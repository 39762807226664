import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import WellBeingAnswerType from 'src/enums/WellBeingAnswerType';

const WellBeingList = (props) => {
  const { methodicArray } = props;
  const [renderArrayState, setRenderArrayState] = useState([]);
  WellBeingList.propTypes = {
    methodicArray: PropTypes.array.isRequired
  };

  const renderColorByType = (type, answer) => {
    switch (type) {
      case WellBeingAnswerType.RELATIVE:
        switch (answer) {
          case 1:
            return '#FF7E79';
          case 2:
            return 'yellow';
          case 3:
          case 4:
            return 'lightgreen';
          case 5:
            return '#30d5c8';
          default:
            return '';
        }
      case WellBeingAnswerType.STABILITY:
      case WellBeingAnswerType.ABSOLUTE:
        switch (answer) {
          case 1:
          case 2:
            return '#FF7E79';
          case 3:
          case 4:
            return 'yellow';
          case 5:
          case 6:
            return 'lightgreen';
          default:
            return '';
        }
      default:
        return '';
    }
  };

  const generateListTemplate = () => {
    const sortedArray = methodicArray[0].Questions.sort((a, b) => a.Id - b.Id);
    const renderArray = [];
    for (let i = 0; i < sortedArray.length; i++) {
      const singleObject = {
        index: i + 1,
        name: i + 1 === 1 ? sortedArray[i].Text.slice(149, sortedArray[i].Text.length) : sortedArray[i].Text, // cut first big question
        color: renderColorByType(i + 1, sortedArray[i].AnswerValue),
        answer: sortedArray[i].AnswerValue === 0 ? 0 : sortedArray[i].AnswerTemplateMethodic[sortedArray[i].AnswerTemplateMethodic.findIndex((j) => j.Value === sortedArray[i].AnswerValue)].Text
      };
      singleObject.name = singleObject.name.charAt(0).toUpperCase() + singleObject.name.slice(1);
      renderArray.push(singleObject);
    }

    setRenderArrayState(renderArray);
  };

  useEffect(() => { generateListTemplate(); }, [methodicArray]);

  return (
    <>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        {renderArrayState.map((item) => (
          <ListItem button style={{ backgroundColor: item.color, textAlign: 'center' }}>
            <ListItemText
              primary={(
                <b>{item.name}</b>
              )}
              disableTypography
              secondary={<Typography type="h5" sx={{ fontWeight: 800, color: 'black', fontStyle: 'italic' }}>{item.answer}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default WellBeingList;

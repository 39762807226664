import { useState } from 'react';
import {
  Collapse, List, ListItemText, ListItem
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

export default function SearchCollapseList(props) {
  const {
    singleResearcherResult,
    navigate
  } = props;
  SearchCollapseList.propTypes = {
    singleResearcherResult: PropTypes.array.isRequired,
    navigate: PropTypes.func.isRequired
  };
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={<b>{singleResearcherResult.FullName}</b>} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" key={singleResearcherResult.Id} unmountOnExit>
        <List component="div" disablePadding key={singleResearcherResult.Id}>
          {singleResearcherResult.Projects.map((project) => (
            <ListItem button key={project.Id}>
              <ListItemText primary={project.Name} onClick={() => navigate(singleResearcherResult.Id, project.Id)} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import methodicService from 'src/services/methodicService';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddNewMethodic from 'src/dialogs/AddNewMethodic';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';
import CustomNotification from '../CustomNotification';
import CustomBackdrop from '../CustomBackdrop';

const Methodics = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [methodicsResponse, setMethodicsResponse] = useState(null);
  const [currentResponse, setCurrentResponse] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const redirectToFullMethodic = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../app/single-methodic/${object.id}`, '_blank');
      return;
    }
    navigate(`../single-methodic/${object.id}`, { replace: true });
  };
  const openAddNewMethodicDialog = () => {
    setShowDialog(true);
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const sendDialog = async (object) => {
    setLoading(true);
    const response = await methodicService.save(object);
    setCurrentResponse(response);
    if (response > 0) {
      await getMethodics(defaultPaggingSettings);
    }
    setOpenNotification(true);
    setShowDialog(false);
    setLoading(false);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getMethodics(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getMethodics(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getMethodics(defaultPaggingSettings);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: '',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.name',
        propertyName: 'name',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.description',
        propertyName: 'description',
        type: TableCellTypes.LongText
      },
      {
        label: 'TABLE.language',
        propertyName: 'languageName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToFullMethodic
      }
    ]
  };
  const getMethodics = async (paggingSettings) => {
    setMethodicsResponse(null);
    const response = await methodicService.getPaged(paggingSettings);
    setMethodicsResponse(response);
  };

  useEffect(() => {
    getMethodics(defaultPaggingSettings);
  }, []);

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.methodicCreated')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <AddNewMethodic open={showDialog} cancel={cancelDialog} send={sendDialog} methodicData={{}} />
      { storedUser.isSuperAdmin === 1 && false
        ? (
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openAddNewMethodicDialog}
          >
            {t('BUTTONS.addNewMethodic')}
          </Button>
        ) : ''}
      <CustomTable dataSource={methodicsResponse} dataConfig={dataConfig} />
    </>
  );
};

export default Methodics;

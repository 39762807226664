import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dialog, FormGroup, FormControlLabel, Checkbox, Typography, Box, Divider, Alert
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function StartInterview(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, respondent, methodics, isComplementMode
  } = props;
  const [selectedMethodics, setSelectedMethodics] = useState([]);
  StartInterview.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    respondent: PropTypes.object.isRequired,
    methodics: PropTypes.array.isRequired,
    isComplementMode: PropTypes.bool
  };

  StartInterview.defaultProps = {
    isComplementMode: false
  };

  const handleChange = (index, event) => {
    const copyArr = [...selectedMethodics];
    if (!event.target.checked) { // remove
      const currentIndex = copyArr.indexOf(+event.target.id);
      if (currentIndex > -1) {
        copyArr.splice(currentIndex, 1);
      }
    } else {
      copyArr.push(+event.target.id);
    }
    setSelectedMethodics(copyArr);
  };

  const sendDialogData = () => {
    send(selectedMethodics);
  };

  useEffect(() => { setSelectedMethodics([]); }, [cancel]);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('FORMS.startInterview')}</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 5 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {t('FORMS.currentRespondent')}
            </Typography>
            <Divider />
            <Typography
              sx={{ mt: 2 }}
              color="textPrimary"
              variant="subtitle1"
            >
              {`${respondent.surname} ${respondent.name} ${respondent.patronymic}`}
            </Typography>
          </Box>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {t('FORMS.methodicsWillBeUsed')}
          </Typography>
          <Divider />
          {methodics.length !== 0 ? (
            <FormGroup column sx={{ mt: 2 }}>
              {methodics.map((item, index) => (
                <>
                  <FormControlLabel
                    control={<Checkbox id={item.methodicId} checked={selectedMethodics[selectedMethodics.indexOf(item.id)]} onChange={(e) => handleChange(index, e)} name={item.name} color="primary" />}
                    label={item.name}
                  />
                </>
              ))}
            </FormGroup>
          ) : (
            <Alert sx={{ m: 3 }} severity="warning">{t('FORMS.noMethodicsAttachedToProject')}</Alert>
          )}
          {selectedMethodics.length === 0 && !isComplementMode
            ? (
              <Box sx={{ mt: 2 }}>
                <Divider />
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={() => { sendDialogData(); }} variant="contained" disabled={selectedMethodics.length !== 0}>{t('BUTTONS.startInterviewWithoutMethodics')}</Button>
                </Box>
              </Box>
            ) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { sendDialogData(); }} color="primary" disabled={selectedMethodics.length === 0}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

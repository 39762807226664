import {
  Card,
  CardContent,
  Box,
  Typography,
  Divider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';

const ResearcherProfileDetails = (props) => {
  const location = useLocation();
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const {
    interviews,
    createdRespondents
  } = props;
  ResearcherProfileDetails.propTypes = {
    interviews: PropTypes.object.isRequired,
    createdRespondents: PropTypes.object.isRequired
  };

  const redirectToAllInterviews = () => {
    navigate('app/interviews', { replace: true });
  };

  const redirectToSingleInterview = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../single-interview/${object.id}`, '_blank');
      return;
    }
    navigate(`../../single-interview/${object.id}`, { replace: true, state: { backRoute: location.pathname } });
  };

  const redirectToFullRespondent = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../single-respondent/${object.id}`, '_blank');
      return;
    }
    navigate(`../../single-respondent/${object.id}`, { replace: true, state: { backRoute: location.pathname } });
  };

  const dataConfigInterviews = {
    isFullMode: false,
    isViewAllButton: false,
    tableTitle: '',
    headerButtons: [],
    viewAllNavigation: redirectToAllInterviews,
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToSingleInterview
      },
      {
        label: 'TABLE.projectName',
        propertyName: 'projectName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.status',
        propertyName: 'statusName',
        propertyValue: 'statusId',
        type: TableCellTypes.Chip
      },
      {
        label: 'TABLE.startDate',
        propertyName: 'startDatetime',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.finishDate',
        propertyName: 'finishDatetime',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.respondent',
        propertyNames: ['respondentSurname', 'respondentName', 'respondentPatronymic'],
        type: TableCellTypes.ConcatText
      },
      {
        label: 'TABLE.researcher',
        propertyNames: ['researcherSurname', 'researcherName', 'researcherPatronymic'],
        type: TableCellTypes.ConcatText
      }
    ]
  };
  const dataConfigCreatedRespondents = {
    isFullMode: false,
    headerButtons: [],
    tableTitle: 'TABLE_LABELS.createdRespondents',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToFullRespondent
      },
      {
        label: 'TABLE.pseudonym',
        propertyName: 'pseudonym',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.age',
        propertyName: 'age',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.gender',
        propertyName: 'genderName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.familyState',
        propertyName: 'familyStateName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.educationState',
        propertyName: 'educationStateName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.dateCreated',
        propertyName: 'dateCreated',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.finishedInterviewsCount',
        propertyName: 'finishedInterviewsCount',
        type: TableCellTypes.Text
      }
    ]
  };
  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {t('TABLE_LABELS.interviews')}
            </Typography>
          </Box>
          <Divider />
          <CustomTable dataSource={interviews} dataConfig={dataConfigInterviews} />
          <CustomTable dataSource={createdRespondents} dataConfig={dataConfigCreatedRespondents} />
        </CardContent>
      </Card>
    </>
  );
};

export default ResearcherProfileDetails;

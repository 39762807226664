import { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const { menuOptions, source, menuIcon } = props;
  LongMenu.propTypes = {
    menuOptions: PropTypes.array.isRequired,
    source: PropTypes.object, // pass source object for menu inside table
    menuIcon: PropTypes.element
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    // call function in the parent
    const menuOption = menuOptions[menuOptions.findIndex((item) => item.id === event.target.value)];
    if (menuOption !== undefined) {
      menuOption.function(source);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {menuIcon === undefined ? (<MoreVertIcon />) : (menuIcon)}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
        {menuOptions.filter((i) => i.visible === true)
          .map((option) => (
            <MenuItem style={{ color: option.textColor }} value={option.id} key={option.id} onClick={handleClose}>
              {option.text}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

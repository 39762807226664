import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import genderService from 'src/services/genderService';
import educationStateService from 'src/services/educationStateService';
import employmentStateService from 'src/services/employmentStateService';
import familyStateService from 'src/services/familyStateService';
import StartInterview from 'src/dialogs/StartInterview';
import interviewService from 'src/services/interviewService';
import languageService from 'src/services/languageService';
import CustomBackdrop from '../CustomBackdrop';
import CustomNotification from '../CustomNotification';
import respondentService from '../../services/respondentService';
import methodicService from '../../services/methodicService';

const CreateNewRespondent = () => {
  const storedUser = useSelector((state) => state.user);
  const initialState = {
    name: '',
    surname: '',
    patronymic: '',
    pseudonym: '',
    age: 0,
    dateOfBirth: null,
    projectId: +storedUser.projectId,
    respondentId: null,
    genderId: null,
    familyStateId: null,
    educationStateId: null,
    employmentStateId: null,
    livingPlace: '',
    languageCommunication: '',
    typeOfEmployment: '',
    languageId: null
  };
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentResponse, setCurrentResponse] = useState({});

  const [showDialog, setShowDialog] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [educationStateList, setEducationStateList] = useState([]);
  const [employmentStateList, setEmploymentStateList] = useState([]);
  const [familyStateList, setFamilyStateList] = useState([]);
  const [respondent, setRespondent] = useState({});
  const [methodics, setMethodics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [respondentData, setRespondentData] = useState(initialState);
  const [languageList, setLanguageList] = useState([]);

  const cancelDialog = () => {
    setShowDialog(false);
  };
  const sendDialog = async (object) => {
    setLoading(true);
    const respondentId = await saveRespondent();
    const newObject = {};
    newObject.languageId = respondentData.languageId;
    newObject.methodics = object;
    newObject.respondentId = respondentId;
    newObject.projectId = +storedUser.projectId;
    newObject.interviewId = 0;
    setShowDialog(false);
    setRespondentData(initialState);

    const response = await interviewService.initializeInterview(newObject);
    if (response > 0) {
      if (object.length !== 0) {
        dispatch({ type: 'updateUser', user: { activeInterviewId: response, interviewLanguageId: respondentData.languageId } });
        navigate(`../interview/${response}`, { replace: true });
      } else {
        dispatch({ type: 'updateUser', user: { redirectAfterTesting: true } });
        navigate(`../single-interview/${response}`, { replace: true });
      }
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    setRespondentData({
      ...respondentData,
      [event.target.name]: event.target.value
    });
  };

  const getGenderList = async () => {
    const response = await genderService.getAll();
    setGenderList(response);
  };
  const getEducationStateList = async () => {
    const response = await educationStateService.getAll();
    setEducationStateList(response);
  };
  const getEmploymentStateList = async () => {
    const response = await employmentStateService.getAll();
    setEmploymentStateList(response);
  };
  const getFamilyStateList = async () => {
    const response = await familyStateService.getAll();
    setFamilyStateList(response);
  };
  const startInterviewPreDialog = async () => {
    const methodicsResponse = await methodicService.getAllByProjectId(storedUser.projectId, respondentData.languageId);
    setRespondent(respondentData);
    setMethodics(methodicsResponse);
    setShowDialog(true);
  };
  const initializeInterview = async () => {
    setLoading(true);
    await startInterviewPreDialog();
    setLoading(false);
  };
  const saveRespondent = async () => {
    setLoading(true);
    let response = null;
    const respondentObject = {
      name: respondentData.name,
      surname: respondentData.surname,
      pseudonym: respondentData.pseudonym,
      patronymic: respondentData.patronymic,
      age: +respondentData.age,
      dateOfBirth: respondentData.dateOfBirth,
      projectId: +storedUser.projectId,
      genderId: respondentData.genderId,
      genderName: respondentData.genderName,
      educationStateId: respondentData.educationStateId,
      employmentStateId: respondentData.employmentStateId,
      familyStateId: respondentData.familyStateId,
      livingPlace: respondentData.livingPlace,
      languageCommunication: respondentData.languageCommunication,
      typeOfEmployment: respondentData.typeOfEmployment
    };
    response = await respondentService.create(respondentObject);
    if (response > 0) {
      setRespondentData(initialState);
      setCurrentResponse(response);
      setOpenNotification(true);
    }
    setOpenNotification(false);
    setLoading(false);

    return response;
  };
  const validateForm = () => (
    respondentData.name !== ''
    && respondentData.surname !== ''
    && respondentData.pseudonym !== ''
    && respondentData.genderId != null
    && respondentData.age !== ''
    && respondentData.age > 0
    && respondentData.languageId !== null
  );
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const getLanguages = async () => {
    setLoading(true);
    const response = await languageService.getAll();
    if (response !== null) {
      setLanguageList(response);
    }
    setLoading(false);
  };

  useEffect(async () => {
    await getGenderList();
    await getEducationStateList();
    await getEmploymentStateList();
    await getFamilyStateList();
    await getLanguages();
  }, []);
  return (
    <>
      <StartInterview open={showDialog} cancel={cancelDialog} send={sendDialog} respondent={respondent} methodics={methodics} />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.respondentCreated')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <Box
        sx={{
          mt: 2
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ ml: -3 }}>
            <>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
              >
                <TextField
                  sx={{ mr: 1 }}
                  margin="dense"
                  id="Name"
                  label={t('FORMS.name')}
                  name="name"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <TextField
                  margin="dense"
                  id="Surname"
                  label={t('FORMS.surname')}
                  name="surname"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
              >
                <TextField
                  sx={{ mr: 1 }}
                  margin="dense"
                  id="Patronymic"
                  label={t('FORMS.patronymic')}
                  name="patronymic"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <TextField
                  margin="dense"
                  id="Pseudonym"
                  label={t('FORMS.pseudonym')}
                  name="pseudonym"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
              >
                <TextField
                  sx={{ mr: 1 }}
                  margin="dense"
                  id="Age"
                  label={t('FORMS.age')}
                  name="age"
                  type="number"
                  min="0"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <TextField
                  margin="dense"
                  id="dateOfBirth"
                  label={t('FORMS.dateOfBirth')}
                  name="dateOfBirth"
                  type="date"
                  fullWidth
                  size="small"
                  onChange={handleChange}
                  onBlur={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 1
              }}
              >
                <FormControl variant="outlined" sx={{ width: '33%', mr: 1 }}>
                  <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.gender')}</InputLabel>
                  <Select
                    name="genderId"
                    onChange={handleChange}
                    label={t('FORMS.gender')}
                  >
                    <MenuItem value={null}>
                      <em>(не вказано)</em>
                    </MenuItem>
                    {genderList.map((gender) => (
                      <MenuItem value={gender.id} key={gender.id}>
                        {gender.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ width: '33%', mr: 1 }}>
                  <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.familyState')}</InputLabel>
                  <Select
                    name="familyStateId"
                    onChange={handleChange}
                    label={t('FORMS.familyState')}
                  >
                    {familyStateList.map((state) => (
                      <MenuItem value={state.id} key={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ width: '33%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.educationState')}</InputLabel>
                  <Select
                    name="educationStateId"
                    onChange={handleChange}
                    label={t('FORMS.educationState')}
                  >
                    {educationStateList.map((state) => (
                      <MenuItem value={state.id} key={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
              >
                <TextField
                  sx={{ mr: 1 }}
                  margin="dense"
                  id="LanguageCommunication"
                  label={t('FORMS.languageCommunication')}
                  name="languageCommunication"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <TextField
                  margin="dense"
                  id="LivingPlace"
                  label={t('FORMS.livingPlace')}
                  name="livingPlace"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 1
              }}
              >
                <TextField
                  sx={{ mr: 1, width: '50%' }}
                  margin="dense"
                  id="TypeOfEmployment"
                  label={t('FORMS.typeOfEmployment')}
                  name="typeOfEmployment"
                  type="text"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <FormControl variant="outlined" sx={{ width: '33%', mr: 1 }}>
                  <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.employmentState')}</InputLabel>
                  <Select
                    name="employmentStateId"
                    onChange={handleChange}
                    label={t('FORMS.employmentState')}
                  >
                    {employmentStateList.map((state) => (
                      <MenuItem value={state.id} key={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ width: '20%', ml: 1 }}>
                  <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.language')}</InputLabel>
                  <Select
                    size="large"
                    name="languageId"
                    onChange={handleChange}
                    label={t('FORMS.language')}
                  >
                    {languageList.map((language) => (
                      <MenuItem value={language.id} key={language.id}>
                        {language.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1
          }}
          >
            <Button onClick={initializeInterview} disabled={!validateForm()} variant="contained">{t('BUTTONS.save')}</Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CreateNewRespondent;

import { Helmet } from 'react-helmet';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RespondentProfile from 'src/components/respondent/RespondentProfile';
import RespondentProfileDetails from 'src/components/respondent/RespondentProfileDetails';
import respondentService from '../services/respondentService';
import CustomBackdrop from '../components/CustomBackdrop';
import BackButton from '../components/BackButton';

const SingleRespondent = () => {
  const [t] = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const storedUser = useSelector((state) => state.user);
  const [generalData, setGeneralInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState(null);
  const getRespondent = async () => {
    setLoading(true);
    const response = await respondentService.getSingle(id, storedUser.projectId);
    if (response !== null && response !== '') {
      setProfileDetails(<RespondentProfileDetails interviews={{ data: response.interviews, count: response.interviews.length }} />);
      setGeneralInfo(response);
      setLoading(false);
    } else {
      navigate('../../not-results', { replace: true });
    }
  };

  useEffect(() => { getRespondent(); }, []);

  return (
    <>
      <Helmet>
        <title>
          {`QQDC | ${t('BROWSER_TAB_TITLES.respondentNumber')}`}
          {id}
        </title>
      </Helmet>
      <BackButton route={location?.state?.backRoute ? `../../../${location.state.backRoute}` : `../../single-project/${storedUser.projectId}`} />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <Box
        sx={{
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="false">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={4}
              md={12}
              xs={12}
            >
              <RespondentProfile userData={generalData} updateData={getRespondent} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xs={12}
            >
              {profileDetails}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SingleRespondent;

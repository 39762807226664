import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  IconButton,
  ButtonGroup
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BackupIcon from '@material-ui/icons/Backup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import UploadFile from 'src/dialogs/UploadFile';
import interviewFileService from 'src/services/interviewFileService';
import helperService from 'src/services/helperService';
import ConfirmRemovingTranscriptionFile from 'src/dialogs/ConfirmRemovingTranscriptionFile';
import interviewService from 'src/services/interviewService';
import RoleEnum from 'src/enums/RoleEnum';

const InterviewTranscription = (props) => {
  const storedUser = useSelector((state) => state.user);
  const {
    isPublished, interviewResearcherId, interviewId, interviewTranscriptionFile, updateParent
  } = props;
  InterviewTranscription.propTypes = {
    isPublished: PropTypes.number,
    interviewResearcherId: PropTypes.number,
    interviewId: PropTypes.number,
    interviewTranscriptionFile: PropTypes.object,
    updateParent: PropTypes.func
  };

  const [t] = useTranslation('common');
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [showTranscriptionConfirmDialog, setShowTranscriptionConfirmDialog] = useState(false);

  const cancelUploadFile = () => {
    setOpenUploadFile(false);
  };

  const sendUploadFile = async (fileObject) => {
    const transcriptionFileObject = {
      interviewId,
      fileBase64: fileObject.base64.split(',')[1],
      mimeType: fileObject.mimeType,
      fileName: fileObject.fileName
    };
    const response = await interviewFileService.save(transcriptionFileObject);
    if (response > 0) {
      await interviewService.update(interviewId, { projectId: storedUser.projectId, transcriptionFileId: response });
      setOpenUploadFile(false);
      updateParent();
    }
  };

  const downloadTranscriptionFile = () => {
    const a = document.createElement('a');
    const audioUrl = URL.createObjectURL(helperService.b64toBlob(interviewTranscriptionFile.fileArray, interviewTranscriptionFile.mimeType));
    const fileName = interviewTranscriptionFile.fileName == null
      ? `Transcription interview#_${interviewId}`
      : interviewTranscriptionFile.fileName;
    a.setAttribute('download', fileName);
    a.setAttribute('href', audioUrl);
    a.click();
    URL.revokeObjectURL(audioUrl);
  };

  const uploadTranscription = () => {
    setOpenUploadFile(true);
  };
  const downloadTranscription = () => {
    downloadTranscriptionFile();
  };

  const removeTranscription = () => {
    setShowTranscriptionConfirmDialog(true);
  };

  const cancelTranscriptionConfirming = () => {
    setShowTranscriptionConfirmDialog(false);
  };

  const confirmRemoveTranscriptionFile = async () => {
    setShowTranscriptionConfirmDialog(false);
    const response = await interviewFileService.remove(interviewTranscriptionFile.id, storedUser.projectId, interviewId);
    if (response > 0) {
      await interviewService.update(interviewId, { projectId: storedUser.projectId, transcriptionFileId: -1 });
      updateParent();
    }
  };

  const currentUserCanUploadRemoveTranscription = () => (
    storedUser.isSuperAdmin === 1
    || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
    || (storedUser.systemRoleId === RoleEnum.RESEARCHER && interviewResearcherId === storedUser.id)
  );

  return (
    <>
      <ConfirmRemovingTranscriptionFile open={showTranscriptionConfirmDialog} cancel={cancelTranscriptionConfirming} confirm={confirmRemoveTranscriptionFile} />
      <UploadFile open={openUploadFile} cancel={cancelUploadFile} send={sendUploadFile} allowedTypes={['text/plain', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']} />
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="body1"
        >
          <b>{t('SINGLE_PAGE.interviewTranscription')}</b>
        </Typography>
        <ButtonGroup sx={{ mt: -1 }}>
          {interviewTranscriptionFile === null && currentUserCanUploadRemoveTranscription() && isPublished === 0
            ? (
              <IconButton size="small" aria-label="upload" color="primary" onClick={uploadTranscription}>
                <BackupIcon />
              </IconButton>
            ) : ''}
          {interviewTranscriptionFile !== null
            ? (
              <IconButton size="small" aria-label="download" color="primary" onClick={downloadTranscription}>
                <CloudDownloadIcon />
              </IconButton>
            ) : ''}
          {interviewTranscriptionFile !== null && currentUserCanUploadRemoveTranscription() && isPublished === 0
            ? (
              <IconButton size="small" aria-label="remove" color="secondary" onClick={removeTranscription}>
                <DeleteIcon />
              </IconButton>
            ) : ''}
        </ButtonGroup>
      </Box>
    </>
  );
};

export default InterviewTranscription;

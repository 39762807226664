import {
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const PsychoDistressList = (props) => {
  const { methodicArray } = props;
  const [renderArrayState, setRenderArrayState] = useState([]);
  PsychoDistressList.propTypes = {
    methodicArray: PropTypes.array.isRequired
  };
  const renderColorByAnswer = (index) => {
    switch (index) {
      case 1:
        return 'white';
      case 2:
        return 'lightgreen';
      case 3:
        return 'yellow';
      case 4:
        return '#FF7E79';
      default:
        break;
    }
  };

  const generateListTemplate = () => {
    const sortedArray = methodicArray[0].Questions.sort((a, b) => b.AnswerValue - a.AnswerValue);
    const renderArray = [];
    for (let i = 0; i < sortedArray.length; i++) {
      const singleObject = {
        index: i + 1,
        name: sortedArray[i].Text,
        color: renderColorByAnswer(sortedArray[i].AnswerValue)
      };
      renderArray.push(singleObject);
    }

    setRenderArrayState(renderArray);
  };

  useEffect(() => { generateListTemplate(); }, [methodicArray]);

  return (
    <>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        {renderArrayState.map((item, index) => (
          <ListItem button style={{ backgroundColor: item.color, textAlign: 'center' }}>
            <ListItemText primary={`${index + 1}) ${item.name}`} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default PsychoDistressList;

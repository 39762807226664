import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import CustomTransferList from 'src/components/CustomTransferList';
import LanguageEnum from 'src/enums/LanguageEnum';
import methodicService from '../services/methodicService';
import RoleEnum from '../enums/RoleEnum';

export default function EditAttachedMethodics(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, attachedMethodics
  } = props;
  const storedUser = useSelector((state) => state.user);
  const [currentItems, setCurrentItems] = useState(attachedMethodics.data);
  const [customTransferList, setCustomTransferList] = useState('');
  EditAttachedMethodics.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    attachedMethodics: PropTypes.array
  };

  const setSelectItems = (object) => {
    setCurrentItems(object);
  };

  const getSourceMethodics = async () => {
    if (storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN) {
      const sourceMethodicsResponse = await methodicService.getSourceMethodics(storedUser.projectId, LanguageEnum.Ukrainian);
      setCustomTransferList(<CustomTransferList selectedItems={attachedMethodics.data} sourceItems={sourceMethodicsResponse} passSelectedItems={setSelectItems} keyField="id" valueField="name" moveAllEnabled="true" reverseLgMoreDirection="true" />);
    }
  };

  useEffect(() => { if (open) { getSourceMethodics(); } }, [open]);

  return (
    <>
      <Dialog maxWidth="md" fullWidth open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('FORMS.attachedMethodics')}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {customTransferList}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { cancel(); setCurrentItems([]); }} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(currentItems); setCurrentItems([]); }} disabled={currentItems.length === 0} color="primary">
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

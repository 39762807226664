import { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function AddNewProject(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, projectData
  } = props;
  const [projectDataObject, setProjectDataObject] = useState({
    name: null,
    description: null,
    id: 0,
    updateAccessCode: 0,
  });
  const [updateAccessCodeWasTouched, setUpdateAccessCodeWasTouched] = useState(0);

  const initialState = {
    name: null,
    description: null,
    id: 0,
    updateAccessCode: 0
  };
  AddNewProject.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    projectData: PropTypes.object
  };

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      setUpdateAccessCodeWasTouched(true);
    }
    setProjectDataObject({
      ...projectDataObject,
      [event.target.name]: event.target.type === 'checkbox' ? +event.target.checked : event.target.value,
      id: projectData.id === undefined ? 0 : projectData.id
    });
  };

  const validateEditForm = () => {
    if (Object.keys(projectData).length !== 0) {
      return projectDataObject.name !== null || projectDataObject.description !== null || updateAccessCodeWasTouched;
    }
  };
  const validate = (object) => (
    validateEditForm()
    || (object.name !== ''
      && object.name !== null
      && object.description !== null
      && object.description !== '')
  );

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {Object.keys(projectData).length === 0
            ? t('FORMS.createProject')
            : t('FORMS.editProject')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Object.keys(projectData).length === 0
              ? ''
              : ''}
          </DialogContentText>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="name"
            label={t('FORMS.name')}
            name="name"
            type="text"
            fullWidth
            size="small"
            defaultValue={projectData === undefined ? '' : projectData.name}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="description"
            label={t('FORMS.description')}
            name="description"
            type="text"
            fullWidth
            size="small"
            defaultValue={projectData === undefined ? '' : projectData.description}
          />
          {Object.keys(projectData).length !== 0 ? (
            <FormControlLabel onChange={handleChange} onBlur={handleChange} control={<Checkbox color="default" />} label={t('FORMS.generateNewAccessCode')} name="updateAccessCode" />
          ) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { cancel(); setProjectDataObject(initialState); }} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(projectDataObject); setProjectDataObject(initialState); }} color="primary" disabled={!validate(projectDataObject)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import StartInterview from 'src/dialogs/StartInterview';
import interviewService from 'src/services/interviewService';
import languageService from 'src/services/languageService';
import CustomBackdrop from '../CustomBackdrop';
import respondentService from '../../services/respondentService';
import methodicService from '../../services/methodicService';

const SelectExistingRespondent = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedUser = useSelector((state) => state.user);
  const [showDialog, setShowDialog] = useState(false);
  const [respondentList, setRespondentList] = useState([]);
  const [respondent, setRespondent] = useState({});
  const [methodics, setMethodics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [respondentData, setRespondentData] = useState({
    respondentId: null,
    languageId: null
  });
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const sendDialog = async (object) => {
    setLoading(true);
    const newObject = {};
    newObject.languageId = respondentData.languageId;
    newObject.methodics = object;
    newObject.respondentId = respondent.id;
    newObject.projectId = +storedUser.projectId;
    newObject.interviewId = 0;
    setShowDialog(false);
    const response = await interviewService.initializeInterview(newObject);
    if (object.length !== 0) {
      dispatch({ type: 'updateUser', user: { activeInterviewId: response, interviewLanguageId: respondentData.languageId } });
      navigate(`../interview/${response}`, { replace: true });
    } else {
      dispatch({ type: 'updateUser', user: { redirectAfterTesting: true, interviewLanguageId: respondentData.languageId } });
      navigate(`../single-interview/${response}`, { replace: true });
    }
    setLoading(false);
  };

  const handleChangeAutoComplete = (newValue) => {
    if (newValue !== null) {
      setRespondentData({
        ...respondentData,
        respondentId: newValue.id
      });
    } else {
      setRespondentData({
        ...respondentData,
        respondentId: null
      });
    }
  };
  const handleChange = (event) => {
    setRespondentData({
      ...respondentData,
      languageId: event.target.value
    });
  };
  const getRespondents = async () => {
    setLoading(true);
    const response = await respondentService.getAll(storedUser.projectId);
    if (response !== null) {
      setRespondentList(response);
    }
    setLoading(false);
  };
  const getLanguages = async () => {
    setLoading(true);
    const response = await languageService.getAll();
    if (response !== null) {
      setLanguageList(response);
    }
    setLoading(false);
  };
  const startInterviewPreDialog = async (respondentId) => {
    await respondentService.attach(respondentId, storedUser.projectId);
    const methodicsResponse = await methodicService.getAllByProjectId(storedUser.projectId, respondentData.languageId);
    const respondentResponse = await respondentService.getSingle(respondentId, storedUser.projectId);
    setRespondent(respondentResponse);
    setMethodics(methodicsResponse);
    setShowDialog(true);
  };
  const saveRespondent = async () => {
    setLoading(true);
    const respondentIdToStore = respondentData.respondentId;
    await startInterviewPreDialog(respondentIdToStore);
    setLoading(false);
  };
  const validateForm = () => (
    respondentData.respondentId !== null
    && respondentData.languageId !== null
  );

  useEffect(() => { getRespondents(); getLanguages(); }, []);
  return (
    <>
      <StartInterview open={showDialog} cancel={cancelDialog} send={sendDialog} respondent={respondent} methodics={methodics} />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <Box
        sx={{
          mt: 2
        }}
      >
        <Container maxWidth="xl">
          <>
            <Box sx={{ ml: -3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
                <Autocomplete
                  onChange={(event, newValue) => { handleChangeAutoComplete(newValue); }}
                  name="respondentId"
                  options={respondentList}
                  getOptionLabel={(option) => `${option.surname} ${option.name} ${option.patronymic}`}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label={t('SINGLE_PAGE.respondent')} variant="outlined" />}
                />
                <FormControl variant="outlined" sx={{ width: '20%', ml: 1 }}>
                  <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.language')}</InputLabel>
                  <Select
                    size="large"
                    name="languageId"
                    onChange={handleChange}
                    label={t('FORMS.language')}
                  >
                    {languageList.map((language) => (
                      <MenuItem value={language.id} key={language.id}>
                        {language.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1
          }}
          >
            <Button onClick={saveRespondent} disabled={!validateForm()} variant="contained">{t('BUTTONS.continue')}</Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SelectExistingRespondent;

import httpService from './httpService';

const login = (email, password) => httpService.post('/api/auth/login', { email, password });

const loginByAccessCode = (acccessCode) => httpService.post(`/api/auth/login/${acccessCode}`);

const validatePassword = (id, password) => httpService.post(`/api/auth/validatepass/${id}`, { password });

const logout = () => localStorage.removeItem('token');

const forgotPassword = (email) => httpService.post('/api/auth/forgotpass', { email });

const changePassword = (passwords) => httpService.post('/api/auth/changepass', passwords);

const checkIsEmailUnique = (email) => httpService.post('api/auth/checkLogin', { email });

const updateToken = (userId, projectId) => httpService.post(`/api/auth/updateToken/${userId}/${projectId}`, { userId, projectId });

export default {
  login, loginByAccessCode, logout, forgotPassword, changePassword, checkIsEmailUnique, validatePassword, updateToken
};

import { Helmet } from 'react-helmet';
import {
  Button,
  Container,
  Typography,
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotResults = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('common');
  return (
    <>
      <Helmet>
        <title>{`QQDC | ${t('BROWSER_TAB_TITLES.serverError')}`}</title>
      </Helmet>
      <Container
        maxWidth="false"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Typography
          align="center"
          color="textPrimary"
          variant="h1"
        >
          {t('SINGLE_PAGE.pageNoExists')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate('../home')}>{t('BUTTONS.redirectToHomePage')}</Button>
        </Box>
      </Container>
    </>
  );
};

export default NotResults;

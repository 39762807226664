import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import MetaValuesAnswer from 'src/enums/MetaValuesAnswer';

const ValuesList = (props) => {
  const { methodicArray } = props;
  const [renderArrayState, setRenderArrayState] = useState([]);
  ValuesList.propTypes = {
    methodicArray: PropTypes.array.isRequired
  };

  const renderIconByAnswer = (answer) => {
    switch (answer) {
      case MetaValuesAnswer.READY_TO_DECLINE:
        return (<StarBorderIcon />);
      case MetaValuesAnswer.NOT_READY_TO_DECLINE:
        return (<StarIcon />);
      case MetaValuesAnswer.READY_DECLINE_TEMPORARY:
        return (<StarHalfIcon />);
      default:
        break;
    }
  };

  const renderColorByIndex = (index) => {
    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
        return 'lightGreen';
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return 'yellow';
      case 10:
      case 11:
      case 12:
      case 13:
        return '#FF7E79';
      default:
        break;
    }
  };

  const generateListTemplate = () => {
    const sortedArray = [];
    const orderArray = [];
    // union 3 arrays from ranges from 3 to 1 answer values
    for (let i = 1; i <= 3; i++) {
      if (methodicArray[i].ComplexRanges.Ranges.length !== 0) {
        orderArray.push(...new Set([...methodicArray[i].ComplexRanges.Ranges[0].map((item) => item.Id)]));
      }
    }

    // create new array from questions by this current list of questions' ids
    orderArray.forEach((id) => {
      sortedArray.push(methodicArray[0].Questions[methodicArray[0].Questions.findIndex((i) => i.Id === id)]);
    });

    // create object to render and push to array
    const renderArray = [];
    for (let i = 0; i < sortedArray.length; i++) {
      const singleObject = {
        icon: renderIconByAnswer(sortedArray[i].AnswerValue),
        index: i + 1,
        name: sortedArray[i].Text,
        metaValueName: sortedArray[i].MetaValueName,
        color: renderColorByIndex(i + 1)
      };
      renderArray.push(singleObject);
    }

    setRenderArrayState(renderArray);
  };

  useEffect(() => { generateListTemplate(); }, [methodicArray]);

  return (
    <>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        {renderArrayState.map((item, index) => (
          <ListItem button style={{ backgroundColor: item.color, textAlign: 'center' }}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={`${index + 1}) ${item.name} - (${item.metaValueName})`} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ValuesList;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FileTypesEnum from '../enums/FileTypesEnum';

export default function ExportFilterDialog(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send
  } = props;
  const [exportFilterObject, setExportFilterObject] = useState({
    startDate: null,
    finishDate: null,
    fileType: 0,
    exportAll: 0
  });
  ExportFilterDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired
  };

  const handleChange = (event) => {
    const value = event.target.value === '' ? null : event.target.value;
    setExportFilterObject({
      ...exportFilterObject,
      [event.target.name]: event.target.type === 'checkbox' ? +event.target.checked : value
    });
  };

  const validate = (object) => {
    if (object.exportAll) {
      return object.fileType !== 0;
    }

    return object.startDate !== null && object.finishDate !== null && object.fileType !== 0 && object.startDate <= object.finishDate;
  };

  const fileTypes = [
    {
      text: 'CSV',
      value: FileTypesEnum.CSV,
    },
    {
      text: 'Excel',
      value: FileTypesEnum.EXCEL,
    }
  ];

  useEffect(() => {
    if (open) {
      setExportFilterObject({
        startDate: null,
        finishDate: null,
        fileType: 0,
        exportAll: 0
      });
    }
  }, [open]);

  useEffect(() => {
    if (exportFilterObject.exportAll === 1) {
      setExportFilterObject({
        startDate: null,
        finishDate: null,
        fileType: exportFilterObject.fileType,
        exportAll: exportFilterObject.exportAll
      });
    }
  }, [exportFilterObject.exportAll]);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('FORMS.export')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5" display="block" gutterBottom>
            {t('FORMS.filterByStartDateLabel')}
          </Typography>
          <TextField
            disabled={exportFilterObject.exportAll}
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="startDate"
            label={t('FORMS.startDate')}
            name="startDate"
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            disabled={exportFilterObject.exportAll}
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="finishDate"
            label={t('FORMS.finishDate')}
            name="finishDate"
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl variant="outlined" style={{ minWidth: '100%', 'margin-top': 10 }}>
            <InputLabel id="demo-simple-select-label">{t('FORMS.fileType')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="fileType"
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
            >
              {fileTypes.map((project) => (<MenuItem key={project.value} value={project.value}>{project.text}</MenuItem>))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              (
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleChange}
                  name="exportAll"
                  color="primary"
                />
              )
            }
            label={t('FORMS.exportAll')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(exportFilterObject); }} color="primary" disabled={!validate(exportFilterObject)}>
            {t('BUTTONS.send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

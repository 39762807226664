import { Button, ListItem, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function KeywordList(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, keywords
  } = props;
  KeywordList.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    keywords: PropTypes.array
  };

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('FORMS.keywordList')}
        </DialogTitle>
        <DialogContent>
          <List>
            {keywords.map((keyword) => (
              <ListItem key={keyword.id}>
                {keyword.name}
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { cancel(); }} color="primary">
            {t('BUTTONS.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

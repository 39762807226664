import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import keywordService from '../services/keyWordService';

export default function AttachKeyword(props) {
  const storedUser = useSelector((state) => state.user);
  const [t] = useTranslation('common');
  const {
    open, cancel, send, attachedKeywords, filterMode
  } = props;
  const [keywords, setKeywords] = useState([]);
  const [updatedKeywords, setUpdatedKeywords] = useState([]);
  const [updatedKeywordsCustom, setUpdatedKeywordsCustom] = useState([]);
  const [customKeywordString, setCustomKeywordString] = useState('');

  AttachKeyword.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    attachedKeywords: PropTypes.array,
    filterMode: PropTypes.bool
  };

  const getAllKeywords = async () => {
    const response = await keywordService.getAll(storedUser.projectId);
    setKeywords(response);
  };

  const handleKeywordChange = (newArray) => {
    setUpdatedKeywords([]);
    setUpdatedKeywords(newArray);
  };

  const handleChange = (event) => {
    setCustomKeywordString([]);
    const keywordString = event.target.value;
    if (keywordString !== '' && !validateForm(keywordString)) {
      setCustomKeywordString(keywordString);
      const newKeywordsParsed = keywordString
        .split(',')
        .map((word) => ({ id: 0, name: word }));
      const uniqueNewKeywords = newKeywordsParsed.filter((i) => updatedKeywords.findIndex((u) => u.name === i.name) === -1);
      setUpdatedKeywordsCustom(uniqueNewKeywords);
    } else {
      setCustomKeywordString('');
      setUpdatedKeywordsCustom([]);
    }
  };

  const concatArrayAndSend = () => {
    const concatedArrayWithState = updatedKeywords.concat(updatedKeywordsCustom);
    setUpdatedKeywords([]);
    setUpdatedKeywords(concatedArrayWithState);
    send(concatedArrayWithState);
  };

  const validateForm = (stringToValidate) => {
    if (stringToValidate !== '') {
      const regex = new RegExp('^[a-zA-Za-åa-ö-w-я 0-9]+(,[a-zA-Za-åa-ö-w-я 0-9]+)*$');
      return !regex.test(stringToValidate);
    }
    return false;
  };

  useEffect(async () => {
    setUpdatedKeywords(attachedKeywords);
    await getAllKeywords();
  }, [open === true]);

  useEffect(async () => {
    setCustomKeywordString('');
    setUpdatedKeywordsCustom([]);
  }, [open === false]);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('FORMS.attachedKeywords')}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            style={{ minWidth: 450 }}
            onChange={(event, newValue) => { handleKeywordChange(newValue); }}
            multiple
            filterSelectedOptions
            options={keywords}
            getOptionLabel={(option) => option.name}
            defaultValue={attachedKeywords}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          {!filterMode
            ? (
              <TextField
                onBlur={handleChange}
                margin="dense"
                id="keywords"
                label={t('FORMS.keywords')}
                name="keywords"
                type="text"
                fullWidth
                size="medium"
              />
            ) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { concatArrayAndSend(); }} color="primary" disabled={validateForm(customKeywordString)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import httpService from './httpService';
import helperService from './helperService';

const getPaged = (projectId, paggingSettings) => httpService.post(`/api/keyword/getPaged/${projectId}`, paggingSettings);

const getAll = (projectId) => httpService.get(`/api/keyword/getAll/${projectId}`);

const create = (keyWordData) => httpService.post('/api/keyword/create', helperService.filterObject(keyWordData));

const update = (id, keyWordData) => httpService.post(`/api/keyword/update/${id}`, helperService.filterObject(keyWordData));

const remove = (id) => httpService.post(`/api/keyword/remove/${id}`);

export default {
  getPaged, getAll, create, update, remove
};

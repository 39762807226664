import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function AddNewAnswer(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, answerTemplateData
  } = props;
  const [answerTemplateObjectData, setQuestionObjectData] = useState({
    description: null,
    template: null
  });

  const initialValue = {
    description: null,
    template: null
  };
  AddNewAnswer.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    answerTemplateData: PropTypes.object
  };

  const handleChange = (event) => {
    setQuestionObjectData({
      ...answerTemplateObjectData,
      [event.target.name]: event.target.value,
      id: Object.keys(answerTemplateData).length === 0 ? 0 : answerTemplateData.id
    });
  };

  const validatejson = (jsonText) => {
    if (jsonText === '' || jsonText === null) {
      return false;
    }
    try {
      JSON.parse(jsonText);
    } catch (e) {
      return false;
    }
    return true;
  };

  const validateEditForm = () => {
    if (Object.keys(answerTemplateData).length !== 0) {
      return (answerTemplateObjectData.description !== null && validatejson(answerTemplateObjectData.template)) || (answerTemplateObjectData.template !== null && validatejson(answerTemplateObjectData.template));
    }
  };

  const validate = (object) => (
    validateEditForm()
    || (validatejson(object.template)
      && object.description !== ''
      && object.template !== ''
      && object.template !== null
      && object.description !== null));

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {Object.keys(answerTemplateData).length === 0
            ? t('FORMS.addNewAnswerTemplate')
            : t('FORMS.editAnswerTemplate')}
        </DialogTitle>
        <DialogContent>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="description"
            label={t('FORMS.description')}
            name="description"
            type="text"
            fullWidth
            size="medium"
            defaultValue={answerTemplateData === undefined ? '' : answerTemplateData.description}
          />
          <TextField
            multiline
            rowsMax={15}
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="template"
            label={t('FORMS.answerTemplate')}
            name="template"
            type="text"
            fullWidth
            size="medium"
            defaultValue={answerTemplateData === undefined ? '' : answerTemplateData.template}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(answerTemplateObjectData); setQuestionObjectData(initialValue); }} color="primary" disabled={!validate(answerTemplateObjectData)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

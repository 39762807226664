import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import PropTypes from 'prop-types';
import AddNewMethodic from '../../dialogs/AddNewMethodic';
import methodicService from '../../services/methodicService';
import CustomMenu from '../CustomMenu';
import CustomNotification from '../CustomNotification';
import CustomBackdrop from '../CustomBackdrop';

const ResearcherProfile = (props) => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [currentResponse, setCurrentResponse] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const { methodicData, updateData } = props;
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  ResearcherProfile.propTypes = {
    methodicData: PropTypes.object.isRequired,
    updateData: PropTypes.func
  };
  const openEditMethodic = () => {
    setShowDialog(true);
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const sendDialog = async (object) => {
    setLoading(true);
    const response = await methodicService.update(object.id, object);
    setCurrentResponse(response);
    if (response > 0) {
      updateData();
    }
    setOpenNotification(true);
    setShowDialog(false);
    setLoading(false);
  };
  const menuOptions = [
    {
      id: 1,
      text: t('BUTTONS.edit'),
      visible: true,
      function: openEditMethodic
    }
  ];
  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.methodicUpdated')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <AddNewMethodic open={showDialog} cancel={cancelDialog} send={sendDialog} methodicData={methodicData} />
      <Card {...props}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {t('SINGLE_PAGE.methodicNumber')}
              {methodicData.id}
            </Typography>
            {storedUser.isSuperAdmin === 1 ? (
              <CustomMenu menuOptions={menuOptions} />
            ) : ''}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'left',
              display: 'flex',
              flexDirection: 'column',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.name')}</b>
              {methodicData.name}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.description')}</b>
              {methodicData.description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ResearcherProfile;

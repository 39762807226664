import httpService from './httpService';

const getPaged = (projectId, paggingSettings) => httpService.post(`/api/interviews/getPaged/${projectId}`, paggingSettings);
const getPagedPublished = (projectId, paggingSettings) => httpService.post(`/api/interviews/getPaged/public/${projectId}`, paggingSettings);

const getAll = (projectId) => httpService.get(`/api/interviews/all/${projectId}`);

const initializeInterview = (interviewObject) => httpService.post('/api/interviews/initialize', interviewObject);

const getSingle = (interviewId, projectId) => httpService.get(`/api/interviews/single/${interviewId}/${projectId}`);

const updateInterview = (id, recalculateValues, object, projectId, languageId) => httpService.post(`/api/interviews/update/${id}/${recalculateValues}/${projectId}/${languageId}`, object);

const update = (id, object) => httpService.post(`/api/interviews/update/${id}`, object);

const complementInterview = (interviewObject) => httpService.post('/api/interviews/complement', interviewObject);

const remove = (id, projectId) => httpService.post(`/api/interviews/remove/${id}/${projectId}`);

const exportData = (projectId, object) => httpService.post(`/api/interviews/export/${projectId}`, object, { responseType: 'blob' });

export default {
  getPaged, getPagedPublished, getAll, initializeInterview, getSingle, updateInterview, update, complementInterview, remove, exportData
};

import { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function UploadFile(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, allowedTypes
  } = props;
  const [fileState, setFileState] = useState({
    base64: '',
    mimeType: '',
    fileName: ''
  });
  const initialfileState = {
    base64: '',
    mimeType: '',
    fileName: ''
  };
  const [loading, setLoading] = useState(false);
  UploadFile.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    allowedTypes: PropTypes.array.isRequired
  };

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      console.log(error);
      reject(error);
    };
  });

  const allowedMimeTypes = [
    { type: 1, mimeType: 'audio/' },
    { type: 2, mimeType: 'text/plain' },
    { type: 3, mimeType: 'application/pdf' },
    { type: 4, mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
  ];

  const verifyAllowedMimeType = (mimeType) => {
    if (mimeType.includes('audio')) {
      const splitedArray = allowedTypes.map((i) => i.split('/')[0]);
      return splitedArray.includes('audio');
    }

    return allowedTypes.includes(mimeType);
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const mimeType = allowedMimeTypes.find((i) => file.type.includes(i.mimeType));
    if (mimeType !== null && mimeType !== undefined && verifyAllowedMimeType(file.type)) {
      const base64 = await convertBase64(file);
      setFileState({
        ...fileState,
        base64,
        mimeType: file.type,
        fileName: file.name
      });
    } else {
      event.target.value = null;
      setFileState({
        ...fileState,
        base64: null
      });
    }
  };

  const sendFile = () => {
    setLoading(true);
    send(fileState);
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setFileState(initialfileState);
    }
  }, [open]);

  return (
    <div>
      <Dialog maxWidth="md" open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('FORMS.audioUpload')}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          {loading
            ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <TextField
                type="file"
                inputProps={{ accept: allowedTypes }}
                onChange={(e) => handleFileRead(e)}
                variant="standard"
              />
            )}
          {fileState.base64 === null
            ? (
              <Typography sx={{ mt: 5 }} variant="body4" display="block" align="center" color="red">
                {t('DIALOGS.wrongFileFormat')}
              </Typography>
            ) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary" disabled={loading}>
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => sendFile()} color="primary" disabled={fileState.base64 === '' || fileState.base64 === null || loading}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

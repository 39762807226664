import { useState } from 'react';
import {
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function AddNewMethodic(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, methodicData
  } = props;
  const [methodicDataObject, setMethodicDataObject] = useState({
    name: null,
    description: null,
    id: 0
  });

  const initialState = {
    name: null,
    description: null,
    id: 0
  };

  AddNewMethodic.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    methodicData: PropTypes.object
  };

  const handleChange = (event) => {
    setMethodicDataObject({
      ...methodicDataObject,
      [event.target.name]: event.target.value,
      id: methodicData.id === undefined ? 0 : methodicData.id
    });
  };

  const validateEditForm = () => {
    if (Object.keys(methodicData).length !== 0) {
      return (methodicDataObject.name !== null || methodicDataObject.description !== null) && validatejson(methodicDataObject.description);
    }
  };

  const validate = (object) => (
    validateEditForm()
    || (object.name !== ''
      && object.description !== ''
      && object.name !== null
      && object.description !== null
      && validatejson(object.description)));

  const validatejson = (jsonText) => {
    if (jsonText === '' || jsonText === null) {
      return false;
    }
    try {
      JSON.parse(jsonText);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {Object.keys(methodicData).length === 0
            ? t('FORMS.createMethodic')
            : t('FORMS.editMethodics')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Object.keys(methodicData).length === 0
              ? ''
              : ''}
          </DialogContentText>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="name"
            label={t('FORMS.name')}
            name="name"
            type="text"
            fullWidth
            size="small"
            defaultValue={methodicData === undefined ? '' : methodicData.name}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="description"
            label={t('FORMS.description')}
            name="description"
            type="text"
            fullWidth
            size="small"
            defaultValue={methodicData === undefined ? '' : methodicData.description}
            multiline
            maxRows={10}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(methodicDataObject); setMethodicDataObject(initialState); }} color="primary" disabled={!validate(methodicDataObject)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { useEffect, useState, useRef } from 'react';
import { Prompt, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  MobileStepper,
  Button,
  Alert
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AudioRecordActions from 'src/enums/AudioRecordActions';
import interviewService from '../services/interviewService';
import SingleMethodicInterviewPage from './SingleMethodicInterviewPage';
import InterruptInterview from '../dialogs/InterruptInterview';
import CustomBackdrop from '../components/CustomBackdrop';
import CustomNotification from '../components/CustomNotification';
import StatusEnum from '../enums/StatusEnum';
import AudioRecorder from '../components/AudioRecorder';
import interviewFileService from '../services/interviewFileService';

const InterviewEntirePage = () => {
  const divRef = useRef(null);
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const [currentResponse, setCurrentResponse] = useState({});
  const [interruptedInterview, setInterruptedInterview] = useState(false);
  const [interviewObject, setInterviewObject] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [preventLeavingPage, setPreventLeavingPage] = useState(true);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentStepElement, setCurrentStepElement] = useState('');
  const [recordState, setRecordState] = useState(AudioRecordActions.START_RECORD);
  const [audioFile, setAudioFile] = useState(null);
  const storedUser = useSelector((state) => state.user);
  const theme = useTheme();
  const { id } = useParams();
  const getInterview = async () => {
    const response = await interviewService.getSingle(id, storedUser.projectId);
    setInterviewObject(JSON.parse(response.interviewObject));
    if (response.statusId === StatusEnum.INTERRUPTED) {
      if (activeStep === 0) {
        setActiveStep(response.interviewLastStepId);
      }
      setInterruptedInterview(true);
      setPreventLeavingPage(true);
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cancelDialog = () => {
    setShowDialog(false);
  };

  const finishInterview = () => {
    setShowDialog(true);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };

  const sendFinishInterview = async (object) => {
    setRecordState(AudioRecordActions.STOP_RECORD);
    setPreventLeavingPage(false);
    setLoading(true);
    const interviewObjectToUpdate = {
      interviewFinishReason: object,
      interviewLastStepId: +activeStep,
      statusId: StatusEnum.INTERRUPTED,
      researcherId: storedUser.id,
      projectId: storedUser.projectId
    };
    const response = await interviewService.update(id, interviewObjectToUpdate);
    setCurrentResponse(response);
    if (response) {
      setShowDialog(false);
      setOpenNotification(true);
      navigate(`../../single-project/${storedUser.projectId}`, { replace: true });
      dispatch({ type: 'removeProperty', user: 'activeInterviewId' });
    }
    setLoading(false);
  };

  // if last range is empty -- finish interview
  const isLastStepEmptyRanges = (currentStep) => {
    const isCurrentStepBeforeLast = (interviewObject.length - 1) === currentStep + 1;
    const isLastRangeEmpty = interviewObject[currentStep + 1]
      ? interviewObject[currentStep + 1].ComplexRanges.Ranges.length === 0
      && interviewObject[currentStep + 1].Questions.length === 0
      : false;

    return isCurrentStepBeforeLast && isLastRangeEmpty;
  };

  // if first range contains all answers -- finish interview
  const isFirstRangeIsFull = (currentStep) => {
    const isCurrentStepBeforeLast = (interviewObject.length - 1) === currentStep + 1;
    const rangesIs13Count = interviewObject[currentStep].ComplexRanges.Ranges.length === 0
      ? false
      : interviewObject[currentStep].ComplexRanges.Ranges[0].length === 13;

    return !isCurrentStepBeforeLast && rangesIs13Count;
  };

  const saveAnswers = async (actionType, object) => {
    setLoading(true);
    const interviewObjectFinishDate = {};
    const isLastStepEmptyRange = isLastStepEmptyRanges(activeStep);
    const isFirstRangeIsFullValue = isFirstRangeIsFull(activeStep);
    if (activeStep === interviewObject.length - 1 || isLastStepEmptyRange || isFirstRangeIsFullValue) {
      setRecordState(AudioRecordActions.STOP_RECORD);
      setPreventLeavingPage(false);
      interviewObjectFinishDate.statusId = StatusEnum.FINISHED;
      interviewObjectFinishDate.researcherId = +storedUser.id;
      interviewObjectFinishDate.projectId = +storedUser.projectId;
      await interviewService.update(id, interviewObjectFinishDate);
    }
    const copyObject = object;
    copyObject.ComplexRanges.Touched = true;
    const responseUpdateInterviewObject = await interviewService.updateInterview(id, actionType, copyObject, storedUser.projectId, storedUser.interviewLanguageId);
    if (responseUpdateInterviewObject >= 1) {
      await getInterview();
    }
    if (activeStep === interviewObject.length - 1
      || isLastStepEmptyRange
      || isFirstRangeIsFullValue) {
      setPreventLeavingPage(false);
      navigate('../../finish-interview', { replace: true });
    } else {
      handleNext();
    }
    setLoading(false);
  };

  const setElementForStep = () => {
    if (activeStep !== 0 && activeStep === interviewObject.length) {
      setPreventLeavingPage(false);
      navigate('../../finish-interview', { replace: true });
    }

    if (interviewObject.length !== 0 && interviewObject[activeStep] !== undefined) {
      if (interviewObject[activeStep].ComplexRanges.Ranges.length === 0 && interviewObject[activeStep].Questions.length === 0) {
        handleNext();
      } else {
        setCurrentStepElement('');
        setCurrentStepElement(<SingleMethodicInterviewPage interviewObject={interviewObject[activeStep]} save={saveAnswers} />);
      }
    }
  };

  const getAudio = async (object) => {
    setAudioFile(object);
  };

  const saveAudio = async () => {
    const audioFileObject = {
      interviewId: +id,
      fileBase64: audioFile
    };
    await interviewFileService.save(audioFileObject);
  };

  const scrollToUp = () => {
    if (divRef.current !== null) {
      divRef.current.scrollIntoView();
    }
  };

  scrollToUp(); // this function doesn't work in useEffect, so I call this one here and it started to work

  useEffect(() => {
    window.onbeforeunload = function () {
      setShowDialog(true);
    };
    if (storedUser.activeInterviewId === undefined) {
      navigate(`../../single-interview/${id}`, { replace: true });
    } else {
      getInterview();
    }
    setPreventLeavingPage(true);
  }, []);
  useEffect(() => { setElementForStep(); }, [activeStep]);
  useEffect(() => { setElementForStep(); }, [interviewObject.length]);
  useEffect(() => { if (audioFile !== null && audioFile !== '') { saveAudio(); } }, [audioFile]);

  return (
    <>
      <Alert sx={{ m: 2, ml: 3, mr: 3 }} severity="success" color="info"><b>Recording is active</b></Alert>
      <AudioRecorder recordMode="true" getAudio={getAudio} isRecordingProp={recordState} />
      <Prompt when={preventLeavingPage} message={t('NOTIFICATIONS.interruptPromtText')} />
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.interviewFinished')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <InterruptInterview open={showDialog} cancel={cancelDialog} send={sendFinishInterview} />
      <Helmet>
        <title>{t('BROWSER_TAB_TITLES.newInterview')}</title>
      </Helmet>
      <Box
        ref={divRef}
        sx={{
          mt: 2
        }}
      >
        <Container>
          <MobileStepper
            variant="dots"
            steps={interviewObject.length}
            position="static"
            activeStep={activeStep}
            nextButton={(
              <Button variant="contained" color="secondary" size="small" onClick={finishInterview}>
                {t('BUTTONS.interuptInterview')}
              </Button>
            )}
            backButton={
              (
                <Button size="small" onClick={handleBack} disabled={activeStep === 0 || interruptedInterview}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  {t('BUTTONS.back')}
                </Button>
              )
            }
          />
          <div>
            {currentStepElement}
          </div>
        </Container>
      </Box>
    </>
  );
};

export default InterviewEntirePage;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
  InputLabel,
  Select,
  FormControl,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RoleEnum from 'src/enums/RoleEnum';
import projectService from 'src/services/projectService';
import researcherService from 'src/services/researcherService';
import roleService from '../services/roleService';

export default function AddResearcher(props) {
  const storedUser = useSelector((state) => state.user);
  const [t] = useTranslation('common');
  const {
    open, cancel, send
  } = props;
  const [projectList, setProjectList] = useState([]);
  const [systemRoleList, setSystemRoleList] = useState([]);
  const [allResearchersList, setAllResearchersList] = useState([]);
  const [selectedResearcherId, setSelectedResearcherId] = useState(0);
  const [researcherProjectData, setResearcherProjectData] = useState({
    projectRole: null,
    systemRoleId: null
  });

  AddResearcher.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired
  };

  const getProjectList = async () => {
    const response = await projectService.getAllProjects();
    setProjectList(response);
  };

  useEffect(() => {
    if ((storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN) && open) {
      getProjectList();
    }
  }, [open]);

  const handleChangeAttachForm = (event) => {
    setResearcherProjectData({
      ...researcherProjectData,
      [event.target.name]: event.target.value
    });
  };
  const handleChangeResearcherAutoComplete = (newValue) => {
    if (newValue !== null) {
      setSelectedResearcherId(newValue.id);
    } else {
      setSelectedResearcherId(0);
    }
  };

  const getSystemRoles = async () => {
    const response = await roleService.getAll();
    setSystemRoleList(response);
  };

  const getAllResearchers = async () => {
    const response = await researcherService.getAllNotInvolvedToProject(storedUser.projectId);
    setAllResearchersList(response);
  };

  const validateAttachingResearcherForm = (reseracherProjectData) => (
    reseracherProjectData.projectRole !== null && reseracherProjectData.systemRoleId !== null
    && reseracherProjectData.projectRole !== '' && reseracherProjectData.systemRoleId !== ''
  );

  const attachResearcher = async () => {
    researcherProjectData.researcherId = selectedResearcherId;
    researcherProjectData.projectId = storedUser.projectId;
    const response = await researcherService.attachResearcherToProject(researcherProjectData);
    setSelectedResearcherId(0);
    send(response);
  };

  useEffect(() => {
    if (open) {
      getSystemRoles();
      getAllResearchers();
    }
  }, [open]);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('FORMS.attachResearcher')}</DialogTitle>
        <Box>
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText>
              {t('FORMS.fillFieldsToAttachResearcher')}
              <Typography>{`${t('SINGLE_PAGE.projectName')}: ${projectList.length !== 0 ? projectList.find((i) => i.id === storedUser.projectId).name : ''}`}</Typography>
            </DialogContentText>
            <Autocomplete
              style={{ minWidth: 500 }}
              size="small"
              onChange={(event, newValue) => { handleChangeResearcherAutoComplete(newValue); }}
              name="researcherId"
              options={allResearchersList}
              getOptionLabel={(option) => `${option.surname} ${option.name} ${option.patronymic == null ? '' : option.patronymic} - ${moment(option.dateOfBirth).format('DD/MM/YYYY')}`}
              fullWidth
              renderInput={(params) => <TextField {...params} label={t('SINGLE_PAGE.researcher')} variant="outlined" />}
            />
            {selectedResearcherId !== 0
              ? (
                <Box>
                  <TextField
                    style={{ minWidth: 500 }}
                    onChange={handleChangeAttachForm}
                    onBlur={handleChangeAttachForm}
                    margin="dense"
                    id="projectRole"
                    label={t('FORMS.projectRole')}
                    name="projectRole"
                    type="text"
                    fullWidth
                    size="small"
                  />
                  <FormControl variant="outlined">
                    <InputLabel>{t('FORMS.systemRoleId')}</InputLabel>
                    <Select
                      style={{ minWidth: 550 }}
                      size="small"
                      name="systemRoleId"
                      onChange={handleChangeAttachForm}
                      onBlur={handleChangeAttachForm}
                      label={t('FORMS.systemRoleId')}
                      fullWidth
                    >
                      {systemRoleList.map((systemRole) => (<MenuItem key={systemRole.id} value={systemRole.id}>{systemRole.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Box>
              ) : ''}
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel} color="primary">
              {t('BUTTONS.cancel')}
            </Button>
            {selectedResearcherId !== 0
              ? (
                <Button onClick={() => { attachResearcher(); }} color="primary" disabled={!validateAttachingResearcherForm(researcherProjectData)}>
                  {t('BUTTONS.attach')}
                </Button>
              ) : ''}
          </DialogActions>

        </Box>
      </Dialog>
    </div>
  );
}

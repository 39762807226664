import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackButton = (props) => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const { route } = props;
  BackButton.propTypes = {
    route: PropTypes.string.isRequired
  };

  const redirect = () => {
    navigate(route, { replace: true });
  };

  return (
    <Button
      sx={{ ml: 3, mt: 1, mb: 0 }}
      color="primary"
      startIcon={<ArrowBackIosIcon />}
      onClick={redirect}
    >
      {t('BUTTONS.back')}
    </Button>
  );
};
export default BackButton;

import httpService from './httpService';
import helperService from './helperService';

const save = (questionData) => httpService.post('/api/questions/create', helperService.filterObject(questionData));

const update = (id, questionData) => httpService.post(`/api/questions/update/${id}`, helperService.filterObject(questionData));

const remove = (questionData) => httpService.post('/api/questions/remove', questionData);

export default {
  save, update, remove
};

import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ResearcherProfile from 'src/components/researcher/ResearcherProfile';
import ResearcherProfileDetails from 'src/components/researcher/ResearcherProfileDetails';
import researcherService from '../services/researcherService';
import CustomBackdrop from '../components/CustomBackdrop';
import BackButton from '../components/BackButton';

const SingleResearcher = () => {
  const [t] = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();
  const storedUser = useSelector((state) => state.user);
  const [generalData, setGeneralInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState(null);
  const update = async () => {
    await getResearcher();
  };
  const getResearcher = async () => {
    setLoading(true);
    setProfileDetails('');
    const response = await researcherService.getSingle(id, storedUser.projectId);
    if (response !== null && response !== '') {
      const user = {
        id: response.id,
        surname: response.surname,
        name: response.name,
        patronymic: response.patronymic,
        projectRole: response.projectRole,
        researcherSurname: response.researcherSurname,
        researcherName: response.researcherName,
        researcherPatronymic: response.researcherPatronymic,
        dateCreated: response.dateCreated,
        dateOfBirth: response.dateOfBirth,
        isSuperAdmin: response.isSuperAdmin,
        isAllowToLogin: response.isAllowToLogin,
        isAllowToCreateProject: response.isAllowToCreateProject,
        login: response.login,
        datePasswordChanged: response.datePasswordChanged,
        currentUserSuperAdmin: storedUser.isSuperAdmin,
        projectId: response.projectId,
        systemRoleId: response.systemRoleId,
        systemRoleName: response.systemRoleName,
        createdById: response.createdById
      };

      setProfileDetails(<ResearcherProfileDetails
        interviews={{ data: response.interviews, count: response.interviews.length }}
        createdRespondents={{ data: response.createdRespondents, count: response.createdRespondents.length }}
        researcherId={id}
        updateParent={getResearcher}
      />);
      setGeneralInfo(user);
      setLoading(false);
    } else {
      navigate('../../not-results', { replace: true });
    }
  };

  useEffect(() => { getResearcher(); }, []);

  return (
    <>
      <Helmet>
        <title>
          {`QQDC | ${t('BROWSER_TAB_TITLES.researcherNumber')}`}
          {id}
        </title>
      </Helmet>
      <BackButton route={`../../single-project/${storedUser.projectId}`} />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <Box
        sx={{
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="false">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={4}
              md={12}
              xs={12}
            >
              <ResearcherProfile userData={generalData} updateData={update} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xs={12}
            >
              {profileDetails}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SingleResearcher;

import httpService from './httpService';

const getPaged = (projectId, paggingSettings) => httpService.post(`/api/respondents/getPaged/${projectId}`, paggingSettings);

const getSingle = (respondentId, projectId) => httpService.get(`/api/respondents/single/${respondentId}/${projectId}`);

const getAll = (projectId) => httpService.get(`/api/respondents/all/${projectId}`);

const create = (object) => httpService.post('/api/respondents/create', object);

const update = (id, object) => httpService.post(`/api/respondents/update/${id}`, object);

const remove = (id) => httpService.post(`/api/respondents/remove/${id}`);

const attach = (id, projectId) => httpService.post(`/api/respondents/attach/${id}/${projectId}`);

export default {
  getPaged, getSingle, getAll, create, update, remove, attach
};

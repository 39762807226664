import { Helmet } from 'react-helmet';
import {
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Projects from 'src/components/dashboard/Projects';
import SearchCard from 'src/components/dashboard/SearchCard';

const Dashboard = () => {
  const [t] = useTranslation('common');
  return (
    <>
      <Helmet>
        <title>{`QQDC | ${t('BROWSER_TAB_TITLES.mainPage')}`}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%'
        }}
      >
        <Projects />
        <SearchCard />
      </Box>
    </>
  );
};

export default Dashboard;

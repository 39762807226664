// import { useState } from 'react';
import {
  Alert,
  Snackbar
} from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomNotification = (props) => {
  const {
    open, close, autoHideDurationTime, successText, vertical, horizontal, response
  } = props;
  CustomNotification.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.bool.isRequired,
    autoHideDurationTime: PropTypes.number.isRequired,
    successText: PropTypes.string.isRequired,
    vertical: PropTypes.string.isRequired,
    horizontal: PropTypes.string.isRequired,
    response: PropTypes.object.isRequired
  };

  return (
    <Snackbar open={open} onClose={close} autoHideDuration={autoHideDurationTime} anchorOrigin={{ vertical, horizontal }}>
      {response.status === 200 || response >= 1 ? (
        <Alert severity="success">
          {successText}
        </Alert>
      ) : (
        <Alert severity="error">
          {response.data}
        </Alert>
      )}
    </Snackbar>
  );
};

export default CustomNotification;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import Ukraine from '../flags/ukraine_flag.png';
import Britain from '../flags/britain_flag.png';
import Russia from '../flags/russia_flag.png';

const countries = [
  {
    src: Ukraine,
    name: 'ukr'
  },
  {
    src: Britain,
    name: 'en'
  },
  {
    src: Russia,
    name: 'ru'
  }
];

const useStyles = makeStyles(() => ({
  button: {
    display: 'block'
  },
  formControl: {
    minWidth: 120,
    backgroundColor: 'transparent'
  },
  select: {
    textAlign: 'center',
    textDecoration: 'none'
  }
}));

export default function SelectCountryList(props) {
  const { currentLanguage } = props;
  SelectCountryList.propTypes = {
    currentLanguage: PropTypes.string.isRequired
  };
  const dispatch = useDispatch();
  const { i18n } = useTranslation('common');
  const classes = useStyles();
  const [country, setCountry] = useState(Ukraine);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setCountry(event.target.value);
    dispatch({ type: 'updateUser', user: { language: event.target.name.name } });
    i18n.changeLanguage(event.target.name.name);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    i18n.changeLanguage(currentLanguage === undefined ? 'ukr' : currentLanguage);
    setCountry(currentLanguage === undefined ? Ukraine : countries[countries.findIndex((item) => item.name === currentLanguage)].src);
  }, []);

  return (
    <form autoComplete="off" style={{ marginBottom: '16px' }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="open-select" />
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={country}
          name={countries[countries.findIndex((item) => item.src === country.toString())]}
          onChange={handleChange}
          onBlur={handleChange}
          inputProps={{
            id: 'open-select'
          }}
        >
          {countries.map((option) => (
            <MenuItem value={option.src}>
              <img src={option.src} alt="" style={{ width: '25px', height: '25px' }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
}

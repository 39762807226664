import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import projectService from '../services/projectService';
import CustomBackdrop from '../components/CustomBackdrop';
import ProjectProfile from '../components/project/ProjectProfile';
import BackButton from '../components/BackButton';
import ProjectDetails from '../components/project/ProjectDetails';

const SingleProject = () => {
  const storedUser = useSelector((state) => state.user);
  const [t] = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectProfile, setProjectProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState('');
  const getProject = async () => {
    setLoading(true);
    setProjectProfile('');
    const response = await projectService.getById(id);

    if (response !== null && response !== '') {
      dispatch({
        type: 'updateUser',
        user: {
          projectId: response.id,
          projectStatusId: response.statusId,
          systemRoleId: response.userSystemRoleId
        }
      });
      setProjectDetails(<ProjectDetails />);
      setProjectProfile(<ProjectProfile projectData={response} updateData={getProject} />);
      setLoading(false);
    } else {
      navigate('../../not-results', { replace: true });
    }
  };

  useEffect(() => { getProject(); }, []);

  return (
    <>
      <Helmet>
        <title>
          {`QQDC | ${t('BROWSER_TAB_TITLES.projectNumber')}`}
          {id}
        </title>
      </Helmet>
      {!storedUser.publishMode
        ? (
          <BackButton route="../../app/home" />
        ) : ''}
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <Box
        sx={{
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="false">
          <Grid
            container
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {projectProfile}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {projectDetails}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SingleProject;

const TableCellTypes = Object.freeze({
  Text: 1,
  Date: 2,
  Button: 3,
  Chip: 4,
  ConcatText: 5,
  LongText: 6,
  AudioFile: 7,
  DateTime: 8,
  Menu: 9,
  IconButton: 10,
});

export default (TableCellTypes);

import { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Tab,
  Tabs
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import CreateNewRespondent from './CreateNewRespondent';
import SelectExistingRespondent from './SelectExistingRespondent';

const CreateRespondentCard = () => {
  const [t] = useTranslation('common');
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <>
      <Box
        sx={{
          mt: 2
        }}
      >
        <Container maxWidth="xl">
          <Tabs
            variant="fullWidth"
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            onChange={handleChangeTabs}
          >
            <Tab icon={<PersonAddIcon />} label={t('SINGLE_PAGE.newRespondent')} />
            <Tab icon={<PersonIcon />} label={t('SINGLE_PAGE.existingRespondent')} />
          </Tabs>
          <>
            <TabPanel value={tabValue} index={0}>
              <CreateNewRespondent />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SelectExistingRespondent />
            </TabPanel>
          </>
        </Container>
      </Box>
    </>
  );
};

export default CreateRespondentCard;

const Logo = (props) => (
  <img
    width="50px"
    height="50px"
    alt="Logo"
    src="/logo.png"
    {...props}
  />
);

export default Logo;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AudioRecorderActions from '../enums/AudioRecordActions';

const MicRecorder = require('mic-recorder-to-mp3');

const AudioRecorder = (props) => {
  const {
    recordMode, getAudio, isRecordingProp, audioUrlProp
  } = props;
  const [recorder] = useState(new MicRecorder({
    bitRate: 128
  }));
  AudioRecorder.propTypes = {
    recordMode: PropTypes.bool.isRequired,
    getAudio: PropTypes.func,
    isRecordingProp: PropTypes.number.isRequired,
    audioUrlProp: PropTypes.string
  };

  function startRecording() {
    recorder.start().then(() => { }).catch((e) => {
      console.error(e);
    });
  }

  function convertBlobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      getAudio(reader.result.split(',')[1]);
    };
  }

  function stopRecording() {
    recorder.stop().getMp3().then(([buffer, blob]) => {
      const file = new File(buffer, 'file.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });

      const player = new Audio(URL.createObjectURL(file));
      player.controls = true;
      convertBlobToBase64(blob);
    }).catch((e) => {
      console.error(e);
    });
  }

  useEffect(() => {
    if (isRecordingProp === AudioRecorderActions.START_RECORD) {
      startRecording();
    } else if (isRecordingProp === AudioRecorderActions.STOP_RECORD) {
      stopRecording();
    }
  }, [isRecordingProp]);

  return (
    <>
      {!recordMode ? (<audio controls preload src={audioUrlProp} />) : ''}
    </>
  );
};

export default AudioRecorder;

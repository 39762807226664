import httpService from './httpService';
import helperService from './helperService';

const getPaged = (paggingSettings) => httpService.post('/api/answertemplates/getPaged', paggingSettings);

const getAll = () => httpService.get('/api/answertemplates/getAll');

const getById = (answerTemplateId) => httpService.get(`/api/answertemplates/single/${answerTemplateId}`);

const save = (answerTemplateData) => httpService.post('/api/answertemplates/create', helperService.filterObject(answerTemplateData));

const update = (id, answerTemplateData) => httpService.post(`/api/answertemplates/update/${id}`, helperService.filterObject(answerTemplateData));

export default {
  getPaged, getById, save, update, getAll
};

import {
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomBackdrop = (props) => {
  const {
    open, spinnerColor
  } = props;
  CustomBackdrop.propTypes = {
    open: PropTypes.bool.isRequired,
    spinnerColor: PropTypes.string.isRequired
  };
  return (
    <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color={spinnerColor} />
    </Backdrop>
  );
};
export default CustomBackdrop;

import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import activityService from '../../services/activityService';

const Activity = () => {
  const [t] = useTranslation('common');
  const [activityFilter, setActivityFilter] = useState({
    startDate: null,
    endDate: null
  });

  const initialState = {
    startDate: null,
    endDate: null
  };

  const handleChange = (event) => {
    setActivityFilter({
      ...activityFilter,
      [event.target.name]: event.target.value
    });
  };

  const uploadLogFile = async () => {
    const response = await activityService.download(activityFilter);
    setActivityFilter(initialState);
    download(response, 'text/plain');
  };

  const download = (response) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'logs.txt');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Card>
        <CardHeader
          title={t('SINGLE_PAGE.load_logs')}
        />
        <Divider />
        <CardContent>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="startDate"
            label={t('FORMS.startDate')}
            name="startDate"
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="endDate"
            label={t('FORMS.finishDate')}
            name="endDate"
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={uploadLogFile}
            disabled={activityFilter.startDate == null || activityFilter.endDate == null || (activityFilter.startDate > activityFilter.endDate)}
          >
            {t('BUTTONS.download')}
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default Activity;

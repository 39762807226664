import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  InputAdornment,
  Box,
  Card,
  CardHeader,
  TextField,
  TablePagination,
  Button,
  Chip,
  LinearProgress,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import moment from 'moment';
import AudioRecordActions from 'src/enums/AudioRecordActions';
import TableCellTypes from '../enums/TableCellTypes';
import AudioRecorder from './AudioRecorder';
import CustomMenu from './CustomMenu';
import helperService from '../services/helperService';
import CustomNoResultTemplate from './CustomNoResultTemplate';

const CustomTable = (props) => {
  const [t] = useTranslation('common');
  const { dataSource, dataConfig } = props;
  CustomTable.propTypes = {
    dataSource: PropTypes.array.isRequired,
    dataConfig: PropTypes.object.isRequired
  };
  const [limit, setLimit] = useState(dataConfig.limit === undefined ? 5 : dataConfig.limit);
  const [page, setPage] = useState(0);
  const [timer, setTimer] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    dataConfig.handleLimitChangeFunction(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    dataConfig.handlePageChangeFunction(newPage + 1); // add 1 because UI pagging starts from 0, server from 1
  };

  const handleSearching = (event) => {
    clearTimeout(timer);
    setTimer(setTimeout(() => dataConfig.handleSearchingFunction(event.target.value), 2000));
  };

  const renderElementByType = (source, config) => {
    let resultElement = '';
    switch (config.type) {
      case TableCellTypes.Text:
        resultElement = source[config.propertyName];
        break;
      case TableCellTypes.LongText:
        resultElement = source[config.propertyName] === null ? ''
          : (
            <Box>
              <span style={{ wordBreak: 'break-all' }}>
                {source[config.propertyName].slice(0, 200)}
              </span>
              <span>
                {source[config.propertyName].length > 200 ? '...' : ''}
              </span>
            </Box>
          );
        break;
      case TableCellTypes.DateTime:
        resultElement = moment(source[config.propertyName]).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? '' : moment(source[config.propertyName]).format('DD/MM/YYYY HH:mm:ss');
        break;
      case TableCellTypes.Date:
        resultElement = moment(source[config.propertyName]).format('DD/MM/YYYY') === 'Invalid date' ? '' : moment(source[config.propertyName]).format('DD/MM/YYYY');
        break;
      case TableCellTypes.Button:
        if (config.renderCondition !== undefined) {
          if (helperService.renderButtonByCondition(source[config.renderCondition[0].propertyName], config.renderCondition)) {
            resultElement = (<Button variant="contained" color={config.buttonColor} onClick={() => config.function(source)} onContextMenu={() => config.function(source, true)}>{config.buttonLabel}</Button>);
          } else {
            resultElement = '';
          }
        } else {
          resultElement = (<Button variant="contained" color={config.buttonColor} onClick={() => config.function(source)} onContextMenu={() => config.function(source, true)}>{config.buttonLabel}</Button>);
        }
        break;
      case TableCellTypes.IconButton:
        if (config.renderCondition !== undefined) {
          if (helperService.renderButtonByCondition(source[config.renderCondition[0].propertyName], config.renderCondition)) {
            resultElement = (<IconButton color={config.buttonColor} component="span" onClick={() => config.function(source)}>{config.iconElement}</IconButton>);
          } else {
            resultElement = '';
          }
        } else {
          resultElement = <IconButton color={config.buttonColor} component="span" onClick={() => config.function(source)}>{config.iconElement}</IconButton>;
        }
        break;
      case TableCellTypes.Chip:
        resultElement = (<Chip style={{ backgroundColor: helperService.renderChipCredentials(source[config.propertyValue], t).color, color: 'white' }} label={helperService.renderChipCredentials(source[config.propertyValue], t).text} />);
        break;
      case TableCellTypes.ConcatText:
        config.propertyNames.forEach((prop) => {
          resultElement += `${source[prop] == null ? 'SuperAdm' : source[prop]} `;
        });
        break;
      case TableCellTypes.AudioFile:
        resultElement = (<AudioRecorder recordMode={false} isRecordingProp={AudioRecordActions.NONE} audioUrlProp={source[config.propertyName]} />);
        break;
      case TableCellTypes.Menu: {
        const copyOptions = _.cloneDeep(config.options); // deep copy of array with lodash
        for (let i = 0; i < copyOptions.length; i++) {
          if (copyOptions[i].renderCondition !== undefined) {
            const renderCondition = helperService.renderButtonByCondition(source[config.options[i].renderCondition[0].propertyName], config.options[i].renderCondition);
            copyOptions[i].visible = config.options[i].visible && renderCondition;
          }
        }
        resultElement = (<CustomMenu menuOptions={copyOptions} source={source} />);
        break;
      }
      default:
        break;
    }

    return resultElement;
  };

  const getRowColor = (source) => {
    if (dataConfig.rowsColorCondition !== undefined) {
      if (source[dataConfig.rowsColorCondition.propertyName] === dataConfig.rowsColorCondition.propertyValue) {
        return '#FF7E79';
      }
    }
    return '';
  };

  useEffect(() => {
    if (dataSource !== null) {
      setIsLoading(false);
    }
  }, [dataSource]);
  return (
    <>
      <Card sx={{ m: 2 }}>
        {dataSource === null
          ? (
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2
            }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2
                }}
              >
                <CardHeader title={
                  dataConfig.headerButtons.length > 0
                    ? (
                      <Box>
                        {dataConfig.headerButtons[0]}
                      </Box>
                    ) : `${t(dataConfig.tableTitle)}`
                }
                />
                {dataConfig.isFullMode
                  ? (
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        onKeyUp={handleSearching}
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Button onClick={(e) => handleSearching(e)}>{t('BUTTONS.reset')}</Button>
                    </Box>
                  ) : ''}
              </Box>
              <Divider />

              {dataSource.data.length === 0 ? (
                <CustomNoResultTemplate />
              ) : (
                <PerfectScrollbar>
                  {isLoading ? <LinearProgress /> : ''}
                  <Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {dataConfig.columns.map((col) => (
                            <TableCell align="center">{t(col.label)}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataSource.data.map((source) => (
                          <TableRow style={{ backgroundColor: getRowColor(source) }}>
                            {dataConfig.columns.map((config) => (
                              <TableCell component="th" scope="row" align="center">
                                {renderElementByType(source, config)}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  {isLoading ? <LinearProgress /> : ''}
                </PerfectScrollbar>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                {dataConfig.isFullMode
                  ? (
                    <>
                      <TablePagination
                        component="div"
                        count={dataSource.count}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    </>
                  ) : ''}
                {dataConfig.isViewAllButton
                  ? (
                    <>
                      <Button color="primary" onClick={dataConfig.viewAllNavigation}>View all</Button>
                    </>
                  ) : ''}
              </Box>
            </Box>
          )}
      </Card>
    </>
  );
};
export default CustomTable;

import { useTranslation } from 'react-i18next';
import {
  Dialog, Typography, DialogActions, DialogContent, DialogTitle, Button
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ConfirmRemovingAudioFile(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, confirm
  } = props;
  ConfirmRemovingAudioFile.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
  };

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('DIALOGS.confirmAudioFileRemoving')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5" display="block" gutterBottom>
            {t('DIALOGS.confirmAudioFileRemovingText')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.no')}
          </Button>
          <Button onClick={() => { confirm(); }} color="secondary">
            {t('BUTTONS.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import commonEn from './translations/en.json';
import commonUkr from './translations/ua.json';
import commonRu from './translations/ru.json';
import store from './store/index';
import App from './App';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'ukr',
  resources: {
    en: {
      common: commonEn
    },
    ukr: {
      common: commonUkr
    },
    ru: {
      common: commonRu
    }
  },
});

ReactDOM.render((

  <BrowserRouter>
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
), document.getElementById('root'));

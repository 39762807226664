import httpService from './httpService';

const save = (object) => httpService.post('/api/file/create', object);

const remove = (id, projectId, interviewId) => httpService.post(`/api/file/remove/${id}/${projectId}/${interviewId}`);

const update = (id, object, projectId) => httpService.post(`/api/file/update/${id}/${projectId}`, object);

const getPaged = (interviewId, paggingSettings) => httpService.post(`/api/file/getPaged/${interviewId}`, paggingSettings);

export default {
  save,
  remove,
  update,
  getPaged
};

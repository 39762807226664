import { useEffect, useState } from 'react';
import languageService from 'src/services/languageService';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';

const Language = () => {
  const [languageResponse, setLanguageResponse] = useState(null);
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getLanguages(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getLanguages(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getLanguages(defaultPaggingSettings);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: '',
    columns: [
      {
        label: 'TABLE.name',
        propertyName: 'name',
        type: TableCellTypes.Text
      }
    ]
  };
  const getLanguages = async (paggingSettings) => {
    setLanguageResponse(null);
    const response = await languageService.getPaged(paggingSettings);
    setLanguageResponse(response);
  };

  useEffect(() => {
    getLanguages(defaultPaggingSettings);
  }, []);

  return (
    <>
      <CustomTable dataSource={languageResponse} dataConfig={dataConfig} />
    </>
  );
};

export default Language;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import {
  Dialog,
  // FormControl,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Select,
  Button,
  // InputLabel,
  // MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import answerTemplateService from 'src/services/answerTemplateService';

export default function AddNewQuestion(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, questionData
  } = props;
  const [questionObjectData, setQuestionObjectData] = useState({
    questionText: null,
    // answerTemplateId: null
  });
  const initialState = {
    questionText: null,
    // answerTemplateId: null
  };
  // const [answerTemplateList, setAnswerTemplateList] = useState([]);
  AddNewQuestion.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    questionData: PropTypes.object
  };

  const handleChange = (event) => {
    setQuestionObjectData({
      ...questionObjectData,
      [event.target.name]: event.target.value
    });
  };

  const validateEditForm = () => {
    if (Object.keys(questionData).length !== 0) {
      return questionObjectData.questionText !== null;
      // || questionObjectData.answerTemplateId !== null;
    }
  };

  const validate = (object) => (
    validateEditForm()
    || (object.questionText !== null));
  // && object.answerTemplateId !== null));

  /* const getAnswerTemplates = async () => {
    const response = await answerTemplateService.getAll();
    setAnswerTemplateList(response);
  }; */

  // useEffect(() => { getAnswerTemplates(); }, []);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {Object.keys(questionData).length === 0
            ? t('FORMS.addNewQuestion')
            : t('FORMS.editQuestion')}
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rowsMax={8}
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="questionText"
            label={t('FORMS.name')}
            name="questionText"
            type="text"
            fullWidth
            size="small"
            defaultValue={questionData === undefined ? '' : questionData.questionText}
          />
          {/* <FormControl variant="outlined" style={{ minWidth: '100%', 'margin-top': 10 }}>
            <InputLabel id="demo-simple-select-label">{t('FORMS.answerTemplate')}</InputLabel>
            <Select
              name="answerTemplateId"
              onChange={handleChange}
              onBlur={handleChange}
              defaultValue={questionData === undefined ? '' : questionData.answerTemplateId}
              fullWidth
            >
              {answerTemplateList.map((template) => (<MenuItem key={template.id} value={template.id}>{template.description}</MenuItem>))}
            </Select>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(questionObjectData); setQuestionObjectData(initialState); }} color="primary" disabled={!validate(questionObjectData)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: process.env.NODE_ENV === 'development' ? '#2E8B57' : '#0099B8'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  shadows,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mdCustom: 770,
      md: 960,
      lg: 1280,
      xl: 1920
    },
  },
});

export default theme;

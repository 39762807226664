import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const FinishInterview = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const storedUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const goToInterview = () => {
    navigate(`../single-interview/${storedUser.activeInterviewId}`, { replace: true });
    dispatch({ type: 'removeProperty', user: 'activeInterviewId' });
    dispatch({ type: 'updateUser', user: { redirectAfterTesting: true } });
  };
  return (
    <>
      <Helmet>
        <title>{`QQDC | ${t('BROWSER_TAB_TITLES.finishInterview')}`}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            {t('SINGLE_PAGE.finishInterviewText')}
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Button variant="contained" onClick={goToInterview}>{t('BUTTONS.redirectToInterviewCard')}</Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FinishInterview;

import {
  Card,
  CardContent
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';

const RespondentProfileDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { interviews } = props;
  RespondentProfileDetails.propTypes = {
    interviews: PropTypes.object.isRequired
  };
  const redirectToAllInterviews = () => {
    navigate('app/interviews', { replace: true });
  };

  const redirectToSingleInterview = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../single-interview/${object.id}`, '_blank');
      return;
    }
    navigate(`../../single-interview/${object.id}`, { replace: true, state: { backRoute: location.pathname } });
  };

  const dataConfig = {
    isFullMode: false,
    headerButtons: [],
    isViewAllButton: false,
    tableTitle: 'TABLE_LABELS.interviews',
    viewAllNavigation: redirectToAllInterviews,
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToSingleInterview
      },
      {
        label: 'TABLE.projectName',
        propertyName: 'projectName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.status',
        propertyName: 'statusName',
        propertyValue: 'statusId',
        type: TableCellTypes.Chip
      },
      {
        label: 'TABLE.startDate',
        propertyName: 'startDatetime',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.finishDate',
        propertyName: 'finishDatetime',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.researcher',
        propertyNames: ['researcherSurname', 'researcherName', 'researcherPatronymic'],
        type: TableCellTypes.ConcatText
      }
    ]
  };
  return (
    <>
      <Card>
        <CardContent>
          <CustomTable dataSource={interviews} dataConfig={dataConfig} />
        </CardContent>
      </Card>
    </>
  );
};

export default RespondentProfileDetails;

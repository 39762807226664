import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Chip,
  Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfirmRemovingRespondent from '../../dialogs/ConfirmRemovingRespondent';
import AddRespondent from '../../dialogs/AddRespondent';
import CustomNotification from '../CustomNotification';
import CustomMenu from '../CustomMenu';
import CustomBackdrop from '../CustomBackdrop';
import respondentService from '../../services/respondentService';

const ResearcherProfile = (props) => {
  const navigate = useNavigate();
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [showDialog, setShowDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});
  const [openNotification, setOpenNotification] = useState(false);
  const [menuRespondent, setRespondentMenu] = useState([]);
  const { userData, updateData } = props;
  ResearcherProfile.propTypes = {
    userData: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired
  };
  const openAddRespondentDialog = () => {
    setShowDialog(true);
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const cancelConfirming = () => {
    setShowConfirmDialog(false);
  };
  const confirmRemoveRespondent = async () => {
    const response = await respondentService.remove(userData.id);
    setCurrentResponse(response);
    setShowConfirmDialog(false);
    if (response > 0) {
      navigate(`../../single-project/${storedUser.projectId}`);
    }
  };
  const sendDialog = async (userObject) => {
    setShowDialog(false);
    setLoading(true);
    const userObjectCopy = userObject;
    userObjectCopy.projectId = +storedUser.projectId;
    userObjectCopy.age = +userObject.age === 0 ? null : +userObject.age;
    const response = await respondentService.update(userData.id, userObjectCopy);
    setCurrentResponse(response);
    if (response === 1) {
      updateData();
    }
    setLoading(false);
    setOpenNotification(true);
  };
  const removeRespondent = () => {
    setShowConfirmDialog(true);
  };
  const menuOptions = [
    {
      id: 1,
      text: t('BUTTONS.edit'),
      visible: true,
      function: openAddRespondentDialog
    },
    {
      id: 2,
      text: t('SINGLE_PAGE.removeInterview'),
      visible: true,
      function: removeRespondent,
      textColor: 'red'
    }
  ];
  const navigateToSingleProject = (project) => {
    navigate(`../../single-project/${project.id}`);
  };

  useEffect(() => {
    if (storedUser.isSuperAdmin === 1 || storedUser.id === userData.createdById) {
      setRespondentMenu(<CustomMenu menuOptions={menuOptions} />);
    }
  }, [userData]);

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.respondentUpdated')}
        type="success"
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <ConfirmRemovingRespondent open={showConfirmDialog} cancel={cancelConfirming} confirm={confirmRemoveRespondent} />
      <AddRespondent open={showDialog} cancel={cancelDialog} send={sendDialog} userObjectProp={userData} />
      <Card {...props}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {t('SINGLE_PAGE.respondentNumber')}
              {userData.id}
            </Typography>
            {menuRespondent}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'left',
              display: 'flex',
              flexDirection: 'column',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.name')}</b>
              {userData.name}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.surname')}</b>
              {userData.surname}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.patronymic')}</b>
              {userData.patronymic}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.pseudonym')}</b>
              {userData.pseudonym}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.age')}</b>
              {userData.age}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.dateOfBirth')}</b>
              {`${moment(userData.dateOfBirth).format('DD/MM/YYYY')}`}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.genderLabel')}</b>
              {userData.genderName}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.familyState')}</b>
              {userData.familyStateName}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.educationState')}</b>
              {userData.educationStateName}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.languageCommunication')}</b>
              {userData.languageCommunication}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.typeOfEmployment')}</b>
              {userData.typeOfEmployment}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.employmentName')}</b>
              {userData.employmentStateName}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.livingPlace')}</b>
              {userData.livingPlace}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.projects')}</b>
              {userData.projects !== undefined ? (
                <>
                  {userData.projects.map((project, index) => (
                    <Link component="button" onClick={() => navigateToSingleProject(project)} sx={{ fontSize: 17 }}>
                      {`${index === 0 ? ' ' : ', '} ${project.name}`}
                    </Link>
                  ))}
                </>
              ) : ''}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.createdBy')}</b>
              {userData.researcherSurname}
              <span> </span>
              {userData.researcherName}
              <span> </span>
              {userData.researcherPatronymic}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.dateCreated')}</b>
              {`${moment(userData.dateCreated).format('DD/MM/YYYY HH:mm:ss')}`}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'left',
              display: 'flex',
              flexDirection: 'column',
              p: 1
            }}
          >
            {userData.currentUserSuperAdmin === 1 ? (
              <>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.superAdmin')}</b>
                  {userData.isSuperAdmin ? <Chip label="Yes" color="primary" /> : <Chip label="No" color="error" />}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.allowToLogin')}</b>
                  {userData.isAllowToLogin ? <Chip label="Yes" color="primary" /> : <Chip label="No" color="error" />}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.login')}</b>
                  {userData.login}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.datePasswordChanged')}</b>
                  {`${moment(userData.datePasswordChanged).format('DD/MM/YYYY HH:mm:ss')}`}
                </Typography>
              </>
            )
              : ''}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ResearcherProfile;

import { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import genderService from '../services/genderService';
import educationStateService from '../services/educationStateService';
import employmentStateService from '../services/employmentStateService';
import familyStateService from '../services/familyStateService';

const initialState = {
  name: null,
  surname: null,
  patronymic: null,
  pseudonym: null,
  age: null,
  dateOfBirth: null,
  genderId: null,
  familyStateId: null,
  educationStateId: null,
  languageCommunication: null,
  typeOfEmployment: null,
  employmentStateId: null,
  livingPlace: null
};

export default function AddRespondent(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, userObjectProp
  } = props;
  const [userData, setUserData] = useState(initialState);
  const [genderList, setGenderList] = useState([]);
  const [educationStateList, setEducationStateList] = useState([]);
  const [employmentStateList, setEmploymentStateList] = useState([]);
  const [familyStateList, setFamilyStateList] = useState([]);
  AddRespondent.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    userObjectProp: PropTypes.object
  };

  const getGenderList = async () => {
    const response = await genderService.getAll();
    setGenderList(response);
  };
  const getEducationStateList = async () => {
    const response = await educationStateService.getAll();
    setEducationStateList(response);
  };
  const getEmploymentStateList = async () => {
    const response = await employmentStateService.getAll();
    setEmploymentStateList(response);
  };
  const getFamilyStateList = async () => {
    const response = await familyStateService.getAll();
    setFamilyStateList(response);
  };

  useEffect(() => {
    setUserData(initialState);
  }, [cancel]);

  useEffect(async () => {
    if (open) {
      await getGenderList();
      await getEducationStateList();
      await getEmploymentStateList();
      await getFamilyStateList();
    }
  }, [open]);

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value
    });
  };
  const validateForm = () => {
    if (userObjectProp !== undefined) {
      if (userData.name === ''
        || userData.surname === ''
        || userData.age === ''
        || (userData.age !== null && +userData.age <= 0)
        || userData.genderId === ''
        || userData.pseudonym === '') {
        // block
        return false;
      }
      return true;
    }
  };

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{userObjectProp === undefined ? t('FORMS.newRespondent') : t('FORMS.editRespondent')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {userObjectProp === undefined ? t('FORMS.fillFieldsToCreateRespondent') : ''}
          </DialogContentText>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="name"
            label={t('FORMS.name')}
            name="name"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.name}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="surname"
            label={t('FORMS.surname')}
            name="surname"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.surname}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="patronymic"
            label={t('FORMS.patronymic')}
            name="patronymic"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.patronymic}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="pseudonym"
            label={t('FORMS.pseudonym')}
            name="pseudonym"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.pseudonym}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="age"
            label={t('FORMS.age')}
            name="age"
            type="number"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? 0 : userObjectProp.age}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="dateOfBirth"
            label={t('FORMS.dateOfBirth')}
            name="dateOfBirth"
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={userObjectProp === undefined ? null : `${moment(userObjectProp.dateOfBirth).format('YYYY-MM-DD')}`}
          />
          <FormControl variant="outlined" sx={{ width: '100%', mb: 1.5 }}>
            <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.gender')}</InputLabel>
            <Select
              name="genderId"
              onChange={handleChange}
              label={t('FORMS.gender')}
              defaultValue={userObjectProp.genderId}
            >
              {genderList.map((gender) => (
                <MenuItem value={gender.id} key={gender.id}>
                  {gender.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ width: '100%', mb: 1.5 }}>
            <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.familyState')}</InputLabel>
            <Select
              name="familyStateId"
              onChange={handleChange}
              label={t('FORMS.familyState')}
              defaultValue={userObjectProp.familyStateId}
            >
              {familyStateList.map((state) => (
                <MenuItem value={state.id} key={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ width: '100%', mb: 1.5 }}>
            <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.educationState')}</InputLabel>
            <Select
              name="educationStateId"
              onChange={handleChange}
              label={t('FORMS.educationState')}
              defaultValue={userObjectProp.educationStateId}
            >
              {educationStateList.map((state) => (
                <MenuItem value={state.id} key={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="languageCommunication"
            label={t('FORMS.languageCommunication')}
            name="languageCommunication"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.languageCommunication}
          />
          <TextField
            sx={{ mb: 1 }}
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="typeOfEmployment"
            label={t('FORMS.typeOfEmployment')}
            name="typeOfEmployment"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.typeOfEmployment}
          />
          <FormControl variant="outlined" sx={{ width: '100%', mb: 1.5 }}>
            <InputLabel id="demo-simple-select-outlined-label">{t('FORMS.employmentState')}</InputLabel>
            <Select
              name="employmentStateId"
              onChange={handleChange}
              label={t('FORMS.employmentState')}
              defaultValue={userObjectProp.employmentStateId}
            >
              {employmentStateList.map((state) => (
                <MenuItem value={state.id} key={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="livingPlace"
            label={t('FORMS.livingPlace')}
            name="livingPlace"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.livingPlace}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(userData); }} color="primary" disabled={!validateForm(userData)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

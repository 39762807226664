import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import respondentService from '../services/respondentService';
import CustomTable from '../components/CustomTable';
import TableCellTypes from '../enums/TableCellTypes';

const Respodents = () => {
  const storedUser = useSelector((state) => state.user);
  const [respondentsResponse, setRespondentsResponse] = useState(null);
  const navigate = useNavigate();
  const redirectToFullRespondent = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../single-respondent/${object.id}`, '_blank');
      return;
    }
    navigate(`../../single-respondent/${object.id}`, { replace: true });
  };
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getRespondents(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getRespondents(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getRespondents(defaultPaggingSettings);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: 'TABLE_LABELS.respondents',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToFullRespondent
      },
      {
        label: 'TABLE.pseudonym',
        propertyName: 'pseudonym',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.age',
        propertyName: 'age',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.gender',
        propertyName: 'genderName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.familyState',
        propertyName: 'familyStateName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.educationState',
        propertyName: 'educationStateName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.dateCreated',
        propertyName: 'dateCreated',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.createdBy',
        propertyNames: ['researcherSurname', 'researcherName', 'researcherPatronymic'],
        type: TableCellTypes.ConcatText
      }
    ]
  };
  const getRespondents = async (paggingSettings) => {
    setRespondentsResponse(null);
    const response = await respondentService.getPaged(storedUser.projectId, paggingSettings);
    setRespondentsResponse(response);
  };

  useEffect(() => {
    getRespondents(defaultPaggingSettings);
  }, []);

  return (
    <>
      <CustomTable dataSource={respondentsResponse} dataConfig={dataConfig} />
    </>
  );
};

export default Respodents;

import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function ForgotPasswordDialog(props) {
  const [t] = useTranslation('common');
  const { open, cancel, send } = props;
  const [email, setEmail] = React.useState('');
  ForgotPasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (emailInput) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailInput !== '' && re.test(String(emailInput).toLowerCase());
  };

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('FORMS.forgotPassword')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('FORMS.typeEmailToSendPassword')}
          </DialogContentText>
          <TextField
            onChange={handleChange}
            autoFocus
            margin="dense"
            id="name"
            label={t('FORMS.email')}
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(email); }} color="primary" disabled={!validateEmail(email)}>
            {t('BUTTONS.send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

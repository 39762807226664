import httpService from './httpService';

const getPaged = (projectId, paggingSettings) => httpService.post(`/api/researchers/getPaged/${projectId}`, paggingSettings);

const getAllNotInvolvedToProject = (projectId) => httpService.get(`/api/researchers/getAllNotInProject/${projectId}`);

const getSimplifyById = (researcherId) => httpService.get(`/api/researchers/getsimplify/${researcherId}`);

const getSingle = (researcherId, projectId) => httpService.get(`/api/researchers/single/${researcherId}/${projectId}`);

const save = (userData) => httpService.post('/api/researchers/create', filterObject(userData));

const update = (id, userData) => httpService.post(`/api/researchers/update/${id}`, filterObject(userData));

const attachResearcherToProject = (researcherProjectData) => httpService.post('/api/researchers/attach', filterObject(researcherProjectData));

const removeResearcherFromProject = (id, projectId) => httpService.post(`/api/researchers/removefromproject/${id}/${projectId}`);

const filterObject = (object) => {
  const obj = {};
  Object.keys(object).forEach((key) => {
    if (object[key] !== null) {
      obj[key] = object[key];
    }
  });
  return obj;
};

export default {
  getSingle, getSimplifyById, getPaged, save, update, getAllNotInvolvedToProject, attachResearcherToProject, removeResearcherFromProject
};

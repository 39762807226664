import httpService from './httpService';
import helperService from './helperService';

const getPaged = (paggingSettings) => httpService.post('/api/methodics/getPaged', paggingSettings);

const getAllByProjectId = (projectId, languageId) => httpService.get(`/api/methodics/getAllByProjectId/${projectId}/${languageId}`);

const getById = (methodicId) => httpService.get(`/api/methodics/getById/${methodicId}`);

const save = (methodicData) => httpService.post('/api/methodics/create', helperService.filterObject(methodicData));

const update = (id, methodicData) => httpService.post(`/api/methodics/update/${id}`, helperService.filterObject(methodicData));

const getSourceMethodics = (projectId, languageId) => httpService.get(`/api/methodics/getSource/${projectId}/${languageId}`);

const assignMethodicsForProject = (projectId, objectArray) => httpService.post(`/api/methodics/assign/${projectId}`, objectArray);

const getNotUsedMethodics = (interviewId, projectId, languageId) => httpService.get(`/api/methodics/notUsed/${interviewId}/${projectId}/${languageId}`);

export default {
  getPaged, getById, save, update, getSourceMethodics, assignMethodicsForProject, getAllByProjectId, getNotUsedMethodics
};

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Container,
  Tab,
  Tabs
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InterviewsPublish from 'src/pages/InterviewsPublish';
import Researchers from '../../pages/Researchers';
import Respondents from '../../pages/Respondents';
import Interviews from '../../pages/Interviews';
import ProjectAttachedMethodics from './ProjectAttachedMethodics';
import Keywords from '../../pages/Keywords';

const ProjectDetails = () => {
  const storedUser = useSelector((state) => state.user);
  const [t] = useTranslation('common');
  const [currentValue, setCurrentValue] = useState(0);
  function TabPanel(propsPanel) {
    const {
      children, value, index, ...other
    } = propsPanel;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    setCurrentValue(newValue);
  };
  useEffect(() => { }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Container maxWidth="false">
            {!storedUser.publishMode
              ? (
                <Box>
                  <Tabs
                    value={currentValue}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    onChange={handleChange}
                  >
                    <Tab label={t('TABS.interviews')} />
                    <Tab label={t('TABS.respondents')} />
                    <Tab label={t('TABS.researchers')} />
                    <Tab label={t('TABS.methodics')} />
                    <Tab label={t('TABS.keywords')} />
                  </Tabs>
                  <TabPanel value={currentValue} index={0}>
                    <Box sx={{ ml: -3 }}>
                      <Interviews />
                    </Box>
                  </TabPanel>
                  <TabPanel value={currentValue} index={1}>
                    <Box sx={{ ml: -3 }}>
                      <Respondents />
                    </Box>
                  </TabPanel>
                  <TabPanel value={currentValue} index={2}>
                    <Box sx={{ ml: -3 }}>
                      <Researchers />
                    </Box>
                  </TabPanel>
                  <TabPanel value={currentValue} index={3}>
                    <Box sx={{ ml: -3 }}>
                      <ProjectAttachedMethodics />
                    </Box>
                  </TabPanel>
                  <TabPanel value={currentValue} index={4}>
                    <Box sx={{ ml: -3 }}>
                      <Keywords />
                    </Box>
                  </TabPanel>
                </Box>
              ) : (
                <Box>
                  <Tabs
                    value={currentValue}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    onChange={handleChange}
                  >
                    <Tab label={t('TABS.interviews')} />
                  </Tabs>
                  <TabPanel value={currentValue} index={0}>
                    <Box sx={{ ml: -3 }}>
                      <InterviewsPublish />
                    </Box>
                  </TabPanel>
                </Box>
              )}
          </Container>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectDetails;

import StatusEnum from '../enums/StatusEnum';

const filterObject = (object) => {
  const obj = {};
  Object.keys(object).forEach((key) => {
    if (object[key] !== null) {
      obj[key] = object[key];
    }
  });
  return obj;
};

const renderChipCredentials = (statusId, t) => {
  switch (statusId) {
    case StatusEnum.INTERRUPTED:
      return { color: 'red', text: t('CHIP.interrupted') };
    case StatusEnum.FINISHED:
      return { color: 'green', text: t('CHIP.finished') };
    case StatusEnum.ACTIVE:
      return { color: '#D1D100', text: t('CHIP.active') };
    default:
      return { color: 'grey', text: 'Default' };
  }
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const renderButtonByCondition = (sourcePropertyValue, renderCondition) => {
  switch (renderCondition[0].conditionOperator) {
    case 'equals':
      return sourcePropertyValue === renderCondition[0].conditionCompareValue;
    case 'notEquals':
      return sourcePropertyValue !== renderCondition[0].conditionCompareValue;
    default:
      return false;
  }
};

export default {
  filterObject, renderChipCredentials, b64toBlob, renderButtonByCondition
};

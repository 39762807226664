import {
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  Divider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import AddNewQuestion from 'src/dialogs/AddNewQuestion';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';
import questionService from '../../services/questionService';
import CustomBackdrop from '../CustomBackdrop';
import CustomNotification from '../CustomNotification';

const RespondentProfileDetails = (props) => {
  const [t] = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [notificationText, setNotificationText] = useState('');
  const [currentResponse, setCurrentResponse] = useState({});
  const [questionsResponse, setQuestionResponse] = useState(null);
  const storedUser = useSelector((state) => state.user);
  const { questions, methodic, updateParent } = props;
  RespondentProfileDetails.propTypes = {
    questions: PropTypes.object.isRequired,
    methodic: PropTypes.object.isRequired,
    updateParent: PropTypes.func
  };
  const editQuestion = (object) => {
    setShowDialog(true);
    setQuestionData(object);
  };
  const showDialogFunc = () => {
    setShowDialog(true);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const cancelDialog = () => {
    setShowDialog(false);
    setQuestionData({});
  };
  const sendDialog = async (object) => {
    const obj = object;
    obj.methodicTypeId = methodic.methodicId;
    obj.id = Object.keys(questionData).length === 0 ? 0 : questionData.id;
    setShowDialog(false);
    setLoading(true);
    if (obj.id === 0) {
      obj.id = null;
      const saveResponse = await questionService.save(obj);
      setCurrentResponse(saveResponse);
      setNotificationText(t('NOTIFICATIONS.questionCreated'));
    } else {
      const updateResponse = await questionService.update(obj.id, obj);
      setCurrentResponse(updateResponse);
      setNotificationText(t('NOTIFICATIONS.questionUpdated'));
    }
    setLoading(false);
    setOpenNotification(true);
    setQuestionData({});
    updateParent();
  };
  /* const removeQuestion = async (object) => {
    setLoading(true);
    const response = await questionService.remove(object);
    setCurrentResponse(response);
    if (response) {
      setNotificationText(t('NOTIFICATIONS.questionRemoved'));
    }
    setOpenNotification(true);
    setLoading(false);
    updateParent();
  }; */
  const dataConfig = {
    headerButtons: [],
    isFullMode: false,
    isViewAllButton: false,
    tableTitle: '',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.question',
        propertyName: 'questionText',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.actions',
        type: TableCellTypes.Menu,
        options: [
          {
            id: 1,
            text: t('TABLE.edit'),
            visible: true,
            function: editQuestion
          },
          /* {
            id: 2,
            text: t('TABLE.remove'),
            textColor: 'red',
            visible: true,
            function: removeQuestion
          } */
        ]
      }
    ]
  };
  useEffect(() => {
    setQuestionResponse(null);
    if (storedUser.isSuperAdmin === 0) {
      dataConfig.columns = dataConfig.columns.splice(0, 2);
    }
    setQuestionResponse(questions);
  }, [props]);

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={notificationText}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <AddNewQuestion open={showDialog} cancel={cancelDialog} send={sendDialog} questionData={questionData} />
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {t('SINGLE_PAGE.questions')}
            </Typography>
            {storedUser.isSuperAdmin === 1 && false ? (
              <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={showDialogFunc}
              >
                {t('BUTTONS.addNewQuestion')}
              </Button>
            ) : ''}
          </Box>
          <Divider />
          <CustomTable dataSource={questionsResponse} dataConfig={dataConfig} />
        </CardContent>
      </Card>
    </>
  );
};

export default RespondentProfileDetails;

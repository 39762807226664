import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoginByAccessCode from 'src/dialogs/LoginByAccessCode';
import loginService from '../services/loginService';
import ResponseCodes from '../enums/ResponseCodes';
import ForgotPassword from '../dialogs/ForgotPassword';
import CustomNotification from '../components/CustomNotification';
import CustomBackdrop from '../components/CustomBackdrop';

const Login = () => {
  const storedUser = useSelector((state) => state.user);
  const [t] = useTranslation('common');
  const { i18n } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorResponseCode, setErrorResponseCode] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});
  const [openNotification, setOpenNotification] = useState(false);
  const [showLoginByAccessCode, setShowLoginByAccessCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const storeUser = (userObject) => {
    dispatch({ type: 'updateUser', user: userObject });
  };

  const loginToSystem = async (login, password) => {
    setLoading(true);
    const response = await loginService.login(login, password);

    if (response.token !== null) {
      localStorage.setItem('token', response.token.toString());
      navigate('/app/dashboard', { replace: true });
      response.publishMode = false;
      storeUser(response);
    } else {
      switch (response.responseCode) {
        case ResponseCodes.PASSWORD_INCORRECT:
          setErrorResponseCode(<Alert severity="error">{t('SINGLE_PAGE.passwordIncorrect')}</Alert>);
          break;

        case ResponseCodes.USER_NOT_EXISTS:
          setErrorResponseCode(<Alert severity="error">{t('SINGLE_PAGE.userNotExist')}</Alert>);
          break;

        case ResponseCodes.NOT_ALLOW_TO_LOGIN:
          setErrorResponseCode(<Alert severity="error">{t('SINGLE_PAGE.cannotLoginToSystem')}</Alert>);
          break;

        default:
          break;
      }
    }

    setLoading(false);
    return response;
  };

  const loginWithAccessCode = (object) => {
    setShowLoginByAccessCode(false);
    if (object.token !== null) {
      localStorage.setItem('token', object.token.toString());
      navigate(`/app/single-project/${object.projectId}`, { replace: true });
      object.publishMode = true;
      storeUser(object);
    }
  };

  const forgotPassword = () => {
    setShowDialog(true);
  };

  const cancelDialog = () => {
    setShowDialog(false);
  };

  const cancelShowLoginByAccessCode = () => {
    setShowLoginByAccessCode(false);
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };

  const sendDialog = async (email) => {
    setShowDialog(false);
    setLoading(true);
    const response = await loginService.forgotPassword(email);
    setCurrentResponse(response);
    setLoading(false);
    setOpenNotification(true);
  };

  const loginByAccessCode = () => {
    setShowLoginByAccessCode(true);
  };

  useEffect(() => {
    if (storedUser.language !== undefined) {
      i18n.changeLanguage(storedUser.language);
    }
  }, []);

  return (
    <>
      <LoginByAccessCode open={showLoginByAccessCode} cancel={cancelShowLoginByAccessCode} send={loginWithAccessCode} />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.passwordSentToEmail')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ mb: 1, display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{ width: '570px' }}
            align="center"
            color="textPrimary"
            variant="h1"
          >
            {errorResponseCode}
          </Typography>
        </Box>
        <Container maxWidth="sm" sx={{ padding: '20px', backgroundColor: 'white', borderRadius: '20px' }}>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(t('SINGLE_PAGE.emailShouldBeValid')).max(255).required(t('SINGLE_PAGE.emailRequired')),
              password: Yup.string().max(255).required(t('SINGLE_PAGE.passwordRequired'))
            })}
            onSubmit={(values) => {
              loginToSystem(values.email, values.password);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    pb: '0.5',
                    pt: '0.5'
                  }}
                >
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="h1"
                  >
                    <img alt="" src="logo.png" height="100px" />
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('FORMS.email')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('FORMS.password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={errors.email || errors.password || values.email === '' || values.password === ''}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('BUTTONS.login')}
                  </Button>
                </Box>
                <Box sx={{ py: 0.1 }} display="flex" justifyContent="center">
                  <Button size="small" color="secondary" onClick={forgotPassword}>{t('BUTTONS.forgotPassword')}</Button>
                  <Button size="small" color="primary" onClick={loginByAccessCode}>{t('BUTTONS.accessCode')}</Button>
                </Box>
              </form>
            )}
          </Formik>
          <ForgotPassword open={showDialog} cancel={cancelDialog} send={sendDialog} />
        </Container>
      </Box>
    </>
  );
};

export default Login;

import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import loginService from '../../services/loginService';
import CustomNotification from '../CustomNotification';

const SettingsPassword = () => {
  const [t] = useTranslation('common');
  const [currentResponse, setCurrentResponse] = useState({});
  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirm: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const changePassword = async () => {
    setIsLoading(true);
    const response = await loginService.changePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword });
    setCurrentResponse(response);
    setIsLoading(false);
    setOpenNotification(true);
    setValues({ oldPassword: '', newPassword: '', confirm: '' });
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.passwordChanged')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <Card>
        {isLoading ? <LinearProgress /> : ''}
        <CardHeader
          title={t('SINGLE_PAGE.password')}
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label={t('SINGLE_PAGE.oldPassword')}
            margin="normal"
            name="oldPassword"
            onChange={handleChange}
            onBlur={handleChange}
            type="password"
            value={values.oldPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={t('SINGLE_PAGE.password')}
            margin="normal"
            name="newPassword"
            onChange={handleChange}
            onBlur={handleChange}
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={t('SINGLE_PAGE.confirmPassword')}
            margin="normal"
            name="confirm"
            onChange={handleChange}
            onBlur={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={changePassword}
            disabled={(values.newPassword === '' || values.confirm === '') || values.newPassword !== values.confirm}
          >
            {t('BUTTONS.change')}
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default SettingsPassword;

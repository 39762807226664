import {
  Box,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StatusEnum from 'src/enums/StatusEnum';
import RoleEnum from 'src/enums/RoleEnum';
import CustomMenu from '../CustomMenu';
import AddNewProject from '../../dialogs/AddNewProject';
import helperService from '../../services/helperService';
import projectService from '../../services/projectService';
import CustomNotification from '../CustomNotification';
import CustomBackdrop from '../CustomBackdrop';
import ConfirmRemovingProject from '../../dialogs/ConfirmRemovingProject';

const ProjectProfile = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation('common');
  const { projectData, updateData } = props;
  const [currentResponse, setCurrentResponse] = useState({});
  const storedUser = useSelector((state) => state.user);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [projectActionMenu, setProjectActionMenu] = useState('');
  const projectDataObject = [
    { text: t('SINGLE_PAGE.name'), value: projectData.name, visible: !storedUser.publishMode },
    { text: t('SINGLE_PAGE.status'), value: (<Chip style={{ backgroundColor: helperService.renderChipCredentials(projectData.statusId, t).color, color: 'white' }} label={helperService.renderChipCredentials(projectData.statusId, t).text} />), visible: !storedUser.publishMode },
    { text: t('SINGLE_PAGE.startDate'), value: moment(projectData.startDate).format('DD/MM/YYYY HH:mm:ss'), visible: !storedUser.publishMode },
    { text: t('SINGLE_PAGE.finishDate'), value: moment(projectData.finishDate).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? t('SINGLE_PAGE.noDate') : moment(projectData.finishDate).format('DD/MM/YYYY HH:mm:ss'), visible: !storedUser.publishMode },
    { text: t('SINGLE_PAGE.description'), value: projectData.description, visible: true },
    { text: t('SINGLE_PAGE.researcherCount'), value: projectData.researcherCount, visible: !storedUser.publishMode },
    { text: t('SINGLE_PAGE.respondentCount'), value: projectData.respondentCount, visible: !storedUser.publishMode },
    { text: t('SINGLE_PAGE.interviewCount'), value: projectData.interviewCount, visible: true },
    { text: t('SINGLE_PAGE.accessCode'), value: projectData.accessCode, visible: (storedUser.isSuperAdmin || projectData.role === RoleEnum.PROJECT_OWNER) && !storedUser.publishMode }
  ];

  ProjectProfile.propTypes = {
    projectData: PropTypes.object.isRequired,
    updateData: PropTypes.func
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const openEditProject = () => {
    setShowDialog(true);
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const sendDialog = async (object) => {
    object.statusId = projectData.statusId;
    setLoading(true);
    const response = await projectService.update(object.id, object);
    setCurrentResponse(response);
    if (response > 0) {
      updateData();
    }
    setOpenNotification(true);
    setShowDialog(false);
    setLoading(false);
  };
  const toggleProjectStatus = async () => {
    const statusId = projectData.statusId === StatusEnum.FINISHED ? StatusEnum.ACTIVE : StatusEnum.FINISHED;
    const response = await projectService.update(projectData.id, { statusId });
    if (response > 0) {
      updateData();
      dispatch({ type: 'updateUser', user: { projectStatusId: statusId } });
    }
  };
  const removeProject = () => {
    setShowConfirmDialog(true);
  };
  const cancelConfirming = () => {
    setShowConfirmDialog(false);
  };
  const confirmRemoveProject = async () => {
    setShowConfirmDialog(false);
    setLoading(true);
    const response = await projectService.remove(projectData.id);
    if (response > 0) {
      navigate('../../home', { replace: true });
    }
    setLoading(false);
  };
  let menuOptions = [
    {
      id: 1,
      text: t('SINGLE_PAGE.edit'),
      visible: true,
      function: openEditProject
    },
    {
      id: 2,
      text: projectData.statusId === StatusEnum.FINISHED ? t('SINGLE_PAGE.startProject') : t('SINGLE_PAGE.finishProject'),
      visible: true,
      function: toggleProjectStatus
    },
    {
      id: 3,
      text: t('TABLE.remove'),
      visible: true,
      textColor: 'red',
      function: removeProject
    }
  ];
  useEffect(() => {
    if (storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN && storedUser.isSuperAdmin !== 1) {
      menuOptions = menuOptions.slice(0, -1);
    }
    setProjectActionMenu(<Box sx={{ mt: -2 }}><CustomMenu menuOptions={menuOptions} /></Box>);
  }, []);
  return (
    <>
      <ConfirmRemovingProject open={showConfirmDialog} cancel={cancelConfirming} send={confirmRemoveProject} />
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.projectUpdated')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <AddNewProject open={showDialog} cancel={cancelDialog} send={sendDialog} projectData={projectData} />
      <Accordion expanded={expanded === true} onChange={handleChange(true)} sx={{ mb: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography><b>{`${t('SINGLE_PAGE.projectData')} "${projectData.name}"`}</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Card {...props}>
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1
                  }}
                >
                  {!storedUser.publishMode
                    ? (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                        >
                          <b>{projectDataObject[0].text}</b>
                          {projectDataObject[0].value}
                        </Typography>
                        {storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
                          ? projectActionMenu : ''}
                      </Box>
                    ) : ''}
                  {projectDataObject.splice(1, projectDataObject.length - 1).filter((i) => i.visible).map((item) => (
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      <b>{item.text}</b>
                      {item.value}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProjectProfile;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, LinearProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import loginService from 'src/services/loginService';

export default function ConfirmRemovingProject(props) {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const {
    open, cancel, send
  } = props;
  const [isPasswordChecking, setIsPasswordChecking] = useState(false);
  const [timer, setTimer] = useState('');
  const [passwordResponse, setPasswordResponse] = useState({
    isError: true,
    errorText: ''
  });
  ConfirmRemovingProject.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired
  };

  const validatePassword = async (password) => {
    setIsPasswordChecking(true);
    if (password !== '') {
      const response = await loginService.validatePassword(storedUser.id, password);
      if (response) {
        setPasswordResponse({ ...passwordResponse, isError: false, errorText: '' });
      } else {
        setPasswordResponse({ ...passwordResponse, isError: true, errorText: t('FORMS.wrongPassword') });
      }
    }
    setIsPasswordChecking(false);
  };

  const handleChangePassword = (event) => {
    if (event.target.value !== '') {
      clearTimeout(timer);
      setTimer(setTimeout(() => validatePassword(event.target.value), 2000));
    } else {
      setPasswordResponse({ ...passwordResponse, isError: true, errorText: t('FORMS.requiredPassword') });
    }
  };

  const validate = () => (!passwordResponse.isError);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('FORMS.warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('DIALOGS.confirmProjectRemoving')}
          </DialogContentText>
          <TextField
            error={passwordResponse.isError}
            helperText={passwordResponse.errorText}
            onBlur={handleChangePassword}
            margin="dense"
            id="template"
            label="Password"
            name={t('FORMS.password')}
            type="password"
            fullWidth
            size="medium"
          />
          {isPasswordChecking ? (<LinearProgress />) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="secondary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(); }} disabled={!validate()} color="primary">
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import methodicService from '../services/methodicService';
import CustomBackdrop from '../components/CustomBackdrop';
import MethodicProfile from '../components/methodic/MethodicProfile';
import Questions from '../components/methodic/Questions';
import BackButton from '../components/BackButton';

const SingleMethodic = () => {
  const [t] = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();
  const [methodicData, setMethodicData] = useState({});
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState(null);
  const update = async () => {
    await getMethodic();
  };
  const getMethodic = async () => {
    setLoading(true);
    setMethodicData('');
    const response = await methodicService.getById(id);
    if (response !== null && response !== '') {
      const methodic = {
        id: response.id,
        name: response.name,
        methodicId: response.methodicId,
        description: response.description
      };

      setQuestions(<Questions
        questions={{ data: response.questions, count: response.questions.length }}
        methodic={methodic}
        updateParent={getMethodic}
      />);
      setMethodicData(methodic);
      setLoading(false);
    } else {
      navigate('../../not-results', { replace: true });
    }
  };

  useEffect(() => { getMethodic(); }, []);

  return (
    <>
      <Helmet>
        <title>
          {`QQDC | ${t('BROWSER_TAB_TITLES.methodicNumber')}`}
          {id}
        </title>
      </Helmet>
      <BackButton route="../../settings" />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <Box
        sx={{
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="false">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={4}
              md={12}
              xs={12}
            >
              <MethodicProfile methodicData={methodicData} updateData={update} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xs={12}
            >
              {questions}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SingleMethodic;

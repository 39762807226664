import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box, Container, Tabs, Tab, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SettingsPassword from 'src/components/settings/SettingsPassword';
import Methodics from 'src/components/settings/Methodics';
import AnswerTemplates from 'src/components/settings/AnswerTemplates';
import Activity from 'src/components/settings/Activity';
import Language from 'src/components/settings/Language';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SettingsView = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>{`QQDC | ${t('BROWSER_TAB_TITLES.settings')}`}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: 2
        }}
      >
        <Container maxWidth="xl">
          <Box>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
            >
              <Tab label={t('TABS.changePassword')} />
              {storedUser.isSuperAdmin === 1
                ? (
                  <Tab label={t('TABS.methodics')} />
                )
                : ''}
              {storedUser.isSuperAdmin === 1
                ? (
                  <Tab label={t('TABS.language')} />
                )
                : ''}
              {storedUser.isSuperAdmin === 1
                ? (
                  <Tab label={t('TABS.answerTemplates')} />
                )
                : ''}
              {storedUser.isSuperAdmin === 1
                ? (
                  <Tab label={t('TABS.activity')} />
                )
                : ''}
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box sx={{ ml: -3 }}>
                <SettingsPassword />
              </Box>
            </TabPanel>
            {storedUser.isSuperAdmin === 1
              ? (
                <>
                  <TabPanel value={value} index={1}>
                    <Box sx={{ ml: -3 }}>
                      <Methodics />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Box sx={{ ml: -3 }}>
                      <Language />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Box sx={{ ml: -3 }}>
                      <AnswerTemplates />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Box sx={{ ml: -3 }}>
                      <Activity />
                    </Box>
                  </TabPanel>
                </>
              )
              : ''}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SettingsView;

import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AttachKeyword from 'src/dialogs/AttachKeyword';
import StatusEnum from '../enums/StatusEnum';
import interviewService from '../services/interviewService';
import CustomTable from '../components/CustomTable';
import TableCellTypes from '../enums/TableCellTypes';
import ExportFilterDialog from '../dialogs/ExportFilterDialog';
import CustomBackdrop from '../components/CustomBackdrop';
import FileTypesEnum from '../enums/FileTypesEnum';

const Interviews = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [interviewsResponse, setInterviewsResponse] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAttachKeywordDialog, setShowAttachKeywordDialog] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const lessThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const redirectToSingleInterview = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../single-interview/${object.id}`, '_blank');
      return;
    }
    navigate(`../../single-interview/${object.id}`, { replace: true });
  };
  const createInterview = () => {
    navigate('../../create-interview', { replace: true });
  };
  const openExportDialog = () => {
    setShowDialog(true);
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const cancelAttachKeywordDialog = () => {
    setShowAttachKeywordDialog(false);
  };
  const fileTypes = [
    {
      text: 'csv',
      value: FileTypesEnum.CSV,
    },
    {
      text: 'xlsx',
      value: FileTypesEnum.EXCEL,
    }
  ];
  const download = (response, type) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    const fileType = fileTypes[fileTypes.findIndex((i) => i.value === type)].text;
    link.setAttribute('download', `interviews.${fileType}`);
    document.body.appendChild(link);
    link.click();
  };
  const sendDialog = async (object) => {
    setShowDialog(false);
    setLoading(true);
    const response = await interviewService.exportData(storedUser.projectId, object);
    if (response != null) {
      download(response, object.fileType);
    }
    setLoading(false);
  };
  const sendAttachKeyword = async (array) => {
    const keyWordPaggingSettings = {
      CurrentPage: 1,
      Search: '',
      RowsPerPage: 100,
      keyWordIds: array.length === 0 ? [] : array.map((i) => i.id)
    };
    setShowAttachKeywordDialog(false);
    await getInterviews(keyWordPaggingSettings);
  };
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getInterviews(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getInterviews(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getInterviews(defaultPaggingSettings);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: 'TABLE_LABELS.interviews',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToSingleInterview
      },
      {
        label: 'TABLE.respondent',
        propertyNames: ['respondentSurname', 'respondentName', 'respondentPatronymic'],
        type: TableCellTypes.ConcatText
      },
      {
        label: 'TABLE.researcher',
        propertyNames: ['researcherSurname', 'researcherName', 'researcherPatronymic'],
        type: TableCellTypes.ConcatText
      },
      {
        label: 'TABLE.startDate',
        propertyName: 'startDatetime',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.finishDate',
        propertyName: 'finishDatetime',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.status',
        propertyName: 'statusName',
        propertyValue: 'statusId',
        type: TableCellTypes.Chip
      }
    ]
  };
  const getInterviews = async (paggingSettings) => {
    setInterviewsResponse(null);
    const response = await interviewService.getPaged(storedUser.projectId, paggingSettings);
    setInterviewsResponse(response);
  };

  const openFilterByKeyWordDialog = () => {
    setShowAttachKeywordDialog(true);
  };

  useEffect(() => {
    getInterviews(defaultPaggingSettings);
  }, []);

  return (
    <>
      <AttachKeyword open={showAttachKeywordDialog} cancel={cancelAttachKeywordDialog} send={sendAttachKeyword} attachedKeywords={[]} filterMode="true" />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <Box sx={{ m: 2, display: 'flex', flexDirection: lessThanLg ? 'column' : 'row' }}>
        {storedUser.projectStatusId === StatusEnum.ACTIVE
          ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={createInterview}
            >
              {t('BUTTONS.createInterview')}
            </Button>
          ) : ''}
        <Button
          sx={{ ml: lessThanLg ? 0 : 1, mt: lessThanLg ? 1 : 0 }}
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={openExportDialog}
        >
          {t('BUTTONS.exportData')}
        </Button>
        <Button
          sx={{ ml: lessThanLg ? 0 : 1, mt: lessThanLg ? 1 : 0 }}
          variant="contained"
          color="primary"
          startIcon={<LocalOfferIcon />}
          onClick={openFilterByKeyWordDialog}
        >
          {t('BUTTONS.filterByKeywords')}
        </Button>
      </Box>
      <ExportFilterDialog open={showDialog} cancel={cancelDialog} send={sendDialog} />
      <CustomTable dataSource={interviewsResponse} dataConfig={dataConfig} />
    </>
  );
};

export default Interviews;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import projectService from 'src/services/projectService';
import RoleEnum from 'src/enums/RoleEnum';
import loginService from '../services/loginService';
import roleService from '../services/roleService';

const initialState = {
  name: null,
  surname: null,
  patronymic: null,
  dateOfBirth: null,
  login: null,
  isAllowToLogin: null,
  isSuperAdmin: null,
  isAllowToCreateProject: null,
  projectId: null,
  projectRole: null,
  systemRoleId: null
};

export default function AddResearcher(props) {
  const storedUser = useSelector((state) => state.user);
  const [t] = useTranslation('common');
  const {
    open, cancel, send, userObjectProp
  } = props;
  const [timer, setTimer] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [systemRoleList, setSystemRoleList] = useState([]);
  const [isChekingEmail, setIsChekingEmail] = useState(false);
  const [emailResponse, setEmailResponse] = useState({
    isError: false,
    errorText: ''
  });
  const getSystemRoles = async () => {
    const response = await roleService.getAll();
    setSystemRoleList(response);
  };
  const [userData, setUserData] = useState({
    name: null,
    surname: null,
    patronymic: null,
    dateOfBirth: null,
    login: null,
    isAllowToLogin: null,
    isSuperAdmin: null,
    isAllowToCreateProject: null,
    projectId: null,
    projectRole: null,
    systemRoleId: null
  });
  AddResearcher.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    userObjectProp: PropTypes.object
  };

  const getProjectList = async () => {
    const response = await projectService.getAllProjects();
    setProjectList(response);
  };

  useEffect(() => {
    setUserData(initialState);
  }, [cancel]);

  useEffect(() => {
    if ((storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN) && open) {
      getProjectList();
      getSystemRoles();
    }
  }, [open]);

  const handleChange = (event) => {
    if (event.target.type === undefined) { // for select list
      setUserData({
        ...userData,
        [event.target.name]: +event.target.value
      });
      return;
    }

    setUserData({
      ...userData,
      [event.target.name]: event.target.type === 'checkbox' ? +event.target.checked : event.target.value
    });
  };

  const checkIsEmailUnique = async (email) => {
    setIsChekingEmail(true);
    const response = await loginService.checkIsEmailUnique(email);
    // in edit mode compare email for current user - server said that email is not unique, but ui knows that it his email - no need to check
    if (response || (userObjectProp !== undefined && userObjectProp.login === email)) {
      setUserData({ ...userData, login: email });
      setEmailResponse({ ...emailResponse, isError: false, errorText: '' });
    } else {
      setEmailResponse({ ...emailResponse, isError: true, errorText: t('FORMS.currentEmailExists') });
    }
    setIsChekingEmail(false);
  };

  const handleChangeEmail = (event) => {
    if (validateEmail(event.target.value)) {
      clearTimeout(timer);
      setTimer(setTimeout(() => checkIsEmailUnique(event.target.value), 2000));
    }
  };

  const validateEmail = (emailInput) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailInput !== '' && re.test(String(emailInput).toLowerCase());
  };

  const validateEditForm = () => {
    if (userObjectProp !== undefined) {
      return (userData.name !== null && userData.name !== '')
        || (userData.surname !== null && userData.surname !== '')
        || (userData.dateOfBirth !== null && userData.dateOfBirth !== '')
        || (userData.login !== null && userData.login !== '' && validateEmail(userData.login))
        || (userData.projectRole !== null && userData.projectRole !== '')
        || (userData.systemRoleId !== null && userData.systemRoleId !== '')
        || (userData.isAllowToCreateProject !== null && userData.isAllowToCreateProject !== '')
        || (userData.isSuperAdmin !== null && userData.isSuperAdmin !== '')
        || (userData.isAllowToLogin !== null && userData.isAllowToLogin !== '');
    }
  };

  const validateForm = (userObject) => (
    validateEditForm()
    || (userObject.name !== ''
      && userObject.name !== null
      && userObject.surname !== ''
      && userObject.surname !== null
      && userObject.dateOfBirth !== null
      && validateEmail(userObject.login)
      && userObject.projectRole !== null
      && userObject.projectRole !== ''
      && userObject.systemRoleId !== null)
  );

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{userObjectProp === undefined ? t('FORMS.newResearcher') : t('FORMS.editResearcher')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {userObjectProp === undefined ? t('FORMS.fillFieldsToCreateResearacher') : ''}
            <Typography>{`${projectList.length !== 0 ? `${t('SINGLE_PAGE.projectName')}: ${projectList.find((i) => i.id === storedUser.projectId).name}` : ''}`}</Typography>
          </DialogContentText>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="name"
            label={t('FORMS.name')}
            name="name"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.name}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="surname"
            label={t('FORMS.surname')}
            name="surname"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.surname}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="patronymic"
            label={t('FORMS.patronymic')}
            name="patronymic"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.patronymic}
          />
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="dateOfBirth"
            label={t('FORMS.dateOfBirth')}
            name="dateOfBirth"
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={userObjectProp === undefined ? null : `${moment(userObjectProp.dateOfBirth).format('YYYY-MM-DD')}`}
          />
          <TextField
            error={emailResponse.isError}
            helperText={emailResponse.errorText}
            onBlur={handleChangeEmail}
            margin="dense"
            id="email"
            label={t('FORMS.email')}
            name="login"
            type="email"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.login}
          />
          {isChekingEmail ? (<LinearProgress />) : ''}
          <Typography variant="caption" display="block" gutterBottom>
            {t('FORMS.newPasswordWillBeSent')}
          </Typography>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="projectRole"
            label={t('FORMS.projectRole')}
            name="projectRole"
            type="text"
            fullWidth
            size="small"
            defaultValue={userObjectProp === undefined ? '' : userObjectProp.projectRole}
          />
          <FormControl variant="outlined" style={{ minWidth: '100%' }}>
            <InputLabel>{t('FORMS.systemRoleId')}</InputLabel>
            <Select
              size="small"
              name="systemRoleId"
              onChange={handleChange}
              onBlur={handleChange}
              label={t('FORMS.systemRoleId')}
              fullWidth
              defaultValue={userObjectProp === undefined ? '' : userObjectProp.systemRoleId}
            >
              {systemRoleList.map((systemRole) => (<MenuItem key={systemRole.id} value={systemRole.id}>{systemRole.name}</MenuItem>))}
            </Select>
          </FormControl>
          {storedUser.isSuperAdmin === 1
            ? (
              <>
                <FormControlLabel
                  control={
                    (
                      <Checkbox
                        defaultChecked={userObjectProp === undefined ? false : userObjectProp.isAllowToLogin === 1}
                        onChange={handleChange}
                        onBlur={handleChange}
                        name="isAllowToLogin"
                        color="primary"
                      />
                    )
                  }
                  label={t('FORMS.setAllowToLogin')}
                />
                <FormControlLabel
                  control={
                    (
                      <Checkbox
                        defaultChecked={userObjectProp === undefined ? 0 : userObjectProp.isSuperAdmin === 1}
                        onChange={handleChange}
                        onBlur={handleChange}
                        name="isSuperAdmin"
                        color="primary"
                      />
                    )
                  }
                  label={t('FORMS.setSuperAdmin')}
                />
                <FormControlLabel
                  control={
                    (
                      <Checkbox
                        defaultChecked={userObjectProp === undefined ? 0 : userObjectProp.isAllowToCreateProject === 1}
                        onChange={handleChange}
                        onBlur={handleChange}
                        name="isAllowToCreateProject"
                        color="primary"
                      />
                    )
                  }
                  label={t('FORMS.setAllowToCreateProject')}
                />
              </>
            ) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(userData); }} color="primary" disabled={!validateForm(userData)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Box, Button } from '@material-ui/core';
import StatusEnum from 'src/enums/StatusEnum';
import researcherService from '../services/researcherService';
import CustomTable from '../components/CustomTable';
import TableCellTypes from '../enums/TableCellTypes';
import AddResearcher from '../dialogs/AddResearcher';
import AttachResearcher from '../dialogs/AttachResearcher';
import CustomBackdrop from '../components/CustomBackdrop';
import CustomNotification from '../components/CustomNotification';
import RoleEnum from '../enums/RoleEnum';
import ConfirmRemovingResearcher from '../dialogs/ConfirmRemovingResearcher';

const Researchers = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  // const [researchersResponse, setResearchersResponse] = useState(null);
  const [currentResponse, setCurrentResponse] = useState({});
  const [showAddResearcherDialog, setShowAddResearcherDialog] = useState(false);
  const [showAttachResearcherDialog, setShowAttachResearcherDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationText, setNotificationText] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [researcherRemoveId, setResearcherRemoveId] = useState(0);
  const [researcherTable, setResearcherTable] = useState('');
  const navigate = useNavigate();
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const openAddResearcherDialog = () => {
    setShowAddResearcherDialog(true);
  };

  const openAttachResearcherDialog = () => {
    setShowAttachResearcherDialog(true);
  };

  const cancelAddResearcherDialog = () => {
    setShowAddResearcherDialog(false);
  };

  const removeResearcher = () => {
    setShowConfirmDialog(true);
  };

  const cancelConfirming = () => {
    setShowConfirmDialog(false);
  };

  const cancelAttachResearcher = () => {
    setShowAttachResearcherDialog(false);
  };
  const sendAddResearcherDialog = async (userData) => {
    userData.projectId = storedUser.projectId;
    setShowAddResearcherDialog(false);
    setLoading(true);
    const response = await researcherService.save(userData);
    setCurrentResponse(response);
    if (response > 1) {
      await getResearchers(defaultPaggingSettings);
      navigate(`../../single-researcher/${response}`, { replace: true });
      setNotificationText(t('NOTIFICATIONS.researcherCreated'));
    } else {
      setNotificationText(t('NOTIFICATIONS.researcherCreatedError'));
    }
    setLoading(false);
    setOpenNotification(true);
  };
  const redirectToFullResearcher = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../single-researcher/${object.id}`, '_blank');
      return;
    }
    navigate(`../../single-researcher/${object.id}`, { replace: true });
  };
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getResearchers(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getResearchers(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getResearchers(defaultPaggingSettings);
  };
  const removeResearcherFromProject = async (object) => {
    setResearcherRemoveId(object.id);
    removeResearcher();
  };
  const confirmRemoveResearcher = async () => {
    setLoading(true);
    const response = await researcherService.removeResearcherFromProject(researcherRemoveId, storedUser.projectId);
    setCurrentResponse(response);
    if (Number.isNaN(response)) {
      setNotificationText(response);
    } else {
      setNotificationText(t('NOTIFICATIONS.researcherRemoveSuccess'));
    }
    setOpenNotification(true);
    const responseResearcher = await getResearchers(defaultPaggingSettings);
    renderResearcherTable(responseResearcher);
    setLoading(false);
    setShowConfirmDialog(false);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: 'TABLE_LABELS.researchers',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToFullResearcher
      },
      {
        label: 'TABLE.createdBy',
        propertyNames: ['researcherSurname', 'researcherName', 'researcherPatronymic'],
        type: TableCellTypes.ConcatText
      },
      {
        label: 'TABLE.researcher',
        propertyNames: ['surname', 'name', 'patronymic'],
        type: TableCellTypes.ConcatText
      },
      {
        label: 'TABLE.projectRole',
        propertyName: 'projectRole',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.systemRole',
        propertyName: 'systemRoleName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.dateCreated',
        propertyName: 'dateCreated',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.dateOfBirth',
        propertyName: 'dateOfBirth',
        type: TableCellTypes.Date
      },
      {
        label: 'TABLE.remove',
        type: TableCellTypes.Button,
        buttonLabel: 'x',
        buttonColor: 'secondary',
        function: removeResearcherFromProject,
        renderCondition: [{ propertyName: 'systemRoleId', conditionOperator: 'notEquals', conditionCompareValue: 2 }]
      }
    ],
    rowsColorCondition: {
      propertyName: 'projectId',
      propertyValue: null,
      propertyTrueColor: 'coral'
    }
  };
  const getResearchers = async (paggingSettings) => {
    const response = await researcherService.getPaged(storedUser.projectId, paggingSettings);
    return response;
  };
  const sendPostAttachResearcherDialog = async (response) => {
    setLoading(true);
    const responseResearcher = await getResearchers(defaultPaggingSettings);
    renderResearcherTable(responseResearcher);
    setShowAttachResearcherDialog(false);
    setLoading(false);
    setCurrentResponse(response);
    if (Number.isNaN(response)) {
      setNotificationText(response);
    } else {
      setNotificationText(t('NOTIFICATIONS.researcherAttachedSuccess'));
    }
    setOpenNotification(true);
  };

  const renderResearcherTable = (response) => {
    if (storedUser.systemRoleId === RoleEnum.RESEARCHER) {
      dataConfig.columns = dataConfig.columns.slice(0, -1);
    }
    setResearcherTable(<CustomTable dataSource={response} dataConfig={dataConfig} />);
  };

  useEffect(async () => {
    const response = await getResearchers(defaultPaggingSettings);
    renderResearcherTable(response);
  }, [storedUser.systemRoleId]);

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={notificationText}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <AddResearcher open={showAddResearcherDialog} cancel={cancelAddResearcherDialog} send={sendAddResearcherDialog} />
      <AttachResearcher open={showAttachResearcherDialog} cancel={cancelAttachResearcher} send={sendPostAttachResearcherDialog} />
      <ConfirmRemovingResearcher open={showConfirmDialog} cancel={cancelConfirming} confirm={confirmRemoveResearcher} />
      {(storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN) && storedUser.projectStatusId === StatusEnum.ACTIVE ? (
        <>
          <Box
            sx={{
              m: 2,
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<PersonAddIcon />}
              onClick={openAddResearcherDialog}
            >
              {t('BUTTONS.createResearcher')}
            </Button>
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              color="primary"
              startIcon={<AttachFileIcon />}
              onClick={openAttachResearcherDialog}
            >
              {t('BUTTONS.attachResearcher')}
            </Button>
          </Box>
        </>
      ) : ''}
      {researcherTable}
    </>
  );
};

export default Researchers;

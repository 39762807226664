import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import RoleEnum from 'src/enums/RoleEnum';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AddNewComment from 'src/dialogs/AddNewComment';
import helperService from 'src/services/helperService';
import UploadFile from 'src/dialogs/UploadFile';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';
import interviewFileService from '../../services/interviewFileService';
import CustomNotification from '../CustomNotification';
import ConfirmRemovingAudioFile from '../../dialogs/ConfirmRemovingAudioFile';
import ConfirmRemovingTranscriptionFile from '../../dialogs/ConfirmRemovingTranscriptionFile';

const InterviewProfileAudio = (props) => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showTranscriptionConfirmDialog, setShowTranscriptionConfirmDialog] = useState(false);
  const [audioFiles, setAudioFiles] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});
  const [fileId, setFileId] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [audioFilesData, setAudioFileData] = useState([]);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [attachedFileId, setAttachedFileId] = useState(0);
  const [audioFileConfigState, setAudioFileConfigState] = useState({});
  const { interviewData } = props;
  InterviewProfileAudio.propTypes = {
    interviewData: PropTypes.object.isRequired
  };
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 2
  };
  const downloadAudioFile = (object) => {
    const a = document.createElement('a');
    const fileName = object.fileName == null
      ? `Interview#_${object.interviewId}_${interviewData.respondentName}_${interviewData.respondentSurname}_${moment(object.recordDate).format('DD/MM/YYYY HH:mm:ss')}`
      : object.fileName;
    a.setAttribute('download', fileName);
    a.setAttribute('href', object.audioUrl);
    a.click();
    URL.revokeObjectURL(object.audioUrl);
  };

  const removeAudioFile = (object) => {
    setFileId(object.id);
    setShowConfirmDialog(true);
  };

  const cancelUploadFile = () => {
    setOpenUploadFile(false);
  };

  const sendUploadFile = async (fileObject) => {
    const audioFileObject = {
      interviewId: +interviewData.id,
      fileBase64: fileObject.base64.split(',')[1],
      mimeType: fileObject.mimeType,
      fileName: fileObject.fileName,
      parentFileId: fileId
    };
    const response = await interviewFileService.save(audioFileObject);
    setCurrentResponse(response);
    if (response > 0) {
      setOpenUploadFile(false);
      getAudioFiles(defaultPaggingSettings);
    }
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };

  const cancelConfirming = () => {
    setShowConfirmDialog(false);
  };

  const cancelTranscriptionConfirming = () => {
    setShowTranscriptionConfirmDialog(false);
  };

  const cancelDialog = () => {
    setFileId(0);
    setShowDialog(false);
  };

  const sendDialog = async (comment) => {
    if (comment !== '') {
      const response = await interviewFileService.update(fileId, { comment, interviewId: interviewData.id }, storedUser.projectId);
      if (response > 0) {
        setFileId(0);
        setShowDialog(false);
        getAudioFiles(defaultPaggingSettings);
      }
    }
  };

  const confirmRemoveAudioFile = async () => {
    setShowConfirmDialog(false);
    const response = await interviewFileService.remove(fileId, storedUser.projectId, interviewData.id);
    setCurrentResponse(response);
    if (response > 0) {
      setFileId(0);
      getAudioFiles(defaultPaggingSettings);
    }
    setOpenNotification(true);
  };

  const addComment = (object) => {
    setFileId(object.id);
    setShowDialog(true);
  };
  const getAudioFiles = async (pagggingSettings) => {
    setAudioFiles(null);
    const response = await interviewFileService.getPaged(interviewData.id, pagggingSettings);
    setAudioFileData(response.data);
    response.data.forEach((item) => {
      item.audioUrl = URL.createObjectURL(helperService.b64toBlob(item.fileArray, item.mimeType));
      item.attachedFileUrl = item.attachedFile !== null
        ? URL.createObjectURL(helperService.b64toBlob(item.attachedFile.fileArray, item.attachedFile.mimeType)) : '';
    });

    if (storedUser.isSuperAdmin === 0) {
      const menuOptions = audioDataConfig.columns[audioDataConfig.columns.findIndex((item) => item.type === TableCellTypes.Menu)].options;
      if (interviewData.researcherId !== storedUser.id) {
        audioDataConfig.columns[audioDataConfig.columns.findIndex((item) => item.type === TableCellTypes.Menu)].options = menuOptions.filter((item) => item.id === 1); // show download btn only
      }
    }

    setAudioFileConfigState(audioDataConfig);
    setAudioFiles(response);
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getAudioFiles(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getAudioFiles(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getAudioFiles(defaultPaggingSettings);
  };

  const downloadAttachedFile = (object) => {
    const a = document.createElement('a');
    a.setAttribute('download', object.attachedFile.fileName);
    a.setAttribute('href', object.attachedFileUrl);
    a.click();
    URL.revokeObjectURL(object.attachedFileUrl);
  };

  const uploadTranscriptionFile = (obj) => {
    setFileId(obj.id);
    setOpenUploadFile(true);
  };

  const removeTranscriptionFile = (obj) => {
    setShowTranscriptionConfirmDialog(true);
    setAttachedFileId(obj.attachedFile.id);
  };

  const confirmRemoveTranscriptionFile = async () => {
    setShowTranscriptionConfirmDialog(false);
    const response = await interviewFileService.remove(attachedFileId, storedUser.projectId, interviewData.id);
    setCurrentResponse(response);
    if (response > 0) {
      setFileId(0);
      getAudioFiles(defaultPaggingSettings);
    }
    setOpenNotification(true);
  };

  const audioDataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    headerButtons: [],
    limit: 2,
    isFullMode: true,
    isViewAllButton: false,
    tableTitle: 'TABLE_LABELS.audioFiles',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.audioFile',
        propertyName: 'audioUrl',
        type: TableCellTypes.AudioFile
      },
      {
        label: 'TABLE.comment',
        propertyName: 'comment',
        type: TableCellTypes.LongText
      },
      {
        label: 'TABLE.transcriptionFile',
        propertyName: 'attachedFileId',
        type: TableCellTypes.IconButton,
        iconElement: <GetAppIcon />,
        buttonColor: 'primary',
        function: downloadAttachedFile,
        renderCondition: [{ propertyName: 'attachedFile', conditionOperator: 'notEquals', conditionCompareValue: null }]
      },
      {
        label: 'TABLE.recordDate',
        propertyName: 'recordDate',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.actions',
        type: TableCellTypes.Menu,
        options: [
          {
            id: 1,
            text: t('SINGLE_PAGE.downloadFile'),
            visible: true,
            function: downloadAudioFile
          },
          {
            id: 2,
            text: t('SINGLE_PAGE.editComment'),
            visible: storedUser.isSuperAdmin === 1
              || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
              || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN
              || (storedUser.systemRoleId === RoleEnum.RESEARCHER && interviewData.researcherId === storedUser.id),
            function: addComment
          },
          {
            id: 3,
            text: t('SINGLE_PAGE.removeInterview'),
            visible: storedUser.isSuperAdmin === 1
              || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
              || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN
              || (storedUser.systemRoleId === RoleEnum.RESEARCHER && interviewData.researcherId === storedUser.id),
            textColor: 'red',
            function: removeAudioFile
          },
          {
            id: 4,
            text: t('SINGLE_PAGE.uploadTranscription'),
            visible: storedUser.isSuperAdmin === 1
              || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
              || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN
              || (storedUser.systemRoleId === RoleEnum.RESEARCHER && interviewData.researcherId === storedUser.id),
            function: uploadTranscriptionFile,
            renderCondition: [{ propertyName: 'attachedFile', conditionOperator: 'equals', conditionCompareValue: null }]
          },
          {
            id: 5,
            text: t('SINGLE_PAGE.removeTranscription'),
            visible: storedUser.isSuperAdmin === 1
              || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
              || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN
              || (storedUser.systemRoleId === RoleEnum.RESEARCHER && interviewData.researcherId === storedUser.id),
            textColor: 'red',
            function: removeTranscriptionFile,
            renderCondition: [{ propertyName: 'attachedFile', conditionOperator: 'notEquals', conditionCompareValue: null }]
          },
        ]
      }
    ]
  };

  useEffect(() => {
    if (interviewData.id !== undefined) {
      if (interviewData.isPublished === 1) {
        const index = audioDataConfig.columns.findIndex((item) => item.type === TableCellTypes.Menu);
        audioDataConfig.columns.splice(index, 1);
      }
      getAudioFiles(defaultPaggingSettings);
    }
  }, [interviewData]);

  return (
    <>
      <UploadFile open={openUploadFile} cancel={cancelUploadFile} send={sendUploadFile} allowedTypes={['text/plain', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']} />
      <AddNewComment open={showDialog} cancel={cancelDialog} send={sendDialog} commentProp={fileId === 0 ? '' : audioFilesData.find((item) => item.id === fileId)?.comment} />
      <ConfirmRemovingAudioFile open={showConfirmDialog} cancel={cancelConfirming} confirm={confirmRemoveAudioFile} />
      <ConfirmRemovingTranscriptionFile open={showTranscriptionConfirmDialog} cancel={cancelTranscriptionConfirming} confirm={confirmRemoveTranscriptionFile} />
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.audioFileWasRemoved')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <Card {...props}>
        <CardContent>
          <CustomTable dataSource={audioFiles} dataConfig={audioFileConfigState} />
        </CardContent>
      </Card>
    </>
  );
};

export default InterviewProfileAudio;

import { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ScreenSizeEnum from '../enums/ScreenSizeEnum';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function CustomTransferList(props) {
  const {
    sourceItems, selectedItems, passSelectedItems, keyField, valueField, moveAllEnabled, reverseLgMoreDirection
  } = props;
  CustomTransferList.propTypes = {
    sourceItems: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired,
    passSelectedItems: PropTypes.func,
    keyField: PropTypes.string.isRequired,
    valueField: PropTypes.string.isRequired,
    moveAllEnabled: PropTypes.bool.isRequired,
    reverseLgMoreDirection: PropTypes.bool.isRequired
  };
  const theme = useTheme();
  const moreLg = useMediaQuery(theme.breakpoints.up('lg'));
  const betweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const betweenMdCustomMd = useMediaQuery(theme.breakpoints.between('mdCustom', 'md'));
  const betweenSmMdCustom = useMediaQuery(theme.breakpoints.between('sm', 'mdCustom'));
  const betweenXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const [currentSizeObject, setCurrentSizeObject] = useState({
    width: 0,
    paperDirection: 'row',
    buttonDirection: 'row',
    isMobileButtonMode: false,
  });

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const componentSizing = [
    {
      size: ScreenSizeEnum.MORE_LG,
      width: 550,
      paperDirection: reverseLgMoreDirection ? 'column' : 'row',
      buttonDirection: reverseLgMoreDirection ? 'row' : 'column',
      isMobileButtonMode: reverseLgMoreDirection
    },
    {
      size: ScreenSizeEnum.BETWEEN_MD_LG,
      width: 820,
      paperDirection: 'column',
      buttonDirection: 'row',
      isMobileButtonMode: true
    },
    {
      size: ScreenSizeEnum.BETWEEN_MD_CUSTOM_MD,
      width: 650,
      paperDirection: 'column',
      buttonDirection: 'row',
      isMobileButtonMode: true
    },
    {
      size: ScreenSizeEnum.BETWEEN_SM_MD_CUSTOM,
      width: 500,
      paperDirection: 'column',
      buttonDirection: 'row',
      isMobileButtonMode: true
    },
    {
      size: ScreenSizeEnum.BETWEEN_XS_SM,
      width: 310,
      paperDirection: 'column',
      buttonDirection: 'row',
      isMobileButtonMode: true
    }
  ];

  const setComponentsSettings = () => {
    if (moreLg) {
      setCurrentSizeObject(componentSizing[componentSizing.findIndex((item) => item.size === ScreenSizeEnum.MORE_LG)]);
    }
    if (betweenMdLg) {
      setCurrentSizeObject(componentSizing[componentSizing.findIndex((item) => item.size === ScreenSizeEnum.BETWEEN_MD_LG)]);
    }
    if (betweenMdCustomMd) {
      setCurrentSizeObject(componentSizing[componentSizing.findIndex((item) => item.size === ScreenSizeEnum.BETWEEN_MD_CUSTOM_MD)]);
    }
    if (betweenSmMdCustom) {
      setCurrentSizeObject(componentSizing[componentSizing.findIndex((item) => item.size === ScreenSizeEnum.BETWEEN_SM_MD_CUSTOM)]);
    }
    if (betweenXsSm) {
      setCurrentSizeObject(componentSizing[componentSizing.findIndex((item) => item.size === ScreenSizeEnum.BETWEEN_XS_SM)]);
    }
  };

  const useStyles = makeStyles((themeObj) => ({
    root: {
      display: 'flex',
      justifyContent: 'center'
    },
    paper: {
      width: currentSizeObject.width,
      'min-height': 100,
      overflow: 'auto'
    },
    button: {
      margin: themeObj.spacing(0, 0),
    },
  }));
  const classes = useStyles();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    passSelectedItems(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    passSelectedItems(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    passSelectedItems(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    passSelectedItems([]);
    setRight([]);
  };

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-item-${item}-label`;
          return (
            <ListItem key={item[keyField]} role="listitem" button onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item[valueField]} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  useEffect(() => {
    setLeft(sourceItems);
    setRight(selectedItems);
  }, [sourceItems]);

  const sizeArray = [moreLg, betweenMdLg, betweenMdCustomMd, betweenSmMdCustom, betweenXsSm];

  useEffect(() => {
    if (sizeArray.some((item) => item)) {
      setComponentsSettings();
    }
  }, [moreLg, betweenMdLg, betweenMdCustomMd, betweenSmMdCustom, betweenXsSm]);

  return (
    <Grid container justify="center" alignItems="center" className={classes.root} sx={{ display: 'flex', flexDirection: currentSizeObject.paperDirection }}>
      <Grid item>{customList(left)}</Grid>
      <Grid item sx={{ m: 0.2 }}>
        <Grid container direction={currentSizeObject.buttonDirection} alignItems="center">
          {moveAllEnabled
            ? (
              <Button
                sx={{ mb: currentSizeObject.isMobileButtonMode ? 0 : 0.3, mr: currentSizeObject.isMobileButtonMode ? 0.1 : 0 }}
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                {currentSizeObject.isMobileButtonMode
                  ? <ExpandMoreIcon />
                  : <ArrowForwardIosIcon />}
              </Button>
            ) : ''}
          <Button
            sx={{ mb: currentSizeObject.isMobileButtonMode ? 0 : 0.3, mr: currentSizeObject.isMobileButtonMode ? 0.1 : 0 }}
            size="small"
            variant="outlined"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            {currentSizeObject.isMobileButtonMode
              ? <ArrowDownwardIcon />
              : <ArrowForwardIcon />}
          </Button>
          <Button
            sx={{ mb: currentSizeObject.isMobileButtonMode ? 0 : 0.3, mr: currentSizeObject.isMobileButtonMode ? 0.1 : 0 }}
            size="small"
            variant="outlined"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            {currentSizeObject.isMobileButtonMode
              ? <ArrowUpwardIcon />
              : <ArrowBackIcon />}
          </Button>
          {moveAllEnabled
            ? (
              <Button
                sx={{ mb: currentSizeObject.isMobileButtonMode ? 0 : 0.3, mr: currentSizeObject.isMobileButtonMode ? 0.1 : 0 }}
                size="small"
                variant="outlined"
                className={classes.button}
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                {currentSizeObject.isMobileButtonMode
                  ? <ExpandLessIcon />
                  : <ArrowBackIosIcon />}
              </Button>
            ) : ''}
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}

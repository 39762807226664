const InterviewActionItems = Object.freeze({
  CONTINUE: 1,
  COMPLEMENT: 2,
  ADD_AUDIO_RECORD: 3,
  EDIT_COMMENT: 4,
  REMOVE: 5,
  UPLOAD_FROM_DEVICE: 6,
  HIDE_INTERVIEW: 7,
  UNHIDE_INTERVIEW: 8,
  UPDATE_KEYWORDS: 9,
  PUBLISH_INTERVIEW: 10,
  UNPUBLISH_INTERVIEW: 11
});

export default (InterviewActionItems);

import SearchIcon from '@material-ui/icons/Search';
import { Box, Typography } from '@material-ui/core';

export default function CustomNoResultTemplate() {
  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', flexDirection: 'column', p: 3
    }}
    >
      <Box sx={{ 'text-align': 'center', m: 3 }}>
        <SearchIcon style={{ fontSize: 60 }} />
      </Box>
      <Box sx={{ 'text-align': 'center' }}>
        <Typography variant="h4" display="block" gutterBottom>
          No results found
        </Typography>
      </Box>
    </Box>
  );
}

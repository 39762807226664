import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  useParams,
  useNavigate,
  Prompt,
  useLocation
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import interviewService from '../services/interviewService';
import CustomBackdrop from '../components/CustomBackdrop';
import InterviewProfile from '../components/interview/InterviewProfile';
import InterviewProfileAudio from '../components/interview/InterviewProfileAudio';
import InterviewProfileMethodics from '../components/interview/InterviewProfileMethodics';
import BackButton from '../components/BackButton';
import AudioRecorderActions from '../enums/AudioRecordActions';

const SingleInterview = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const { id } = useParams();
  const navigate = useNavigate();
  const [interviewData, setInterviewData] = useState({});
  const [promptActive, setPromptActive] = useState(true);
  const [interviewMethodics, setInterviewMethodics] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getInterview = async () => {
    setLoading(true);
    setInterviewData('');
    const response = await interviewService.getSingle(id, storedUser.projectId);
    if (response !== null && response !== '') {
      setInterviewData(response);
      setInterviewMethodics(<InterviewProfileMethodics interviewObject={JSON.parse(response.interviewObject)} />);
      setLoading(false);
    } else {
      navigate('../../not-results', { replace: true });
    }
  };

  const disablePrompt = () => {
    setPromptActive(false);
  };
  useEffect(() => {
    if (storedUser.publishMode) {
      disablePrompt();
    }
    getInterview();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`QQDC | ${t('BROWSER_TAB_TITLES.interviewNumber')}`}
          {id}
        </title>
      </Helmet>
      { }
      <BackButton route={location?.state?.backRoute ? `../../../${location.state.backRoute}` : `../../single-project/${storedUser.projectId}`} />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <Prompt when={promptActive} message={t('NOTIFICATIONS.postInterviewRecordWarning')} />
      <Box
        sx={{
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="false">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <InterviewProfile interviewData={interviewData} removeAction={disablePrompt} updateParent={getInterview} isRecordingProp={storedUser.redirectAfterTesting !== undefined ? AudioRecorderActions.START_RECORD : AudioRecorderActions.NONE} />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              mt={2}
            >
              {interviewMethodics}
            </Grid>
            {!storedUser.publishMode
              ? (
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <InterviewProfileAudio interviewData={interviewData} />
                </Grid>
              ) : ''}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SingleInterview;

import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import searchService from '../../services/searchService';
import SearchResultSet from '../../dialogs/SearchResultSet';

const SearchCard = () => {
  const [t] = useTranslation('common');
  const [searchCredentials, setSearchCredentials] = useState({
    searchString: '',
    searchFilter: 0
  });
  const [resultSet, setResultSet] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [searchFilters, setSearchFilters] = useState([]);

  const handleChange = (event) => {
    setSearchCredentials({
      ...searchCredentials,
      [event.target.name]: event.target.value
    });
  };

  const search = async () => {
    const response = await searchService.search(searchCredentials);
    if (response !== null) {
      response.searchFilter = searchCredentials.searchFilter;
      setResultSet(response);
      setShowDialog(true);
    }
  };

  const cancel = () => {
    setShowDialog(false);
  };

  const validate = () => (searchCredentials.searchString === '' || searchCredentials.searchFilter === 0);

  const searchEntities = [
    {
      id: 1,
      name: t('TABLE_LABELS.respondents')
    },
    {
      id: 2,
      name: t('TABLE_LABELS.researchers')
    }
  ];

  useEffect(() => {
    setSearchFilters(searchEntities);
  }, []);

  return (
    <>
      <SearchResultSet open={showDialog} cancel={cancel} resultSet={resultSet} targetPlaceToFind={searchCredentials.searchFilter} />
      <Card sx={{ m: 2 }}>
        <CardHeader title={t('SINGLE_PAGE.searchCard')} />
        <Divider />
        <CardContent sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <TextField
            sx={{ mr: 1, width: '50%' }}
            id="searchString"
            label={t('FORMS.searchString')}
            name="searchString"
            type="text"
            size="small"
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormControl variant="outlined" style={{ minWidth: '30%' }}>
            <InputLabel>{t('FORMS.searchFilter')}</InputLabel>
            <Select
              size="small"
              name="searchFilter"
              onChange={handleChange}
              onBlur={handleChange}
              label={t('FORMS.searchFilter')}
              fullWidth
            >
              {searchFilters.map((searchFilter) => (<MenuItem key={searchFilter.id} value={searchFilter.id}>{searchFilter.name}</MenuItem>))}
            </Select>
          </FormControl>
          <Button onClick={search} sx={{ ml: 2 }} variant="contained" disabled={validate()}>{t('BUTTONS.search')}</Button>
        </CardContent>
      </Card>
    </>
  );
};

export default SearchCard;

import axios from 'axios';

const HTTP = axios.create({
  withCredentials: false,
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5000/' : 'https://server91.sociotest.solutions/'
});

HTTP.interceptors.request.use((res) => {
  res.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return res;
});

const get = (route, params) => HTTP.get(route, { params }).then((res) => res.data);

const post = (route, params, headers) => {
  if (headers != null) {
    return HTTP.post(route, params, headers).then((res) => res.data).catch((err) => err.response);
  }
  return HTTP.post(route, params).then((res) => res.data).catch((err) => err.response);
};

export default {
  get,
  post
};

import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import Settings from 'src/pages/Settings';
import SingleResearcher from 'src/pages/SingleResearcher';
import SingleRespondent from 'src/pages/SingleRespondent';
import CreateInterview from 'src/pages/CreateInterview';
import NotResults from 'src/pages/NotResults';
import SingleMethodic from 'src/pages/SingleMethodic';
import SingleProject from 'src/pages/SingleProject';
import InterviewEntirePage from 'src/pages/InterviewEntirePage';
import FinishInterview from './pages/FinishInterview';
import SingleInterview from './pages/SingleInterview';

const routes = (isLoggedIn, publishMode) => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'home', element: <Dashboard />, publish: false },
      { path: 'settings', element: <Settings />, publish: false },
      { path: 'single-researcher/:id', element: <SingleResearcher />, publish: false },
      { path: 'single-respondent/:id', element: <SingleRespondent />, publish: false },
      { path: 'create-interview', element: <CreateInterview />, publish: false },
      { path: 'not-results', element: <NotResults />, publish: false },
      { path: 'single-methodic/:id', element: <SingleMethodic />, publish: false },
      { path: 'single-project/:id', element: <SingleProject />, publish: true },
      { path: 'interview/:id', element: <InterviewEntirePage />, publish: false },
      { path: 'finish-interview', element: <FinishInterview />, publish: false },
      { path: 'single-interview/:id', element: <SingleInterview />, publish: true },
    ]
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to={publishMode.enabled ? `app/single-project/${publishMode.projectId}` : '/app/home'} />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <Navigate to={publishMode.enabled ? `app/single-project/${publishMode.projectId}` : '/app/home'} /> },
      { path: '*', element: <Navigate to={publishMode.enabled ? `app/single-project/${publishMode.projectId}` : '/app/home'} /> }
    ]
  }
];

export default routes;

import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AttachKeyword from 'src/dialogs/AttachKeyword';
import helperService from 'src/services/helperService';
import KeywordList from 'src/dialogs/KeywordList';
import interviewService from '../services/interviewService';
import CustomTable from '../components/CustomTable';
import TableCellTypes from '../enums/TableCellTypes';

const InterviewsPublish = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [interviewsResponse, setInterviewsResponse] = useState(null);
  const [showAttachKeywordDialog, setShowAttachKeywordDialog] = useState(false);
  const [currentKeywords, setCurrentKeywords] = useState([]);
  const [showKeywordDialog, setShowKeywordDialog] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const lessThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const redirectToSingleInterview = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../single-interview/${object.id}`, '_blank');
      return;
    }
    navigate(`../../single-interview/${object.id}`, { replace: true });
  };
  const cancelAttachKeywordDialog = () => {
    setShowAttachKeywordDialog(false);
  };
  const sendAttachKeyword = async (array) => {
    const keyWordPaggingSettings = {
      CurrentPage: 1,
      Search: '',
      RowsPerPage: 100,
      keyWordIds: array.length === 0 ? [] : array.map((i) => i.id)
    };
    setShowAttachKeywordDialog(false);
    await getPublishedInterviews(keyWordPaggingSettings);
  };
  const downloadAttachedFile = (object) => {
    const interviewTranscriptionFile = object.interviewTranscription;
    const fileUrl = URL.createObjectURL(helperService.b64toBlob(interviewTranscriptionFile.fileArray, interviewTranscriptionFile.mimeType));
    const a = document.createElement('a');
    a.setAttribute('download', interviewTranscriptionFile.fileName);
    a.setAttribute('href', fileUrl);
    a.click();
    URL.revokeObjectURL(fileUrl);
  };
  const showKeywords = (object) => {
    setCurrentKeywords(object.interviewKeywords);
    setShowKeywordDialog(true);
  };
  const closeKeywordDialog = () => {
    setShowKeywordDialog(false);
    setCurrentKeywords([]);
  };
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getPublishedInterviews(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getPublishedInterviews(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getPublishedInterviews(defaultPaggingSettings);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: 'TABLE_LABELS.interviews',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToSingleInterview
      },
      {
        label: 'TABLE.pseudonym',
        propertyName: 'respondentPseudonym',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.age',
        propertyName: 'respondentAge',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.age',
        propertyName: 'age',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.gender',
        propertyName: 'respondentGender',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.familyState',
        propertyName: 'respondentFamilyState',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.educationState',
        propertyName: 'respondentEducationState',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.employmentState',
        propertyName: 'respondentEmploymentState',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.keywords',
        propertyName: 'interviewKeywords',
        type: TableCellTypes.IconButton,
        iconElement: <VisibilityIcon />,
        buttonColor: 'primary',
        function: showKeywords,
        renderCondition: [{ propertyName: 'interviewKeywords', conditionOperator: 'notEquals', conditionCompareValue: null }]
      },
      {
        label: 'TABLE.transcriptionFile',
        propertyName: 'interviewTranscription',
        type: TableCellTypes.IconButton,
        iconElement: <GetAppIcon />,
        buttonColor: 'primary',
        function: downloadAttachedFile,
        renderCondition: [{ propertyName: 'interviewTranscription', conditionOperator: 'notEquals', conditionCompareValue: null }]
      }
    ]
  };
  const getPublishedInterviews = async (paggingSettings) => {
    setInterviewsResponse(null);
    const response = await interviewService.getPagedPublished(storedUser.projectId, paggingSettings);
    setInterviewsResponse(response);
  };

  const openFilterByKeyWordDialog = () => {
    setShowAttachKeywordDialog(true);
  };

  useEffect(() => {
    getPublishedInterviews(defaultPaggingSettings);
  }, []);

  return (
    <>
      <KeywordList open={showKeywordDialog} cancel={closeKeywordDialog} keywords={currentKeywords} />
      <AttachKeyword open={showAttachKeywordDialog} cancel={cancelAttachKeywordDialog} send={sendAttachKeyword} attachedKeywords={[]} filterMode="true" />
      <Box sx={{ m: 2, display: 'flex', flexDirection: lessThanLg ? 'column' : 'row' }}>
        <Button
          sx={{ ml: lessThanLg ? 0 : 1, mt: lessThanLg ? 1 : 0 }}
          variant="contained"
          color="primary"
          startIcon={<LocalOfferIcon />}
          onClick={openFilterByKeyWordDialog}
        >
          {t('BUTTONS.filterByKeywords')}
        </Button>
      </Box>
      <CustomTable dataSource={interviewsResponse} dataConfig={dataConfig} />
    </>
  );
};

export default InterviewsPublish;

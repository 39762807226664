import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RoleEnum from 'src/enums/RoleEnum';
import CustomBackdrop from 'src/components/CustomBackdrop';
import keywordService from '../services/keyWordService';
import CustomTable from '../components/CustomTable';
import TableCellTypes from '../enums/TableCellTypes';
import AddNewKeyword from '../dialogs/AddNewKeyword';
import ConfirmRemovingKeyword from '../dialogs/ConfirmRemovingKeyword';

const Keywords = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [keywordResponse, setKeywordssResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentKeyword, setCurrentKeyword] = useState({});
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getKeywords(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getKeywords(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getKeywords(defaultPaggingSettings);
  };
  const openEditForm = (keyword) => {
    setCurrentKeyword(keyword);
    setShowDialog(true);
    setLoading(false); // remove
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const cancelConfirming = () => {
    setShowConfirmDialog(false);
  };
  const confirmRemoveKeyword = async () => {
    setShowConfirmDialog(false);
    setLoading(true);
    const response = await keywordService.remove(currentKeyword.id);
    if (response > 0) {
      getKeywords(defaultPaggingSettings);
    }
    setLoading(false);
  };
  const sendDialog = async (keyword) => {
    const keywordInput = {
      name: keyword.name,
      projectId: storedUser.projectId
    };
    let response = 0;
    setLoading(true);
    if (keyword.id !== 0) {
      response = await keywordService.update(keyword.id, keywordInput);
    } else {
      response = await keywordService.create(keywordInput);
    }
    if (response > 0) {
      setLoading(false);
      setShowDialog(false);
      getKeywords(defaultPaggingSettings);
    }
  };
  const openRemoveConfirmation = (keyword) => {
    setCurrentKeyword(keyword);
    setShowConfirmDialog(true);
  };
  const openAddNewKeywordDialog = () => {
    setCurrentKeyword({});
    setShowDialog(true);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: 'TABLE_LABELS.keywords',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.name',
        propertyName: 'name',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.actions',
        type: TableCellTypes.Menu,
        options: [
          {
            id: 1,
            text: t('SINGLE_PAGE.edit'),
            visible: storedUser.isSuperAdmin === 1
              || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
              || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN,
            function: openEditForm
          },
          {
            id: 2,
            text: t('SINGLE_PAGE.removeInterview'),
            visible: storedUser.isSuperAdmin === 1
              || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
              || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN,
            textColor: 'red',
            function: openRemoveConfirmation
          }
        ]
      }
    ]
  };
  const getKeywords = async (paggingSettings) => {
    setKeywordssResponse(null);
    const response = await keywordService.getPaged(storedUser.projectId, paggingSettings);
    setKeywordssResponse(response);
  };

  const filterDataConfig = () => {
    if (storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN) {
      return dataConfig;
    }
    const copyOptions = _.cloneDeep(dataConfig);
    copyOptions.columns = copyOptions.columns.filter((i) => i.type !== TableCellTypes.Menu);
    return copyOptions;
  };

  useEffect(() => {
    getKeywords(defaultPaggingSettings);
  }, []);

  return (
    <>
      {storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN
        ? (
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openAddNewKeywordDialog}
          >
            {t('BUTTONS.addNewKeyword')}
          </Button>
        ) : ''}
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <ConfirmRemovingKeyword open={showConfirmDialog} cancel={cancelConfirming} confirm={confirmRemoveKeyword} />
      <AddNewKeyword open={showDialog} cancel={cancelDialog} send={sendDialog} keyWordData={currentKeyword} />
      <CustomTable dataSource={keywordResponse} dataConfig={filterDataConfig()} />
    </>
  );
};

export default Keywords;

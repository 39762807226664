import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CustomNoResultTemplate from 'src/components/CustomNoResultTemplate';
import SearchFilterEnum from '../enums/SearchFilterEnum';
import SearchCollapseList from '../components/dashboard/SearchCollapseList';

export default function SearchResultSet(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation('common');
  const {
    open, cancel, resultSet, targetPlaceToFind
  } = props;
  SearchResultSet.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    resultSet: PropTypes.array.isRequired,
    targetPlaceToFind: PropTypes.number.isRequired
  };

  const routes = [
    {
      id: SearchFilterEnum.RESPONDENTS,
      route: '../single-respondent/'
    },
    {
      id: SearchFilterEnum.RESEARCHERS,
      route: '../single-researcher/'
    }
  ];

  const navigateToSinglePage = (researcherId, projectId) => {
    dispatch({ type: 'updateUser', user: { projectId } });
    const baseRoute = routes.find((i) => i.id === targetPlaceToFind).route;
    navigate(`${baseRoute}${researcherId}`);
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {t('DIALOGS.searchResult')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {resultSet.length !== 0 ? (
          <>
            {resultSet.map((item) => (
              <SearchCollapseList singleResearcherResult={item} navigate={(r, p) => navigateToSinglePage(r, p)} />
            ))}
          </>
        ) : (
          <CustomNoResultTemplate />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          {t('BUTTONS.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

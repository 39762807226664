import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';

const App = () => {
  const isLoggedIn = localStorage.getItem('token');
  let publishMode = {
    enabled: false,
    projectId: 0
  };
  if (isLoggedIn !== null) {
    const jwtParsed = JSON.parse(Buffer.from(isLoggedIn.split('.')[1], 'base64').toString('binary'));
    publishMode = {
      enabled: jwtParsed !== null ? jwtParsed.role === 'AccessByCode' : false,
      projectId: jwtParsed !== null ? +jwtParsed.unique_name : 0
    };
  }
  const defaultRoutes = routes(isLoggedIn, publishMode);
  const appRoutes = routes(isLoggedIn, publishMode)[0].children;
  defaultRoutes[0].children = publishMode.enabled
    ? appRoutes.filter((i) => i.publish)
    : appRoutes;
  const routing = useRoutes(defaultRoutes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;

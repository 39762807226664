import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function AddNewKeyWord(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, keyWordData
  } = props;
  const [keyWordObjectData, setKeyWordObjectData] = useState({
    name: null,
    projectId: null
  });

  const initialValue = {
    name: null,
    projectId: null
  };
  AddNewKeyWord.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    keyWordData: PropTypes.object
  };

  const handleChange = (event) => {
    setKeyWordObjectData({
      ...keyWordObjectData,
      [event.target.name]: event.target.value,
      id: Object.keys(keyWordData).length === 0 ? 0 : keyWordData.id
    });
  };

  const validateEditForm = () => {
    if (Object.keys(keyWordData).length !== 0) {
      return keyWordObjectData.name !== null && keyWordObjectData.name !== '';
    }
  };

  const validate = (object) => (validateEditForm() || (object.name !== '' && object.name !== null));

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {Object.keys(keyWordData).length === 0
            ? t('FORMS.addNewKeyword')
            : t('FORMS.editKeyword')}
        </DialogTitle>
        <DialogContent>
          <TextField
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="name"
            label={t('FORMS.name')}
            name="name"
            type="text"
            fullWidth
            size="medium"
            defaultValue={keyWordData === undefined ? '' : keyWordData.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { cancel(); setKeyWordObjectData(initialValue); }} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(keyWordObjectData); setKeyWordObjectData(initialValue); }} color="primary" disabled={!validate(keyWordObjectData)}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

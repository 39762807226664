import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  Typography,
  IconButton
} from '@material-ui/core';
import researcherService from '../services/researcherService';
import Logo from './Logo';
import loginService from '../services/loginService';
import SelectCountryList from './SelectCountryList';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const storedUser = useSelector((state) => state.user);
  const [navbarButtons, setNavbarButtons] = useState([]);
  const [currentHomePath, setCurrentHomePath] = useState('app/home');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const removeUser = () => {
    dispatch({ type: 'removeUser' });
  };
  const logout = () => {
    loginService.logout();
    navigate('/login', { replace: true });
    removeUser();
  };
  const redirectToSettings = () => {
    navigate('/app/settings', { replace: true });
  };
  const redirectToHome = () => {
    const homePath = storedUser.publishMode ? `app/single-project/${storedUser.projectId}` : 'app/home';
    setCurrentHomePath(homePath);
    navigate(homePath, { replace: true });
  };
  const navbarButtonsDefault = [
    {
      id: 1,
      icon: <HomeIcon fontSize="small" onClick={redirectToHome} />
    },
    {
      id: 2,
      icon: <SettingsIcon fontSize="small" onClick={redirectToSettings} />
    },
    {
      id: 3,
      icon: <ExitToAppIcon fontSize="small" onClick={logout} />
    }
  ];
  const updateUser = (userObject) => {
    dispatch({ type: 'updateUser', user: userObject });
  };
  const filterNavbarButtons = () => {
    const filteredBtnList = navbarButtonsDefault.filter((i) => i.id !== 2);
    setNavbarButtons(filteredBtnList);
  };
  const getCurrentResearcher = async () => {
    if (!storedUser.publishMode) {
      setNavbarButtons(navbarButtonsDefault);
      const response = await researcherService.getSimplifyById(storedUser.id);
      if (response !== null) {
        updateUser({
          isSuperAdmin: response.isSuperAdmin,
          isAllowToCreateProject: response.isAllowToCreateProject,
          name: `${response.name} ${response.surname}`
        });
      }
    } else {
      filterNavbarButtons();
      updateUser({
        isSuperAdmin: 0,
        isAllowToCreateProject: false,
        name: ''
      });
    }
  };

  useEffect(() => {
    if (Object.keys(storedUser).length !== 0) {
      getCurrentResearcher();
    }
  }, [Object.keys(storedUser).length !== 0]);
  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Hidden smDown>
          <RouterLink to={currentHomePath}>
            <Logo />
          </RouterLink>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mt: 3, mr: 1 }}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {`${storedUser.name}`}
            </Typography>
          </Box>
          <SelectCountryList currentLanguage={storedUser.language} />
          <Hidden>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {navbarButtons.map((item) => (
                <IconButton>
                  {item.icon}
                </IconButton>
              ))}
            </Box>
          </Hidden>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;

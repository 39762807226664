import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import projectService from 'src/services/projectService';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddNewProject from 'src/dialogs/AddNewProject';
import CustomBackdrop from '../CustomBackdrop';
import CustomNotification from '../CustomNotification';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';

const Projects = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});
  const [customProjectTable, setCurrentProjectTable] = useState('');
  const [openNotification, setOpenNotification] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectToFullProject = (object, isRightClick = false) => {
    if (isRightClick) {
      window.open(`../app/single-project/${object.id}`, '_blank');
      return;
    }
    dispatch({ type: 'updateUser', user: { projectId: object.id, projectStatusId: object.statusId } });
    navigate(`../single-project/${object.id}`, { replace: true });
  };
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const openAddNewProjectDialog = () => {
    setShowDialog(true);
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const sendDialog = async (object) => {
    setLoading(true);
    const response = await projectService.save(object);
    setCurrentResponse(response);
    if (response > 0) {
      await getProjects(defaultPaggingSettings);
      setOpenNotification(true);
      setShowDialog(false);
    }
    setLoading(false);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getProjects(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getProjects(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getProjects(defaultPaggingSettings);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.more',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: redirectToFullProject
      },
      {
        label: 'TABLE.systemRole',
        propertyName: 'userSystemRoleName',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.name',
        propertyName: 'name',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.status',
        propertyName: 'statusName',
        propertyValue: 'statusId',
        type: TableCellTypes.Chip
      },
      {
        label: 'TABLE.startDate',
        propertyName: 'startDate',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.finishDate',
        propertyName: 'finishDate',
        type: TableCellTypes.DateTime
      },
      {
        label: 'TABLE.description',
        propertyName: 'description',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.researcherCount',
        propertyName: 'researcherCount',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.respondentCount',
        propertyName: 'respondentCount',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.interviewCount',
        propertyName: 'interviewCount',
        type: TableCellTypes.Text
      }
    ]
  };
  const getProjects = async (paggingSettings) => {
    if (storedUser.isAllowToCreateProject === 1) {
      dataConfig.headerButtons.push(
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openAddNewProjectDialog}
        >
          {t('BUTTONS.addNewProject')}
        </Button>
      );
    } else {
      dataConfig.tableTitle = 'TABLE_LABELS.Projects';
    }
    const response = await projectService.getPaged(paggingSettings);
    setCurrentProjectTable(<CustomTable dataSource={response} dataConfig={dataConfig} />);
  };

  useEffect(() => {
    if (storedUser.isAllowToCreateProject !== undefined) {
      getProjects(defaultPaggingSettings);
    }
  }, [storedUser.isAllowToCreateProject]);

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.projectCreated')}
        type="success"
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <AddNewProject open={showDialog} cancel={cancelDialog} send={sendDialog} projectData={{}} />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      {customProjectTable}
    </>
  );
};

export default Projects;

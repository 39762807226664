import { useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import {
  Card,
  CardContent,
  Box,
  Button,
  Divider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StatusEnum from 'src/enums/StatusEnum';
import EditAttachedMethodics from 'src/dialogs/EditAttachedMethodics';
import methodicService from 'src/services/methodicService';
import LanguageEnum from 'src/enums/LanguageEnum';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';
import CustomBackdrop from '../CustomBackdrop';
import CustomNotification from '../CustomNotification';
import RoleEnum from '../../enums/RoleEnum';

const ProjectAttachedMethodics = () => {
  const [t] = useTranslation('common');
  const [openNotification, setOpenNotification] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});
  const [notificationText, setNotificationText] = useState('');
  const [methodicTable, setMethodicTable] = useState('');
  const [methodicsProjects, setmethodicsProjects] = useState('');
  const storedUser = useSelector((state) => state.user);
  const showDialogFunc = () => {
    setShowDialog(true);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const sendDialog = async (object) => {
    setShowDialog(false);
    setLoading(true);
    const response = await methodicService.assignMethodicsForProject(storedUser.projectId, object);
    setCurrentResponse(response);
    setNotificationText(t('NOTIFICATIONS.methodicChanged'));
    setLoading(false);

    setOpenNotification(true);
    getMethodics();
  };

  const getMethodics = async () => {
    const methodicsForCurrentProject = await methodicService.getAllByProjectId(storedUser.projectId, LanguageEnum.Ukrainian);
    if (methodicsForCurrentProject !== null) {
      setmethodicsProjects(methodicsForCurrentProject);
      setMethodicTable(<CustomTable dataSource={{ data: methodicsForCurrentProject, count: methodicsForCurrentProject.length }} dataConfig={dataConfigAttachedMethodics} />);
    }
  };

  const dataConfigAttachedMethodics = {
    isFullMode: false,
    isViewAllButton: false,
    tableTitle: '',
    headerButtons: [],
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.methodicName',
        propertyName: 'name',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.methodicDescription',
        propertyName: 'description',
        type: TableCellTypes.Text
      }
    ]
  };

  useEffect(() => { getMethodics(); }, []);

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={notificationText}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <EditAttachedMethodics open={showDialog} cancel={cancelDialog} send={sendDialog} attachedMethodics={{ data: methodicsProjects }} />
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1
            }}
          >
            {(storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER) && storedUser.projectStatusId === StatusEnum.ACTIVE ? (
              <Button
                color="primary"
                startIcon={<EditIcon />}
                onClick={showDialogFunc}
              >
                {t('BUTTONS.editAttachedMethodics')}
              </Button>
            ) : ''}
          </Box>
          <Divider />
          {methodicTable}
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectAttachedMethodics;

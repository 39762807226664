import {
  Box,
  Typography,
  Card,
  Tab,
  CardContent,
  Container,
  Tabs,
  Alert
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MethodicEnum from 'src/enums/MethodicEnum';
import ValuesList from './ValuesList';
import PsychoDistressList from './PsychoDistressList';
import SatisfactionList from './SatisfactionList';
import WellBeingList from './WellBeingList';

const InterviewProfileMethodics = (props) => {
  const [t] = useTranslation('common');
  const [currentValue, setCurrentValue] = useState(0);
  const [tabsToRender, setTabsToRender] = useState([]);
  const { interviewObject } = props;
  InterviewProfileMethodics.propTypes = {
    interviewObject: PropTypes.array.isRequired
  };
  function TabPanel(propsPanel) {
    const {
      children, value, index, ...other
    } = propsPanel;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    setCurrentValue(newValue);
  };
  const renderDistressIcon = (type) => {
    if (interviewObject.filter((item) => item.MethodicId === type).length === 0 || interviewObject.filter((item) => item.MethodicId === type)[0].MetaValues.length === 0) {
      return '';
    }
    const level = interviewObject.filter((item) => item.MethodicId === type)[0].MetaValues[0].Level;
    switch (type) {
      case MethodicEnum.PSYHO_DISTRESS:
        switch (level) {
          default:
          case 1:
            return (<StarIcon />);
          case 2:
            return (<StarHalfIcon />);
          case 3:
            return (<StarBorderIcon />);
        }
      case MethodicEnum.SATISFACTION:
        switch (level) {
          default:
          case 1:
            return (<StarBorderIcon />);
          case 2:
            return (<StarHalfIcon />);
          case 3:
            return (<StarIcon />);
        }
      default:
        break;
    }
  };
  const getStabilityAbsoluteIcons = (value) => {
    switch (value) {
      case 1:
      case 2:
        return (<StarBorderIcon />);
      case 3:
      case 4:
        return (<StarHalfIcon />);
      case 5:
      case 6:
        return (<StarIcon />);
      default:
        break;
    }
  };
  const getRelativeIcon = (value) => {
    switch (value) {
      case 1:
        return (<StarBorderIcon />);
      case 2:
        return (<StarHalfIcon />);
      case 3:
      case 4:
      case 5:
        return (<StarIcon />);
      default:
        break;
    }
  };
  const renderWellBeingIcons = () => {
    if (interviewObject.filter((item) => item.MethodicId === MethodicEnum.WELL_BEING).length === 0 || interviewObject.filter((item) => item.MethodicId === MethodicEnum.WELL_BEING)[0].MetaValues.length === 0) {
      return '';
    }
    const metaValues = interviewObject.filter((item) => item.MethodicId === MethodicEnum.WELL_BEING)[0].MetaValues;
    return (
      <>
        <Box>
          {getRelativeIcon(metaValues[0].Value)}
        </Box>
        |
        <Box>
          {getStabilityAbsoluteIcons(metaValues[1].Value)}
        </Box>
        |
        <Box>
          {getStabilityAbsoluteIcons(metaValues[2].Value)}
        </Box>
      </>
    );
  };

  const renderMetaValuesIcons = () => {
    if (interviewObject.filter((item) => item.MethodicId === MethodicEnum.META_VALUES).length === 0 || interviewObject.filter((item) => item.MethodicId === MethodicEnum.META_VALUES)[0].Questions.length === 0) {
      return '';
    }
    const questions = interviewObject.filter((item) => item.MethodicId === MethodicEnum.META_VALUES)[0].Questions;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ mt: 0.3 }}>{questions.filter((i) => i.AnswerValue === 3).length}</Box>
        <Box>
          <StarIcon />
        </Box>
        <Box sx={{ mt: 0.3 }}>{questions.filter((i) => i.AnswerValue === 2).length}</Box>
        <Box>
          <StarHalfIcon />
        </Box>
        <Box sx={{ mt: 0.3 }}>{questions.filter((i) => i.AnswerValue === 1).length}</Box>
        <Box>
          <StarBorderIcon />
        </Box>
      </Box>
    );
  };

  const initialTabs = [
    {
      methodicId: MethodicEnum.META_VALUES,
      label: (
        <>
          {t('TABS.valuesMethodic')}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              {renderMetaValuesIcons()}
            </Box>
          </Box>
        </>),
      value: (<ValuesList methodicArray={interviewObject.filter((item) => item.MethodicId === MethodicEnum.META_VALUES)} />),
      disabled: false
    },
    {
      methodicId: MethodicEnum.PSYHO_DISTRESS,
      label: (
        <>
          {t('TABS.psychologocalDistressMethodic')}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              {renderDistressIcon(MethodicEnum.PSYHO_DISTRESS)}
            </Box>
          </Box>
        </>
      ),
      value: (<PsychoDistressList methodicArray={interviewObject.filter((item) => item.MethodicId === MethodicEnum.PSYHO_DISTRESS)} />),
      disabled: false
    },
    {
      methodicId: MethodicEnum.SATISFACTION,
      label: (
        <>
          {t('TABS.satisfactionMethodic')}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              {renderDistressIcon(MethodicEnum.SATISFACTION)}
            </Box>
          </Box>
        </>
      ),
      value: (<SatisfactionList methodicArray={interviewObject.filter((item) => item.MethodicId === MethodicEnum.SATISFACTION)} />),
      disabled: false
    },
    {
      methodicId: MethodicEnum.WELL_BEING,
      label: (
        <>
          {t('TABS.wellBeingMethodic')}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {renderWellBeingIcons()}
          </Box>
        </>
      ),
      value: (<WellBeingList methodicArray={interviewObject.filter((item) => item.MethodicId === MethodicEnum.WELL_BEING)} />),
      disabled: false
    },
  ];

  const checkMethodicIsFullAnswered = (type, methodicArray) => {
    switch (type) {
      case MethodicEnum.META_VALUES: {
        // check if questions are fully answered
        const questionNotAnswered = methodicArray[0].Questions.some((item) => item.AnswerValue === 0);
        if (!questionNotAnswered) {
          // check if all three ranges were touched
          for (let i = 0; i < methodicArray.slice(1, 4).length; i++) {
            const item = methodicArray.slice(1, 4)[i];
            if (!item.ComplexRanges.Touched) {
              return false;
            }
          }
          return true;
        }
        return false;
      }
      case MethodicEnum.PSYHO_DISTRESS:
      case MethodicEnum.SATISFACTION:
      case MethodicEnum.WELL_BEING:
        // check if questions are fully answered
        return !methodicArray[0].Questions.some((item) => item.AnswerValue === 0);
      default:
        break;
    }
  };

  useEffect(() => {
    const resultArray = [];
    let firstFinishedMethodicTabIndex = -1;
    // get only unique items by methodic id + sort methodics id in asc order
    const sortedUniqueArray = [...new Set(interviewObject.map((item) => item.MethodicId))].sort((a, b) => a - b);
    sortedUniqueArray
      .forEach((methodicId) => {
        // disable methodic tabs if it wasn't fully answered
        if (!checkMethodicIsFullAnswered(methodicId, interviewObject.filter((item) => item.MethodicId === methodicId))) {
          // if interview was interrupted with single methodic or meta values methodic was interrupted in the middle
          if (interviewObject.length === 1 || (methodicId === MethodicEnum.META_VALUES && interviewObject.length === 4)) {
            // disable focus from single tabs on current page
            setCurrentValue(-1);
          }
          initialTabs[initialTabs.findIndex((i) => i.methodicId === methodicId)].disabled = true;
        } else {
          // set index of first fully answered methodic's tab
          firstFinishedMethodicTabIndex = firstFinishedMethodicTabIndex === -1
            ? sortedUniqueArray.findIndex((i) => i === methodicId)
            : firstFinishedMethodicTabIndex;
          setCurrentValue(firstFinishedMethodicTabIndex);
        }
        resultArray.push(initialTabs[initialTabs.findIndex((i) => i.methodicId === methodicId)]);
      });
    setTabsToRender(resultArray);
  }, [interviewObject.length]);

  return (
    <>
      <Card>
        <CardContent>
          <Container maxWidth="false">
            <Box>
              {tabsToRender.length !== 0
                ? (
                  <>
                    <Tabs
                      value={currentValue}
                      indicatorColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="primary"
                      onChange={handleChange}
                    >
                      {tabsToRender.map((tab) => (
                        <Tab
                          wrapped
                          label={tab.label}
                          disabled={tab.disabled}
                        />
                      ))}
                    </Tabs>
                    {tabsToRender.map((tab, index) => (
                      <TabPanel value={currentValue} index={index}>
                        <Box sx={{ ml: -3 }}>
                          {tab.value}
                        </Box>
                      </TabPanel>
                    ))}
                  </>
                ) : (
                  <Alert severity="info" color="warning"><b>{t('SINGLE_PAGE.notMethodicsUsed')}</b></Alert>
                )}
            </Box>
          </Container>
        </CardContent>
      </Card>
    </>
  );
};

export default InterviewProfileMethodics;

import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function AddNewInterviewComment(props) {
  const [t] = useTranslation('common');
  const {
    open, cancel, send, commentProp
  } = props;
  const [comment, setComment] = useState('');
  AddNewInterviewComment.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    send: PropTypes.func.isRequired,
    commentProp: PropTypes.object
  };

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('FORMS.interviewComment')}
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rowsMax={15}
            onChange={handleChange}
            onBlur={handleChange}
            margin="dense"
            id="comment"
            label={t('FORMS.comment')}
            name="comment"
            type="text"
            fullWidth
            size="medium"
            defaultValue={commentProp === '' ? comment : commentProp}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { cancel(); setComment(''); }} color="primary">
            {t('BUTTONS.cancel')}
          </Button>
          <Button onClick={() => { send(comment); setComment(''); }} color="primary" disabled={comment === ''}>
            {t('BUTTONS.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

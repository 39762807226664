import httpService from './httpService';
import helperService from './helperService';

const getPaged = (paggingSettings) => httpService.post('/api/projects/getPaged', paggingSettings);

const getById = (projectId) => httpService.get(`/api/projects/getById/${projectId}`);

const save = (projectData) => httpService.post('/api/projects/create', helperService.filterObject(projectData));

const update = (id, projectData) => httpService.post(`/api/projects/update/${id}`, helperService.filterObject(projectData));

const getAllProjects = () => httpService.get('/api/projects/all');

const attachResearcherToProject = (object) => httpService.post('/api/projects/attach', object);

const updateAttachedProject = (object) => httpService.post('/api/projects/updateattach', object);

const removeAttachedProject = (object) => httpService.post('/api/projects/removeattach', object);

const remove = (id) => httpService.post(`/api/projects/remove/${id}`);

export default {
  getAllProjects, attachResearcherToProject, updateAttachedProject, removeAttachedProject, getPaged, save, update, getById, remove
};

import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Chip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AddResearcher from '../../dialogs/AddResearcher';
import CustomMenu from '../CustomMenu';
import CustomNotification from '../CustomNotification';
import CustomBackdrop from '../CustomBackdrop';
import researcherService from '../../services/researcherService';

const ResearcherProfile = (props) => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [currentResponse, setCurrentResponse] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const { userData, updateData } = props;
  ResearcherProfile.propTypes = {
    userData: PropTypes.object.isRequired,
    updateData: PropTypes.func
  };
  const openAddResearcherDialog = () => {
    setShowDialog(true);
  };

  const cancelDialog = () => {
    setShowDialog(false);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const sendDialog = async (userObject) => {
    setShowDialog(false);
    setLoading(true);
    userObject.projectId = storedUser.projectId;
    const response = await researcherService.update(userData.id, userObject);
    setCurrentResponse(response);
    if (response === 1) {
      updateData();
    }
    setLoading(false);
    setOpenNotification(true);
  };
  const menuOptions = [
    {
      id: 1,
      text: t('BUTTONS.edit'),
      visible: true,
      function: openAddResearcherDialog
    }
  ];
  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.researcherUpdated')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <AddResearcher open={showDialog} cancel={cancelDialog} send={sendDialog} userObjectProp={userData} />
      <Card {...props}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {t('SINGLE_PAGE.researcherNumber')}
              {userData.id}
            </Typography>
            {storedUser.isSuperAdmin === 1 || storedUser.id === userData.createdById ? (
              <CustomMenu menuOptions={menuOptions} />
            ) : ''}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'left',
              display: 'flex',
              flexDirection: 'column',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.name')}</b>
              {userData.name}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.surname')}</b>
              {userData.surname}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.patronymic')}</b>
              {userData.patronymic}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.dateOfBirth')}</b>
              {`${moment(userData.dateOfBirth).format('DD/MM/YYYY')}`}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.createdBy')}</b>
              {userData.researcherSurname}
              <span> </span>
              {userData.researcherName}
              <span> </span>
              {userData.researcherPatronymic}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.dateCreated')}</b>
              {`${moment(userData.dateCreated).format('DD/MM/YYYY HH:mm:ss')}`}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.systemRoleName')}</b>
              {userData.systemRoleName}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
            >
              <b>{t('SINGLE_PAGE.projectRole')}</b>
              {userData.projectRole}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'left',
              display: 'flex',
              flexDirection: 'column',
              p: 1
            }}
          >
            {storedUser.isSuperAdmin === 1 ? (
              <>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.superAdmin')}</b>
                  {userData.isSuperAdmin === 1 ? <Chip label="Yes" color="primary" /> : <Chip label="No" color="error" />}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.allowToLogin')}</b>
                  {userData.isAllowToLogin === 1 ? <Chip label="Yes" color="primary" /> : <Chip label="No" color="error" />}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.allowToCreateProject')}</b>
                  {userData.isAllowToCreateProject === 1 ? <Chip label="Yes" color="primary" /> : <Chip label="No" color="error" />}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.login')}</b>
                  {userData.login}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="body1"
                >
                  <b>{t('SINGLE_PAGE.datePasswordChanged')}</b>
                  {`${moment(userData.datePasswordChanged).format('DD/MM/YYYY HH:mm:ss')}`}
                </Typography>
              </>
            )
              : ''}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ResearcherProfile;

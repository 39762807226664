import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Button
} from '@material-ui/core';
import RoleEnum from 'src/enums/RoleEnum';
import MicOffIcon from '@material-ui/icons/MicOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import methodicService from 'src/services/methodicService';
import ConfirmRemovingInterview from 'src/dialogs/ConfirmRemovingInterview';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UploadFile from 'src/dialogs/UploadFile';
import interviewService from '../../services/interviewService';
import helperService from '../../services/helperService';
import StatusEnum from '../../enums/StatusEnum';
import CustomMenu from '../CustomMenu';
import StartInterview from '../../dialogs/StartInterview';
import AudioRecorder from '../AudioRecorder';
import audioFileService from '../../services/interviewFileService';
import AddNewInterviewComment from '../../dialogs/AddNewInterviewComment';
import InterviewActionItems from '../../enums/InterviewActionItems';
import AudioRecordActions from '../../enums/AudioRecordActions';
import CustomNotification from '../CustomNotification';
import AttachKeyword from '../../dialogs/AttachKeyword';
import InterviewTranscription from './InterviewTranscription';

const InterviewProfile = (props) => {
  const storedUser = useSelector((state) => state.user);
  const {
    interviewData, removeAction, updateParent, isRecordingProp
  } = props;
  const [t] = useTranslation('common');
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogComment, setShowDialogComment] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showAttachKeywordDialog, setShowAttachKeywordDialog] = useState(false);
  const [methodics, setMethodics] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [recordState, setRecordState] = useState(AudioRecordActions.NONE);
  const [audioFile, setAudioFile] = useState(null);
  const [menuOptions, setMenuOptions] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  InterviewProfile.propTypes = {
    interviewData: PropTypes.object.isRequired,
    removeAction: PropTypes.func,
    updateParent: PropTypes.func,
    isRecordingProp: PropTypes.number
  };
  const renderVisibilityIcon = () => (
    interviewData.isVisible
      ? (<div><VisibilityIcon /></div>)
      : (<div><VisibilityOffIcon /></div>)
  );
  const renderPublishIcon = () => (
    interviewData.isPublished
      ? (<div><AssignmentTurnedInIcon /></div>)
      : (<></>)
  );
  const parseKeywords = () => {
    if (interviewData.keyWords !== undefined) {
      return interviewData.keyWords.map((i) => i.name).join(', ');
    }
    return '';
  };
  const interviewDataObject = [
    { text: t('SINGLE_PAGE.projectName'), value: interviewData.projectName, show: true },
    { text: t('SINGLE_PAGE.respondent'), value: `${interviewData.respondentSurname} ${interviewData.respondentName} ${interviewData.respondentPatronymic}`, show: true },
    { text: t('SINGLE_PAGE.status'), value: (<Chip style={{ backgroundColor: helperService.renderChipCredentials(interviewData.statusId, t).color, color: 'white' }} label={helperService.renderChipCredentials(interviewData.statusId, t).text} />), show: true },
    { text: t('SINGLE_PAGE.startDate'), value: moment(interviewData.startDatetime).format('DD/MM/YYYY HH:mm:ss'), show: true },
    { text: t('SINGLE_PAGE.finishDate'), value: moment(interviewData.finishDatetime).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? t('SINGLE_PAGE.noDate') : moment(interviewData.finishDatetime).format('DD/MM/YYYY HH:mm:ss'), show: true },
    { text: t('SINGLE_PAGE.researcher'), value: `${interviewData.researcherSurname} ${interviewData.researcherName} ${interviewData.researcherPatronymic}`, show: true },
    { text: t('SINGLE_PAGE.comment'), value: interviewData.comment, show: true },
    { text: t('SINGLE_PAGE.interviewFinishReason'), value: interviewData.interviewFinishReason, show: interviewData.statusId === StatusEnum.INTERRUPTED },
    { text: t('SINGLE_PAGE.keywords'), value: parseKeywords(), show: true },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const restartInterview = () => {
    if (interviewData.statusId === StatusEnum.INTERRUPTED) {
      dispatch({ type: 'updateUser', user: { activeInterviewId: interviewData.id } });
      navigate(`../../interview/${interviewData.id}`, { replace: true });
    }
  };
  const complementInterview = () => {
    setShowDialog(true);
  };

  const cancelDialog = () => {
    setShowDialog(false);
  };

  const cancelAttachKeywordDialog = () => {
    setShowAttachKeywordDialog(false);
  };

  const sendAttachKeyword = async (array) => {
    const interviewUpdateInput = {
      keyWords: array,
      projectId: storedUser.projectId
    };
    const response = await interviewService.update(interviewData.id, interviewUpdateInput);
    if (response > 0) {
      setShowAttachKeywordDialog(false);
      updateParent();
    }
  };

  const sendDialog = async (object) => {
    const newObject = {};
    newObject.interviewId = interviewData.id;
    newObject.methodics = object;
    newObject.respondentId = interviewData.respondentId;
    newObject.projectId = interviewData.projectId;
    newObject.languageId = interviewData.interviewLanguageId;
    const response = await interviewService.complementInterview(newObject);
    if (response > 0) {
      dispatch({ type: 'updateUser', user: { activeInterviewId: interviewData.id } });
      navigate(`../../interview/${interviewData.id}`, { replace: true });
    }
    setShowDialog(false);
  };

  const removeInterview = () => {
    setShowConfirmDialog(true);
  };

  const hideInterview = async () => {
    const response = await interviewService.update(interviewData.id, { isVisible: 0, projectId: storedUser.projectId });
    if (response > 0) {
      updateParent();
    }
  };

  const unHideInterview = async () => {
    const response = await interviewService.update(interviewData.id, { isVisible: 1, projectId: storedUser.projectId });
    if (response > 0) {
      updateParent();
    }
  };

  const publishInterview = async () => {
    const response = await interviewService.update(interviewData.id, { isPublished: 1, projectId: storedUser.projectId });
    if (response > 0) {
      updateParent();
    }
  };

  const unPublishInterview = async () => {
    const response = await interviewService.update(interviewData.id, { isPublished: 0, projectId: storedUser.projectId });
    if (response > 0) {
      updateParent();
    }
  };

  const editKeyWords = () => {
    setShowAttachKeywordDialog(true);
  };

  const cancelConfirming = () => {
    setShowConfirmDialog(false);
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };

  const confirmRemoveInterview = async () => {
    removeAction();
    const response = await interviewService.remove(interviewData.id, storedUser.projectId);
    if (response > 0) {
      dispatch({ type: 'removeProperty', user: 'activeInterviewId' });
      navigate('../../home', { replace: true });
    }
  };

  const addNewAudioRecord = () => {
    setRecordState(AudioRecordActions.START_RECORD);
  };

  const finishRecord = () => {
    setRecordState(AudioRecordActions.STOP_RECORD);
    dispatch({ type: 'removeProperty', user: 'redirectAfterTesting' });
  };

  const addComment = () => {
    setShowDialogComment(true);
  };

  const cancelDialogComment = () => {
    setShowDialogComment(false);
  };

  const sendDialogComment = async (commentToSend) => {
    const response = await interviewService.update(interviewData.id, { projectId: interviewData.projectId, comment: commentToSend, statusId: interviewData.statusId });
    if (response > 0) {
      updateParent();
    }
    setShowDialogComment(false);
  };

  const uploadAudioFile = () => {
    setOpenUploadFile(true);
  };

  const cancelUploadFile = () => {
    setOpenUploadFile(false);
  };

  const handlePublishMenuItems = (menuItems) => {
    if (interviewData.statusId === StatusEnum.FINISHED
      && (storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER)
      && interviewData.isVisible === 1
      && interviewData.isPublished === 0) {
      menuItems.push(InterviewActionItems.PUBLISH_INTERVIEW);
    } else if (interviewData.statusId === StatusEnum.FINISHED
      && (storedUser.isSuperAdmin === 1 || storedUser.systemRoleId === RoleEnum.PROJECT_OWNER)
      && interviewData.isVisible === 1
      && interviewData.isPublished === 1) {
      menuItems = [];
      menuItems.push(InterviewActionItems.UNPUBLISH_INTERVIEW);
    } else if (interviewData.isPublished === 1 && (storedUser.isSuperAdmin !== 1 || storedUser.systemRoleId === RoleEnum.RESEARCHER || storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN)) {
      menuItems = [];
    }
    return menuItems;
  };

  const initialMenuOptions = [
    {
      id: InterviewActionItems.CONTINUE,
      text: t('SINGLE_PAGE.restartInterview'),
      visible: true,
      function: restartInterview
    },
    {
      id: InterviewActionItems.COMPLEMENT,
      text: t('SINGLE_PAGE.complementInterview'),
      visible: true,
      function: complementInterview
    },
    {
      id: InterviewActionItems.ADD_AUDIO_RECORD,
      text: t('SINGLE_PAGE.addNewRecord'),
      visible: true,
      function: addNewAudioRecord
    },
    {
      id: InterviewActionItems.UPLOAD_FROM_DEVICE,
      text: t('SINGLE_PAGE.uploadFile'),
      visible: true,
      function: uploadAudioFile
    },
    {
      id: InterviewActionItems.EDIT_COMMENT,
      text: t('SINGLE_PAGE.editComment'),
      visible: true,
      function: addComment
    },
    {
      id: InterviewActionItems.REMOVE,
      text: t('SINGLE_PAGE.removeInterview'),
      visible: true,
      textColor: 'red',
      function: removeInterview
    },
    {
      id: InterviewActionItems.HIDE_INTERVIEW,
      text: t('SINGLE_PAGE.hideInterview'),
      visible: true,
      textColor: 'red',
      function: hideInterview
    },
    {
      id: InterviewActionItems.UNHIDE_INTERVIEW,
      text: t('SINGLE_PAGE.unHideInterview'),
      visible: true,
      textColor: 'red',
      function: unHideInterview
    },
    {
      id: InterviewActionItems.UPDATE_KEYWORDS,
      text: t('SINGLE_PAGE.editKeywords'),
      visible: true,
      function: editKeyWords
    },
    {
      id: InterviewActionItems.PUBLISH_INTERVIEW,
      text: t('SINGLE_PAGE.publishInterview'),
      visible: true,
      function: publishInterview
    },
    {
      id: InterviewActionItems.UNPUBLISH_INTERVIEW,
      text: t('SINGLE_PAGE.unPublishInterview'),
      visible: true,
      textColor: 'red',
      function: unPublishInterview
    }
  ];

  const interviewProfileMenuConfiguration = [
    {
      name: 'superadmin_finished_hidden',
      rule: storedUser.isSuperAdmin === 1
        && interviewData.statusId === StatusEnum.FINISHED
        && interviewData.isVisible === 0,
      menu: [
        InterviewActionItems.COMPLEMENT,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE,
        InterviewActionItems.UNHIDE_INTERVIEW
      ]
    },
    {
      name: 'superadmin_finished_visible',
      rule: storedUser.isSuperAdmin === 1
        && interviewData.statusId === StatusEnum.FINISHED
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.COMPLEMENT,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE,
        InterviewActionItems.HIDE_INTERVIEW
      ]
    },
    {
      name: 'superadmin_interrupted_hidden',
      rule: storedUser.isSuperAdmin === 1
        && interviewData.statusId === StatusEnum.INTERRUPTED
        && interviewData.isVisible === 0,
      menu: [
        InterviewActionItems.CONTINUE,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE,
        InterviewActionItems.UNHIDE_INTERVIEW
      ]
    },
    {
      name: 'superadmin_interrupted_visible',
      rule: storedUser.isSuperAdmin === 1
        && interviewData.statusId === StatusEnum.INTERRUPTED
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.CONTINUE,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE,
        InterviewActionItems.HIDE_INTERVIEW
      ]
    },
    {
      name: 'project_owner_finished_visible',
      rule: storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
        && interviewData.statusId === StatusEnum.FINISHED
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.COMPLEMENT,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE
      ]
    },
    {
      name: 'project_owner_interrupted_visible',
      rule: storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.PROJECT_OWNER
        && interviewData.statusId === StatusEnum.INTERRUPTED
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.CONTINUE,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE
      ]
    },
    {
      name: 'project_admin_finished_visible',
      rule: storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN
        && interviewData.statusId === StatusEnum.FINISHED
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.COMPLEMENT,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE
      ]
    },
    {
      name: 'project_admin_interrupted_visible',
      rule: storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.PROJECT_ADMIN
        && interviewData.statusId === StatusEnum.INTERRUPTED
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.CONTINUE,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE
      ]
    },
    {
      name: 'researcher_finished_interview_owner_visible',
      rule: storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.RESEARCHER
        && interviewData.statusId === StatusEnum.FINISHED
        && interviewData.researcherId === storedUser.id
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.COMPLEMENT,
        InterviewActionItems.ADD_AUDIO_RECORD,
        InterviewActionItems.UPLOAD_FROM_DEVICE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE
      ]
    },
    {
      name: 'researcher_interrupted_interview_owner_visible',
      rule:
        storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.RESEARCHER
        && interviewData.statusId === StatusEnum.INTERRUPTED
        && interviewData.researcherId === storedUser.id
        && interviewData.isVisible === 1,
      menu: [
        InterviewActionItems.CONTINUE,
        InterviewActionItems.EDIT_COMMENT,
        InterviewActionItems.UPDATE_KEYWORDS,
        InterviewActionItems.REMOVE
      ]
    },
    {
      name: 'researcher_interrupted_not_interview_owner_visible',
      rule:
        storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.RESEARCHER
        && interviewData.statusId === StatusEnum.INTERRUPTED
        && interviewData.researcherId !== storedUser.id
        && interviewData.isVisible === 1,
      menu: []
    },
    {
      name: 'researcher_finished_not_interview_owner_visible',
      rule:
        storedUser.isSuperAdmin === 0
        && storedUser.systemRoleId === RoleEnum.RESEARCHER
        && interviewData.statusId === StatusEnum.FINISHED
        && interviewData.researcherId !== storedUser.id
        && interviewData.isVisible === 1,
      menu: []
    }
  ];

  const createMenuList = () => {
    if (Object.keys(interviewData).length !== 0 && !storedUser.publishMode) {
      /* console.log('SuperAdmin', storedUser.isSuperAdmin, 'SystemRole', storedUser.systemRoleId,
        'Status', interviewData.statusId, 'IsVisible', interviewData.isVisible); */
      let menuConfigs = interviewProfileMenuConfiguration.find((i) => i.rule === true).menu.sort((a, b) => a - b);
      menuConfigs = handlePublishMenuItems(menuConfigs);
      const filteredList = initialMenuOptions.filter((initialItem) => menuConfigs.includes(initialItem.id));
      setMenuOptions(filteredList);
    }
  };

  const getNotUsedMethodics = async () => {
    const response = await methodicService.getNotUsedMethodics(interviewData.id, interviewData.projectId, interviewData.interviewLanguageId);
    setMethodics(response);
  };

  const getAudio = (object) => {
    setAudioFile(object);
  };

  const saveAudio = async (audioFileBase64) => {
    setRecordState(AudioRecordActions.STOP_RECORD);
    const audioFileObject = {
      interviewId: +interviewData.id,
      fileBase64: audioFileBase64
    };
    const response = await audioFileService.save(audioFileObject);
    setCurrentResponse(response);
    if (response > 0) {
      setOpenNotification(true);
      updateParent();
    }
    setAudioFile(null);
  };

  const sendUploadFile = async (fileObject) => {
    const audioFileObject = {
      interviewId: +interviewData.id,
      fileBase64: fileObject.base64.split(',')[1],
      mimeType: fileObject.mimeType,
      fileName: fileObject.fileName
    };
    const response = await audioFileService.save(audioFileObject);
    setCurrentResponse(response);
    if (response > 0) {
      setOpenUploadFile(false);
      setOpenNotification(true);
      updateParent();
    }
  };

  useEffect(() => {
    createMenuList();
  }, [interviewData]);

  useEffect(() => {
    if (audioFile !== null && audioFile !== undefined) {
      saveAudio(audioFile);
    }
  }, [audioFile]);

  useEffect(() => {
    if (showDialog) {
      getNotUsedMethodics();
    }
  }, [showDialog]);

  useEffect(() => {
    setRecordState(isRecordingProp);
  }, [isRecordingProp]);

  return (
    <>
      {recordState === AudioRecordActions.START_RECORD ? (
        <Alert
          sx={{ mb: 1 }}
          severity="success"
          color="info"
          action={
            (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color="secondary"
                startIcon={<MicOffIcon />}
                onClick={finishRecord}
              >
                {t('BUTTONS.finishRecord')}
              </Button>
            )
          }
        >
          <b>{t('SINGLE_PAGE.recordingIsActive')}</b>
        </Alert>
      ) : ''}
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.audioFileWasUploaded')}
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <AttachKeyword open={showAttachKeywordDialog} cancel={cancelAttachKeywordDialog} send={sendAttachKeyword} attachedKeywords={interviewData.keyWords} filterMode={false} />
      <UploadFile open={openUploadFile} cancel={cancelUploadFile} send={sendUploadFile} allowedTypes={['audio/*']} />
      <AddNewInterviewComment open={showDialogComment} cancel={cancelDialogComment} send={sendDialogComment} commentProp={interviewData.comment} />
      <AudioRecorder recordMode="true" getAudio={getAudio} isRecordingProp={recordState} />
      <ConfirmRemovingInterview open={showConfirmDialog} cancel={cancelConfirming} confirm={confirmRemoveInterview} />
      <StartInterview open={showDialog} cancel={cancelDialog} send={sendDialog} respondent={{ name: interviewData.respondentName, surname: interviewData.respondentSurname, patronymic: interviewData.respondentPatronymic }} methodics={methodics} isComplementMode="true" />
      <Accordion expanded={expanded === true} onChange={handleChange(true)} sx={{ mb: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <b>
                {t('SINGLE_PAGE.interviewNumber')}
                {interviewData.id}
              </b>
              {storedUser.isSuperAdmin === 1 ? (
                <>
                  <Box sx={{ pl: 1 }}>
                    {renderVisibilityIcon()}
                  </Box>
                  <Box sx={{ pl: 1 }}>
                    {renderPublishIcon()}
                  </Box>
                </>
              ) : ''}
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Card {...props}>
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      <b>{interviewDataObject[0].text}</b>
                      {interviewDataObject[0].value}
                    </Typography>
                    {recordState !== AudioRecordActions.START_RECORD && menuOptions.length !== 0 ? (
                      <Box sx={{ mt: -2 }}>
                        <CustomMenu menuOptions={menuOptions} />
                      </Box>
                    ) : ''}
                  </Box>
                  {interviewDataObject
                    .splice(1, interviewDataObject.length - 1)
                    .filter((item) => item.show)
                    .map((item) => (
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="body1"
                      >
                        <b>{item.text}</b>
                        {item.value}
                      </Typography>
                    ))}
                  <InterviewTranscription isPublished={interviewData.isPublished} interviewResearcherId={interviewData.researcherId} interviewId={interviewData.id} interviewTranscriptionFile={interviewData.transcriptionFile} updateParent={updateParent} />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default InterviewProfile;

import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card,
  CardContent
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreateRespondentCard from '../components/respondent/CreateRespondentCard';
import BackButton from '../components/BackButton';

const CreateInterview = () => {
  const storedUser = useSelector((state) => state.user);
  const [t] = useTranslation('common');
  return (
    <>
      <Helmet>
        <title>{t('BROWSER_TAB_TITLES.newInterview')}</title>
      </Helmet>
      <Box
        sx={{
          mt: 2
        }}
      >
        <Container maxWidth="false">
          <Box sx={{ ml: -3, mb: 2 }}>
            <BackButton route={`../single-project/${storedUser.projectId}`} />
          </Box>
          <Card>
            <CardContent>
              <CreateRespondentCard />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default CreateInterview;

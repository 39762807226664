import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import AddNewAnswer from 'src/dialogs/AddNewAnswer';
import answerTemplateService from 'src/services/answerTemplateService';
import CustomTable from '../CustomTable';
import TableCellTypes from '../../enums/TableCellTypes';
import CustomNotification from '../CustomNotification';
import CustomBackdrop from '../CustomBackdrop';

const AnswerTemplates = () => {
  const [t] = useTranslation('common');
  const storedUser = useSelector((state) => state.user);
  const [answerTemplatesResponse, setAnswerTemplatesResponse] = useState(null);
  const [currentResponse, setCurrentResponse] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [answerTemplateObject, setAnswerTemplateObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const defaultPaggingSettings = {
    CurrentPage: 1,
    Search: '',
    RowsPerPage: 5
  };
  const editAnswerTemplate = (object) => {
    setAnswerTemplateObject(object);
    setShowDialog(true);
  };
  const openAddNewAnswerDialog = () => {
    setShowDialog(true);
    setAnswerTemplateObject({});
  };
  const cancelDialog = () => {
    setShowDialog(false);
  };
  const sendDialog = async (object) => {
    setLoading(true);
    let response = '';
    if (object.id === 0) {
      response = await answerTemplateService.save(object);
    } else {
      response = await answerTemplateService.update(object.id, object);
    }
    setCurrentResponse(response);
    if (response > 0) {
      await getAnswers(defaultPaggingSettings);
      setOpenNotification(true);
      setShowDialog(false);
    }
    setLoading(false);
  };
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const handleLimitChange = async (limit) => {
    defaultPaggingSettings.RowsPerPage = limit;
    await getAnswers(defaultPaggingSettings);
  };
  const handlePageChange = async (page) => {
    defaultPaggingSettings.CurrentPage = page;
    await getAnswers(defaultPaggingSettings);
  };
  const handleSearching = async (searchString) => {
    defaultPaggingSettings.Search = searchString;
    await getAnswers(defaultPaggingSettings);
  };
  const dataConfig = {
    handleSearchingFunction: handleSearching,
    handleLimitChangeFunction: handleLimitChange,
    handlePageChangeFunction: handlePageChange,
    isFullMode: true,
    headerButtons: [],
    tableTitle: '',
    columns: [
      {
        label: 'TABLE.id',
        propertyName: 'id',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.description',
        propertyName: 'description',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.template',
        propertyName: 'template',
        type: TableCellTypes.Text
      },
      {
        label: 'TABLE.edit',
        type: TableCellTypes.Button,
        buttonLabel: '>',
        buttonColor: 'primary',
        function: editAnswerTemplate
      }
    ]
  };
  const getAnswers = async (paggingSettings) => {
    setAnswerTemplatesResponse(null);
    const response = await answerTemplateService.getPaged(paggingSettings);
    setAnswerTemplatesResponse(response);
  };

  useEffect(() => {
    getAnswers(defaultPaggingSettings);
  }, []);

  return (
    <>
      <CustomNotification
        open={openNotification}
        close={closeNotification}
        autoHideDurationTime={3000}
        successText={t('NOTIFICATIONS.answerTemplateCreated')}
        type="success"
        vertical="bottom"
        horizontal="center"
        response={currentResponse}
      />
      <CustomBackdrop open={loading} spinnerColor="primary" />
      <AddNewAnswer open={showDialog} cancel={cancelDialog} send={sendDialog} answerTemplateData={answerTemplateObject} />
      { storedUser.isSuperAdmin === 1 && false
        ? (
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openAddNewAnswerDialog}
          >
            {t('BUTTONS.addNewAnswerTemplate')}
          </Button>
        ) : ''}
      <CustomTable dataSource={answerTemplatesResponse} dataConfig={dataConfig} />
    </>
  );
};

export default AnswerTemplates;

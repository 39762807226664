import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: 'wFfB4WwrlvgaImPnfu0x9iA3J'
    }),
  ]
};

const userReducer = (state = { user: {} }, action) => {
  if (action.type === 'storeUser') {
    const obj = {
      user: action.user
    };
    return obj;
  }

  if (action.type === 'updateUser') {
    const obj = {
      user: { ...state.user, ...action.user }
    };
    return obj;
  }

  if (action.type === 'removeUser') {
    const obj = {
      user: {}
    };
    return obj;
  }
  if (action.type === 'removeProperty') {
    const copyArr = { ...state.user };
    delete copyArr[action.user];
    const obj = {
      user: copyArr
    };
    return obj;
  }
  return state;
};

const persistedReducer = persistReducer(persistConfig, userReducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store);

export default { store, persistor };

import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomTransferList from '../components/CustomTransferList';
import ActionTypeEnum from '../enums/ActionTypeEnum';

const SingleMethodicInterviewPage = (props) => {
  const [t] = useTranslation('common');
  const { interviewObject, save } = props;
  const [answers, setAnswers] = useState(interviewObject.Questions);
  const [dragDropData, setDragDropData] = useState([]);
  const [saveRangesDisabled, setSaveRangesDisabled] = useState(true);
  SingleMethodicInterviewPage.propTypes = {
    interviewObject: PropTypes.array.isRequired,
    save: PropTypes.func.isRequired
  };
  const setSelectItems = (object) => {
    interviewObject.ComplexRanges.Ranges[0] = object;
    const currentArrayLenght = object === null ? 0 : object.length;
    setSaveRangesDisabled(dragDropData.length === 0 ? false : !(currentArrayLenght === dragDropData.length));
  };
  const handleChange = (event) => {
    const copyArr = [...answers];
    const indexToUpdate = copyArr.findIndex((item) => item.Id === +event.target.id);
    if (indexToUpdate > -1) {
      copyArr[indexToUpdate].AnswerValue = +event.target.value;
      setAnswers(copyArr);
    }
  };

  const allAnswersChecked = () => {
    const notCheckedAnswers = answers.filter((item) => item.AnswerValue === 0);
    return notCheckedAnswers.length !== 0;
  };
  const calculateMarginOfCharacterLength = (questionTemplate) => {
    let sum = 0;

    questionTemplate.AnswerTemplateMethodic.forEach((question) => {
      sum += question.Text.length;
    });

    const averageLength = sum / questionTemplate.AnswerTemplateMethodic.length;

    // make more margin between big answers
    const marginValue = averageLength < 100 ? 0.1 : 1.5;

    return {
      mt: marginValue,
      mb: marginValue
    };
  };
  useEffect(() => {
    setDragDropData([]);
    setDragDropData(interviewObject.ComplexRanges.Ranges[0]);
    setAnswers(interviewObject.Questions);
    setSaveRangesDisabled(true);
  }, [props]);

  return (
    <>
      <Box
        sx={{
          mt: 2
        }}
      >
        {((interviewObject.Questions.length !== 0)) ? (
          <Container>
            <Box sx={{ textAlign: 'center', mt: 4, mb: 4 }}>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {interviewObject.MethodicDescription}
              </Typography>
            </Box>
            {interviewObject.Questions.map((item) => (
              <Card sx={{ mt: 2, mb: 3 }}>
                <CardContent>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {item.Text}
                  </Typography>
                  <Box sx={{ mt: 3, mb: 3 }}>
                    <RadioGroup id={item.Id} value={answers[answers.findIndex((i) => i.Id === +item.Id)] === undefined ? 0 : answers[answers.findIndex((i) => i.Id === +item.Id)].AnswerValue} onChange={handleChange}>
                      {item.AnswerTemplateMethodic.map((template) => (
                        <>
                          <FormControlLabel
                            sx={calculateMarginOfCharacterLength(item)}
                            value={template.Value}
                            control={<Radio id={item.Id} />}
                            label={template.Text}
                          />
                        </>
                      ))}
                    </RadioGroup>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Container>
        ) : ''}
        <Container>
          {interviewObject.ComplexRanges.Ranges.length !== 0 ? (
            <>
              <Box sx={{ textAlign: 'center', mt: 4, mb: 4 }}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {interviewObject.MethodicDescription}
                </Typography>
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                {dragDropData !== undefined ? (<CustomTransferList selectedItems={[]} sourceItems={dragDropData} keyField="Id" valueField="Text" passSelectedItems={setSelectItems} moveAllEnabled={false} reverseLgMoreDirection={false} />) : ''}
              </Box>
            </>
          ) : ''}
        </Container>
        {interviewObject.ComplexRanges.Ranges.length !== 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button variant="contained" onClick={() => { save(ActionTypeEnum.UPDATE_WITHOUT_CALCULATION, interviewObject); }} disabled={saveRangesDisabled && dragDropData !== undefined && dragDropData.length !== 0}>{t('BUTTONS.saveRange')}</Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button variant="contained" onClick={() => { save(ActionTypeEnum.CALCULATE, interviewObject); }} disabled={allAnswersChecked()}>{t('BUTTONS.save')}</Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SingleMethodicInterviewPage;
